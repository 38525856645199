import React, { useContext, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';


import Error404 from 'pages/errors/Error404';
import Error500 from 'pages/errors/Error500';

import Dashboard from 'components/dashboards/default';
import AppContext from 'context/Context';

import UploadTerritoryJSON from 'newComponents/UploadTerritoryJSON';
import ViewTerritory from 'pages/viewTerritory';
import ModifyTerritoryMap from "newComponents/ModifyTerritoryMap";

import AssignTerritory from "pages/assignTerritory";
import LogInPage from "pages/logIn";

import {gql,useQuery} from "urql";
import AuthContext from "newComponents/AuthContext";

import HomePage from "pages/home";
import ResearchAddressPage from "pages/researchAddress";
import DoNotCallPage from "pages/doNotCall";
import { ViewPublishersPage } from "pages/viewPublishers";
import CreatePublisherPage from "pages/createPublisher";
import EditPublisherPage from "pages/editPublisher";

import { ViewTerritoriesPage } from "pages/viewTerritories";
import EditTerritoryWorkHistory from "pages/editTerritoryWorkHistory/EditTerritoryWorkHistory";
import EditTerritoryWorkHistoryPage from "pages/editTerritoryWorkHistory";

const REGISTER_AS_NEW_USER = gql`
query register{
    registerAsNewUser
  }`

var unDone = true;



const Layout = () => {

  /*
let auth = useContext(AuthContext)
console.log("auth in app");
console.log(auth);
const [query, reexecuteTerritoryQuery] = useQuery({
  query: REGISTER_AS_NEW_USER, //GET_ALL_TERRITORIES,//_FOR_PUBLISHER,
  variables: {
  },
  requestPolicy: "network-only" //"cache-and-network"
});
if (query.data && query.data.registerAsNewUser && unDone) {
  setTimeout(auth.changeAuthState({ refreshToken: true }), 100);
  unDone = false
}*/


  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

console.log("AT NEW STUFF");

  return (
    <>
    <Routes>

    <Route element={<ErrorLayout />}>
          <Route path="errors/404" element={<Error404 />} />
          <Route path="errors/500" element={<Error500 />} />
        </Route>

    <Route element={<MainLayout />}>

<Route path="/" element={<HomePage />} />

<Route path="viewAddresses" element={<Dashboard />} />
<Route path="uploadTerritoryJSON" element={<UploadTerritoryJSON />} />

<Route path="viewTerritory/:territoryID" element={<ViewTerritory/>} />

<Route path="researchAddress/:addressID" element={<ResearchAddressPage/>} />

<Route path="editTerritoryWorkHistory/:territoryID" element={<EditTerritoryWorkHistoryPage/>} />

<Route path="doNotCall/:addressID" element={<DoNotCallPage/>} />

<Route path="modifyTerritoryMap" element={<ModifyTerritoryMap/>} />

<Route path="assignTerritory/:publisherID" element={<AssignTerritory/>} />

<Route path="createPublisher" element={<CreatePublisherPage/>} />

<Route path="editPublisher/:publisherID" element={<EditPublisherPage/>} />

<Route path="viewPublishers" element={<ViewPublishersPage/>} />

<Route path="viewTerritories" element={<ViewTerritoriesPage/>} />

</Route>

{/*<Route path="logIn" element={<LogInPage />} />
*/}


<Route path="*" element={<Navigate to="/errors/404" replace />} />

    </Routes>

      {/*<SettingsToggle /> 
      <SettingsPanel />
        icon={false}
  position={toast.POSITION.BOTTOM_LEFT}
  />*/}
    </>
  );
};

export default Layout;
