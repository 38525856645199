import { AddressQueryFields } from 'models/Address';
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import React from 'react';

import { useParams } from 'react-router-dom';

import CreatePublisher from './CreatePublisher';

const CreatePublisherPage = () => {

    return (
      <div className="card">
         <BreadCrumbHeader/>
        <div className = "card-body" style={{paddingTop:10}}>
        <CreatePublisher/>
        </div>
      </div>
    )

  }

  export default CreatePublisherPage