import React, { Component, useContext, useMemo, useState } from 'react';
import { GET_PUBLISHER_CACHE, Publisher } from "models/Publisher";
import BreadCrumbHeader from "newComponents/BreadCrumbHeader";
import { useCallback } from "react";
import CreatableSelect from "react-select/creatable";
import { useQuery } from "urql";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string } from 'yup';
import { GET_TERRITORY_CACHE, Territory } from 'models/Territory';
import { AssignedTerritoriesCard } from './AssignedTerritoriesCard';
import { UnassignedTerritoriesCard } from './UnassignedTerritoriesCard';
import { customReactSelectStyle } from 'helpers/reactSelectStyle';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { AuthContext } from 'utilities/Authentication';
import { time } from 'console';
import { TerritoryMapModal } from 'pages/viewTerritories/TerritoryMapModal';


const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!candidate.label.includes(inp[i])) {
            return false
        }
    }
    return true;
};

const AssignTerritory = (params: any) => {

    const [territoryMapModalData,setTerritoryMapModalData] = useState({show:false,territoryID:null});

    const publisherID = useMemo(function(){ 

        return window.localStorage.getItem("lastPublisherIDAssignTerritory") || "NP" 


},[]) //useParams();

    const authContext = useContext(AuthContext);

    const [Tquery, reexecuteTerritoryQuery] = useQuery({
        query: GET_TERRITORY_CACHE, //GET_ALL_TERRITORIES,//_FOR_PUBLISHER,
        variables: {
            congregationID: authContext?.currentDomain
            //id: params.selectedPublisher.id
        },
        requestPolicy: "cache-and-network" //"cache-and-network"
    });

    const [query, reexecuteQuery] = useQuery({
        query: GET_PUBLISHER_CACHE, //GET_ALL_PUBLISHERS,
        variables: {
            congregationID: "1"
        },
        requestPolicy: "cache-and-network"//cache-first
    });

    const navigate = useNavigate();
    //const history = useHistory();

    //const selectedPublisher = useState()
    //const [selectedPublisher, setSelectedPublisher]: [selectedPublisher: Publisher | null, setSelectedPublisher: any] = useState(null);
    const [selectedPublisher, setSelectedPublisher] = useState( (publisherID != "NP" && (query?.data?.getPublisherCache || []).find((pub: any) => pub.id == publisherID)) || null );
    const [initializedPublisher, setInitializedPublisher] = useState(false);

    const handleChange = useCallback((inputValue: any) => {
        console.log("handleChange");
        console.log(inputValue)
        console.log(query.data)
        console.log(query.data.getPublisherCache);
        setSelectedPublisher(query.data.getPublisherCache.find((pub: any) => pub.id == inputValue.value));
        window.localStorage.setItem("lastPublisherIDAssignTerritory",inputValue.value);

        //navigate("/assignTerritory/" + inputValue.value, { replace: true })

    }, [query]);

    console.log("postQuery")
    console.log(query)
    console.log(reexecuteQuery);

    let loading = true;
    let error = {}
    let data = {}

    console.log(loading);
    console.log(error);
    console.log(data);

    /*useMemo(function () {
        console.log("MEMO USED 1")
        if (query?.data?.getPublisherCache != undefined && query?.data?.getPublisherCache != null && initializedPublisher == false) {
            console.log("MEMO USED 2")
            if (publisherID && publisherID != "NP") {
                console.log("MEMO USED 3")
                console.log(publisherID);


                setInitializedPublisher(true);

                setTimeout(function(){

                    

                    let pub = query.data.getPublisherCache.find((pub: any) => pub.id == publisherID)
                    setSelectedPublisher(query.data.getPublisherCache.find((pub: any) => pub.id == publisherID));
                },200)
                
            }
        }
    }, [(query?.data?.getPublisherCache)])*/

    if (query.fetching || query.error) {
        return (
            <BreadCrumbHeader query={query} />
        )
    }



    console.log("getpubs");
    console.log(query);
    console.log(query.data.getPublisherCache);

    return (
        <>
            <div className="card">
                <BreadCrumbHeader data={{ routes: ["Assign Territory"] }} />

                <div className="card-body" style={{ //minHeight: 200 
                }}>
                    <label>Select a Publisher</label>
                    <CreatableSelect
                        onCreateOption={(option: string) => {
                            navigate("/createPublisher?publisherName=" + option);
                        }}
                        closeMenuOnSelect={true}
                        options={query.data.getPublisherCache.map((item: Publisher) => { return { value: item.id, label: item.displayName } })}
                        styles={customReactSelectStyle}
                        //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
                        isSearchable={true}
                        //onCreateOption={handleCreate}
                        //menuIsOpen={true}




                        value={selectedPublisher ? {value:selectedPublisher.id, label:selectedPublisher.displayName} : null}

                        placeholder='Select Publisher...'
                        classNamePrefix="react-select2"
                        //value={value}
                        onChange={handleChange}
                        menuPlacement="bottom"
                        width="100%"
                        filterOption={reactSelectCustomSearch}
                    />
                </div></div>

            {(() => {

                if (selectedPublisher) {
                    return (
                        <>
                        <TerritoryMapModal territoryMapModalData={territoryMapModalData} setTerritoryMapModalData={setTerritoryMapModalData} />
                            <AssignedTerritoriesCard territoryMapModalData={territoryMapModalData} setTerritoryMapModalData={setTerritoryMapModalData} key={selectedPublisher["id"] + "p"} selectedPublisher={selectedPublisher} />
                            <UnassignedTerritoriesCard territoryMapModalData={territoryMapModalData} setTerritoryMapModalData={setTerritoryMapModalData} key={selectedPublisher["id"] + "t"} selectedPublisher={selectedPublisher} />
                        </>
                    )
                }
            })()}

            {/*        {selectedPublisher && <AssignedTerritoriesCard key={selectedPublisher["id"]} selectedPublisher={selectedPublisher} />}

            {selectedPublisher && <UnassignedTerritoriesCard key={selectedPublisher["id"]} selectedPublisher={selectedPublisher} />} */}
        </>
    )


}

export default AssignTerritory;

