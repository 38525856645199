import { gql } from "urql";
import { Address, AddressQueryFields } from "./Address";
import { DoorToDoorRecord } from "./DoorToDoorRecord";


export class Territory {//Typescript Class
    constructor(input: Territory) {
        //super(input);
        Object.assign(this, input);
    }

    id: string;
    updatedAt?: string;
    createdAt?: string;
    version?: number;

    congregationID?: string

    territoryName?: string

    //addresses
    //workHistory

    workHistory?: TerritoryWorkRecord[]

    lastWorked?: Date

    lastResearched?: Date

    assignedToPublisherID?: string

    assignedToPublisherName?: string

    checkedOutAt?: Date

    doorToDoor?: boolean

    gatedCommunity?: boolean


    hold?: boolean

    pendingAddressChanges?: boolean

    city?: string
    postalCode?: string
    county?: string
    country?: string

    neighborhoodName?: string

    addresses?: Address[]

    doorToDoorRecordID?: string

    doorToDoorRecord?: DoorToDoorRecord
}

export class TerritoryWorkRecord {//Typescript Class
    constructor(input: TerritoryWorkRecord) { //{...TerritoryWorkRecord}) {
        //super(input);
        Object.assign(this, input);
    }

    publisherID?: string

    publisherName?: string

    campaign?: string

    checkedOut?: Date

    checkedIn?: Date

    researched?: boolean
}

export const TerritoryQueryFields = `
id
congregationID
territoryName
workHistory{
  publisherID
  publisherName
  campaign
  checkedIn
  checkedOut
  researched
}
lastWorked
lastResearched
assignedToPublisherID
assignedToPublisherName
checkedOutAt
doorToDoor
gatedCommunity
hold
pendingAddressChanges
city
postalCode
county
country
neighborhoodName
version
numberAddresses
distanceBetweenHouses
`




export const GET_TERRITORY_CACHE = gql`
query getTerrCache($congregationID:ID!){
  getTerritoryCache(congregationID:$congregationID){
${TerritoryQueryFields}
  }
}`

export const UPDATE_TERRITORY_CACHE = gql`mutation updateTerrCache($congregationID:ID!,$lastUpdated:Date!){
  updateTerritoryCache(lastUpdated:$lastUpdated,congregationID:$congregationID){
    ${TerritoryQueryFields}
  }
}`

export const GET_ADDRESS_TERRITORY_CACHES = gql`
query getAddrAndTerrCache($congregationID:ID!){
  getTerritoryCache(congregationID:$congregationID){
${TerritoryQueryFields}
  }

  getAddressCache(congregationID:$congregationID){
    ${AddressQueryFields}
  }
  }
`




