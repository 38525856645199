import React, { useContext, useState, useEffect } from 'react';
import { HashRouter } from 'react-router-dom';//BrowserRouter as Router,
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Layout from './layouts/Layout';

import { Spinner } from "react-bootstrap";

import { useLocation } from "react-router-dom";

import { AuthContext } from "utilities/Authentication";

import VerifyEmail from "pages/logIn/VerifyEmail";

import LogInPage from "pages/logIn";
import AwaitDomain from "pages/logIn/AwaitDomain";
import CongregationContext from "newComponents/CongregationContext";
import { getAuth } from "firebase/auth";
import { PublisherQueryFields } from "models/Publisher";
import { gql, useClient, useQuery } from "urql";

import { AddressQueryFields } from "models/Address";
import { DoorToDoorRecordQueryFields } from "models/DoorToDoorRecord";
import { LoadingSpinner } from "newComponents/LoadingSpinner";
import StandAloneTerritoryMap from "pages/viewTerritory/StandAloneTerritoryMap";

import { MapContainer } from "react-leaflet";
import useWindowDimensions from "helpers/useWindowDimensions";

import { waitInitCache } from "utilities/Authentication";

const fbAuth = getAuth();

var aboutToReFetch = false;

const GET_SELF = gql`
query GETSELF($id:ID!){
  getPublisherByID(id:$id){
    ${ PublisherQueryFields }
  }
}`

const REGISTER_AS_NEW_USER = gql`
mutation regis{
    registerAsNewUser{
        id
        congregationID

roles
resolvers

firstName
lastName
middleName
displayName

email
backupEmail
phone
backupPhone

createdAt
updatedAt
updaterName
updaterID
version
        }
    }`

export const GET_OWN_TERRITORIES = gql`query{
  getOwnTerritories{
    id
    congregationID
    territoryName
    gatedCommunity
    doorToDoor
    
    assignedToPublisherID
    assignedToPublisherName
    checkedOutAt
    version
  }
}`

async function CheckUserData(graphQLClient,auth){
  let results = await graphQLClient.mutation(REGISTER_AS_NEW_USER, {}).toPromise()
   if (results.data && results.data.registerAsNewUser && results.data.registerAsNewUser.length >= 2) {
       auth.dispatchAuthData({ type: "updateUserList", value: results.data.registerAsNewUser });
   }
}

const App = () => {

  const auth = useContext(AuthContext)
  const graphQLClient = useClient()
  console.log("auth in app");
  console.log(auth);

  /*const [query, reexecuteQuery] = useQuery({
    query: GET_SELF,
    variables: {
      id: auth?.currentDomainUser?.id || ""
    },
    requestPolicy: "cache-only" //"cache-and-network"
  });*/

  console.log("RUNNING QUERY");
  console.log(auth.currentDomain);

  const [query, reexecuteQuery] = useQuery({
    query: GET_OWN_TERRITORIES, //GET_ALL_TERRITORIES,//_FOR_PUBLISHER,
    variables: {
      congregationID: auth.currentDomain
      //id: params.selectedPublisher.id
    //  id:auth?.currentDomainUser?.id
    },
    requestPolicy: "cache-and-network", //"cache-and-network"
    pause:!auth.currentDomainUser
  });

  const [checkedUserData,setCheckedUserData] = useState(false);

  useEffect(function(){

    fbAuth.onIdTokenChanged(function(token){

if (query.error || query.fetching){
  console.log("retrying viewterritories");
  setTimeout(()=> reexecuteQuery(),200);
}

    })

  },[])


  console.log("location");
  console.log(window.location.hash);

  const windowDimensions = useWindowDimensions();

  const h = window.location.hash
if (h.startsWith("#viewMap/")){
  //if (window.location.pathname == "/viewMap"){

    const territoryID = h.split("/")[1] //new URLSearchParams(window.location.search).get("territoryID");
    return ( 
<MapContainer style={{ height: windowDimensions.height, width:windowDimensions.width }} center={[29.105776, -81.028559]} zoom={13} scrollWheelZoom={false}>

<StandAloneTerritoryMap territoryID={territoryID}/>
                </MapContainer>
    )
  }


  if (!auth.isAuthed) {
    console.log("Not user");
    return (<HashRouter basename={process.env.PUBLIC_URL}><LogInPage /></HashRouter>)
  }

  if (!auth.emailVerified) {
    //sho
    return (<HashRouter basename={process.env.PUBLIC_URL}><VerifyEmail /></HashRouter>)
  }

  if (!auth.currentDomain || !auth.currentUser || !auth.globalUser) {
    return (<HashRouter basename={process.env.PUBLIC_URL}><AwaitDomain /></HashRouter>)
  }

if (checkedUserData == false){
  setCheckedUserData(true);
  CheckUserData(graphQLClient,auth);
}

  console.log("LOADING SPINNER");
  console.log(query);
  console.log(new Date());

if (query.error && (query.error.message.includes("not authorized") || query?.error?.response?.status == 401) && query.fetching == false && aboutToReFetch == false){
  aboutToReFetch = true;
  setTimeout(()=> {aboutToReFetch = false; reexecuteQuery()},1000);
}

  if (!query.data || !query.data.getOwnTerritories) {
    console.log("IN APP LOAD")
    //waitInitCache();

    console.log(query.data);
    return (<HashRouter basename={process.env.PUBLIC_URL}><div style={{position:"absolute",left:"50%",top:windowDimensions.height/2,transform:"translate(-50%, -50%)"}}><LoadingSpinner/></div></HashRouter>)
  }

  console.log("rending app normally")



  return (
    <CongregationContext.Provider value={{ congregationID: "1", publisherID: "1_pub_18", publisher: { publisherID: "1_pub_18", displayName: "Wolfgang Burkman" } }}>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <Layout />
      </HashRouter>
    </CongregationContext.Provider>
  );
};

export default App;
