
const NODE_ENV = process.env.NODE_ENV;
console.log("LINUX UPDATE")
//npmtest
//branch1//
import { gql } from "urql";
import React, { useEffect, useReducer, useState } from 'react';//useState
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';

import {AuthenticationWrapper} from "utilities/Authentication";

import { GET_ADDRESS_CACHE, UPDATE_ADDRESS_CACHE } from "models/Address";
import { GET_TERRITORY_CACHE, TerritoryQueryFields, UPDATE_TERRITORY_CACHE } from "models/Territory";

import { GET_PUBLISHER_CACHE, UPDATE_PUBLISHER_CACHE } from "models/Publisher";

import customScalarsExchange from "urql-custom-scalars-exchange";

//import customScalarsExchange from "newComponents/graphqlCustomScalarConverter.js";

import { pipe, filter, merge, share, map } from "wonka";

import AuthContext, { useFirebaseAuth } from "newComponents/AuthContext";

import graphqlschema from './schema.json';

import { cacheExchange } from '@urql/exchange-graphcache';
import { offlineExchange } from '@urql/exchange-graphcache';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { authExchange } from '@urql/exchange-auth';


var subdata = ""

const config = { pushKey: "BMr0OlJkBCZS7Z96L0fxz3kH_yn7lwS5o_-FaLdMEkWE5JmOTrSB5z3gfx6XslN4OslsSRlg-Uja9xxrX6Kfmfo" };
async function subscribe(topic) {
  let swReg = await navigator.serviceWorker.register("./sw.js"//'https://reactstatictest.s3.amazonaws.com/sw.js'
  );
  //const subscription = await swReg.pushManager.subscribe({
    //userVisibleOnly: true,
    //applicationServerKey: urlB64ToUint8Array(config.pushKey),
  //});

  /*const options = {
    body: 'Testing Our Notification',
    icon: "favicon-32x32.png"
  };
  swReg.showNotification('PWA Notification!', options);
*/
}

export const PWAPrompt = {deferredPrompt:null};
window.addEventListener('beforeinstallprompt', (e) => {
  console.log("BEFORE INSTALL PROMPT");
  e.preventDefault();
  PWAPrompt.deferredPrompt = e;
});

window.onerror = function(message, source, lineno, colno, error) {
  console.log("ON ERROR:");
  console.log(message);
  console.log(source);
  console.log(lineno);
  console.log(colno);
  let errmess = {};
  try{
    console.log(JSON.stringify(error, Object.getOwnPropertyNames(error)));
    errmess = JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error)))
  }catch(err){     
    console.log("caught error");
    console.log(err);  
  }
  
  let outp = {"Message":message,"Source":source,"LineNumber":lineno,"ColumnNumber":colno,"Error": errmess}
  
  // Make the call to log the client error.
  var xhr = new XMLHttpRequest();
  xhr.open('POST', 'https://53sgfpjtke45rq5h24d4zvcpd40ubxfn.lambda-url.us-east-1.on.aws/LogClientError', true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(JSON.stringify(outp));
  
  return false;
  
};


function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

subscribe();/////////////TO ACTIVATE PWA//PWA IS SET TO NETWORK ONLY RIGHT NOW



const Main = props => {

  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: "top", //getItemFromStore('navbarPosition', settings.navbarPosition),
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };



  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);




  if (!isLoaded) { //|| authData["graphqlClient"] == null) {//authData == null ||
    console.log("Not ready yet...");
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : 'rgba(237, 242, 249,0)' //getColor('light')
        }}
      ><p>{/*Loading config...*/}</p></div>
    );
  }


  return (
        <AppContext.Provider value={{ config, setConfig, configDispatch }}>
          <AuthenticationWrapper>
          {props.children}
          </AuthenticationWrapper>
        </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
