
import lineOffset from "@turf/line-offset";
import { bbox, centroid, lineString } from "@turf/turf";
import { customReactSelectStyle } from "helpers/reactSelectStyle";
import { Address, AddressQueryFields, GeoJSON, LiteraturePlacedRecord } from "models/Address";
import { DoorToDoorRecord, DoorToDoorRecordQueryFields, DTDAction } from "models/DoorToDoorRecord";
import { Territory } from "models/Territory";
import BreadCrumbHeader from "newComponents/BreadCrumbHeader";
import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, Dropdown, Form, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";

import { GeoJSON as ReactLeafletGeoJSON } from "react-leaflet";

import { AuthContext } from "utilities/Authentication";
import { ResolversEnum } from "utilities/Config";

import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { gql, useClient, useQuery } from "urql";
import { string } from "yup";
import { DoorRowItem } from "./DoorRowItem";
import { PlaceLiteratureModal } from "./PlaceLiteratureModal";
import TerritoryMap from "./TerritoryMap";
import ResearchAddress from "pages/researchAddress/ResearchAddress";
import { PaginationDataClass, PaginationDataReducer, SearchHeader } from "newComponents/SearchBar";
import { pagination } from "helpers/utils";
import { asCleanDays } from "@fullcalendar/react";
import CardDropdown from "components/common/CardDropdown";

import CopyToClipboardButton from 'newComponents/CopyToClipboardButton';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

var mapCoords = [29.105776, -81.028559];

const GET_TERRITORY_BY_ID = gql`
query getterr($id:ID!){

getTerritoryByID(id:$id){
  id
  congregationID
  territoryName
  gatedCommunity
  doorToDoor
  addresses{
${AddressQueryFields}
  }

  doorToDoorRecord{
    ${DoorToDoorRecordQueryFields}
  }
  
  assignedToPublisherID
  assignedToPublisherName
  checkedOutAt
  version
}
}
`

function calculateAddressActions(addresses: Address[], doorToDoorRecord: DoorToDoorRecord) {
    {
        if (addresses.length == 0 || doorToDoorRecord == null) {
            return {}; //{"":{actions:[],currentState:"", }}
        }

        let output = {};
        let curTimeComparatorForNH = new Date().getTime() - 60000 * 180;

        for (let i = 0; i < addresses.length; i++) {
            let address = addresses[i];
            output[address.id] = { doorToDoorWorkID: doorToDoorRecord.id, actions: [], currentState: "", numberNHs: 0, addressID: address.id }
        }

        for (let i = 0; i < doorToDoorRecord.actions.length; i++) {
            let action = doorToDoorRecord.actions[i] as DTDAction;

            let addressRecord: { doorToDoorWorkID: string, actions: DTDAction[], currentState: "NH" | "H" | "", numberNHs: number, addressID: string } = output[action.addressID]
            if (addressRecord) {

                //if (!addressRecord){
                //    addressRecord = {doorToDoorWorkID:doorToDoorRecord.id,actions:[],currentState:"",numberNHs:0,addressID:action.addressID }
                //    output[action.addressID] = addressRecord
                //}

                addressRecord.actions.push(action)

                if (action.action == "NH") {
                    addressRecord.numberNHs = addressRecord.numberNHs + 1;
                    if (action.date.getTime() < curTimeComparatorForNH) {
                        console.log("NH OLD");
                        addressRecord.currentState = ""
                    } else {
                        console.log("NH CURRENT");
                        addressRecord.currentState = "NH"
                    }

                } else if (action.action == "H") {
                    addressRecord.currentState = "H"
                } else if (action.action == "UNDO") {

                    let d = action.undoDate.getTime()

                    let undoneAction = doorToDoorRecord.actions.find(e => e.date.getTime() == d);
                    if (undoneAction) {
                        if (undoneAction.action == "NH" && !undoneAction.undone) {
                            //undoneAction.undone = true;
                            addressRecord.numberNHs = addressRecord.numberNHs - 1;
                            addressRecord.currentState = "";
                        } else if (undoneAction.action == "H") {
                            //undoneAction.undone = true;
                            addressRecord.currentState = "";
                        }
                    }
                }
            }
        }

        console.log("ADDRESS WORK RECORDS UPDATED");
        console.log(new Date().getTime());
        console.log(output);

        return output
    }
}

const ViewTerritory = () => {

    const [toolTipMessage,setToolTipMessage] = useState("Copy NHs to Clipboard")

    const authContext = useContext(AuthContext)

    const userPermissions = useMemo(function () {
        return {
            placeLiterature: authContext.checkHasPermission({ resolver: [ResolversEnum.placeLiterature] }),
            researchAddress: authContext.checkHasPermission({ resolver: [ResolversEnum.researchAddress] }),
            markDoNotCall: authContext.checkHasPermission({ resolver: [ResolversEnum.markDoNotCall] })
        }
    }, [authContext])

    const { territoryID } = useParams();

    console.log("territoryID");
    console.log(territoryID)
    const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_BY_ID,
        variables: {
            id: territoryID
        },
        requestPolicy: "cache-and-network"
    });

    const [modalState, setModalState] = useState({})

    const [paginationData, paginationReducer] = useReducer(PaginationDataReducer, new PaginationDataClass({
        searchFields: ["address"],
        sortByOptions:
            [

                {
                    customAscending: true, label: "Odd - Even", value: "oddEven", sortFunction: function (paginationData, inputItems) {

                        let asc = 1
                        if (!paginationData.ascending) asc = -1;

                        return inputItems.sort(function (a, b) {
                            let loc = a.streetName.localeCompare(b.streetName);
                            if (loc > 0) return 1;
                            if (loc < 0) return -1;

                            // if (a.addressNumber % 2 == 0 && b.addressNumber % 2 == 0) return 0;
                            if (a.addressNumber % 2 != 0 && b.addressNumber % 2 == 0) return -1;
                            if (a.addressNumber % 2 == 0 && b.addressNumber % 2 != 0) return 1;

                            if (a.addressNumber > b.addressNumber) return 1 * asc;
                            if (a.addressNumber < b.addressNumber) return -1 * asc;
                            return 0;
                        })

                    }
                },

                {
                    customAscending: true, label: "Even - Odd", value: "evenOdd", sortFunction: function (paginationData, inputItems) {

                        let asc = 1
                        if (!paginationData.ascending) asc = -1;

                        return inputItems.sort(function (a, b) {
                            let loc = a.streetName.localeCompare(b.streetName);
                            if (loc > 0) return 1;
                            if (loc < 0) return -1;

                            //if (a.addressNumber % 2 == 0 && b.addressNumber % 2 == 0) return 0;
                            if (a.addressNumber % 2 != 0 && b.addressNumber % 2 == 0) return 1;
                            if (a.addressNumber % 2 == 0 && b.addressNumber % 2 != 0) return -1;

                            if (a.addressNumber > b.addressNumber) return 1 * asc;
                            if (a.addressNumber < b.addressNumber) return -1 * asc;
                            return 0;
                        })

                    }
                },

                {
                    customAscending: true, label: "Unworked - Odd - Even", value: "unworkedOddEven", filterFunction: function (paginationData, inputItems) {
                        return inputItems.filter(function (address) {
                            console.log("filt func");
                            if (address.doNotCall) return false;
                            if (!address.doorToDoorRecord) return true;
                            if (address.doorToDoorRecord.currentState == "") return true;
                            return false;
                        })
                    }, sortFunction: function (paginationData, inputItems) {

                        let asc = 1
                        if (!paginationData.ascending) asc = -1;

                        return inputItems.sort(function (a, b) {
                            let loc = a.streetName.localeCompare(b.streetName);
                            if (loc > 0) return 1;
                            if (loc < 0) return -1;

                            // if (a.addressNumber % 2 == 0 && b.addressNumber % 2 == 0) return 0;
                            if (a.addressNumber % 2 != 0 && b.addressNumber % 2 == 0) return -1;
                            if (a.addressNumber % 2 == 0 && b.addressNumber % 2 != 0) return 1;

                            if (a.addressNumber > b.addressNumber) return 1 * asc;
                            if (a.addressNumber < b.addressNumber) return -1 * asc;
                            return 0;
                        })

                    }
                },

                {
                    customAscending: true, label: "Unworked - Even - Odd", value: "unworkedEvenOdd", filterFunction: function (paginationData, inputItems) {
                        return inputItems.filter(function (address) {
                            if (address.doNotCall) return false;
                            if (!address.doorToDoorRecord) return true;
                            if (address.doorToDoorRecord.currentState == "") return true;
                            return false;
                        })
                    }, sortFunction: function (paginationData, inputItems) {

                        let asc = 1
                        if (!paginationData.ascending) asc = -1;

                        return inputItems.sort(function (a, b) {
                            let loc = a.streetName.localeCompare(b.streetName);
                            if (loc > 0) return 1;
                            if (loc < 0) return -1;

                            // if (a.addressNumber % 2 == 0 && b.addressNumber % 2 == 0) return 0;
                            if (a.addressNumber % 2 != 0 && b.addressNumber % 2 == 0) return 1;
                            if (a.addressNumber % 2 == 0 && b.addressNumber % 2 != 0) return -1;

                            if (a.addressNumber > b.addressNumber) return 1 * asc;
                            if (a.addressNumber < b.addressNumber) return -1 * asc;
                            return 0;
                        })

                    }
                },

                //{ label: "Street Number", value: "streetNumber", field: "addressNumber" },//Should be street then street number

                { label: "Address", value: "address", field: "address" }

            ],

        itemsPerPageOptions: [{ label: "All", value: 99999 }, { label: "5", value: 5 }, { label: "15 per page", value: 15 }, { label: "50 per page", value: 50 }, { label: "100", value: 100 },],
        disablePagination: true, itemsPerPage: 9999
    }));

    const territory : Territory | null = query?.data?.getTerritoryByID || null;

    let unfilteredAddresses = territory?.addresses || [];

    const doorToDoorRecord = query?.data?.getTerritoryByID?.doorToDoorRecord || null;

    const [showHideOptions, setShowHideOptions] = useState(territory?.doorToDoor ? { houseHolders: false, age: true, religion: true, phone: false, notes: false, literature:false } : { houseHolders: true, age: true, religion: true, phone: true, notes: true, literature:true })
    console.log("showHides");
    console.log(showHideOptions);

    const addressWorkRecords: Record<string, { doorToDoorWorkID: string, actions: DTDAction[], currentState: "NH" | "H" | "", numberNHs: number, addressID: string }> = useMemo(() => calculateAddressActions(unfilteredAddresses, doorToDoorRecord)
        , [doorToDoorRecord, unfilteredAddresses.length == 0, doorToDoorRecord?.actions])

    for (let adr of unfilteredAddresses) {
        adr.doorToDoorRecord = addressWorkRecords[adr.id] || null
    }

    console.log("addressWorkRecords");
    console.log(addressWorkRecords);

    const addresses :Address[] = useMemo(() => paginationData.sortInputItems(unfilteredAddresses, paginationData, paginationReducer), [unfilteredAddresses, paginationData]);



    console.log("TERRITORY RESULT");
    console.log(territory)
    console.log(doorToDoorRecord);
    console.log(addresses);

    /*const addressesMap :Record<string,Address> = useMemo(function(){
        let output = {}
        for (let i=0;i<addresses.length;i++){
            output[addresses[i].id] = addresses[i]
        }
        return output
    },[addresses]);*/


    //const addressWorkRecords :Record<string,{doorToDoorWorkID:string,actions:DTDAction[],currentState:"NH"|"H"|"", numberNHs:number, addressID:string }> = calculateAddressActions(addresses,doorToDoorRecord) 



    /*const addressRoadLines: Record<string, GeoJSON> = useMemo(() => {

        if (hasCenteredMap == null){
            mapCoords = centroid(addresses.map((a)=>a.coordinates)).geometry.coordinates ;
            //Move it
        }

        let output = {}
        for (let address of addresses) {
            if (address.roadLine) {
                output[address.id] = {
                    type: "LineString", coordinates: lineOffset(lineString(address.roadLine.coordinates), address.roadLine.properties.side == "left" ? -0.00003 : 0.00003, { units: 'degrees' }).geometry.coordinates//address["roadLine"]["coordinates"]
                };
            }
        }
        return output
    }, [addresses]);*/


    if (query.fetching || query.error || !query?.data?.getTerritoryByID?.addresses) { //!query.data || !query.data.getTerritoryByID || !query.data.getTerritoryByID.addresses) {
        return (
            <BreadCrumbHeader query={query} loading={true}></BreadCrumbHeader>
        )
    }


    return (
        <div className="card">
            <BreadCrumbHeader />

            <PlaceLiteratureModal modalState={modalState} setModalState={setModalState} />



            <div className="card-body bg-light" style={{ paddingTop: 0, paddingBottom: 0 }}>

                <h3 style={{ textAlign: "center", marginTop: 0, marginBottom: 15 }}>{territory.territoryName}<span style={{ display: "inline-block" }}>
                    <CardDropdown  
                    autoClose="outside" >
                        <div className="py-2">
                        {territory?.checkedOutAt && <Dropdown.Header>Checked Out For {Math.floor((new Date().getTime() - territory.checkedOutAt.getTime()) / 60000 / 60 / 24)} Days</Dropdown.Header>}
                            <Dropdown.Item >
                                {showHideOptions.houseHolders &&
                         
                                        
                                        <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, houseHolders: !showHideOptions.houseHolders }) }}>
                                        <Form.Check id="show-hh" inline type="checkbox" checked
                                        onChange={(e) => { setShowHideOptions({ ...showHideOptions, houseHolders: !showHideOptions.houseHolders }) }} />Hide Householders</label>}
                                {!showHideOptions.houseHolders &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, houseHolders: !showHideOptions.houseHolders }) }}>
                                        <Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, houseHolders: !showHideOptions.houseHolders }) }} />Show Householders</label>
                                        }
                            </Dropdown.Item>

                            <Dropdown.Item >
                                {showHideOptions.age &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, age: !showHideOptions.age }) }}>
                                        <Form.Check inline type="checkbox" checked
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, age: !showHideOptions.age }) }} />Hide Age</label>}

                                    {!showHideOptions.age && <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, age: !showHideOptions.age }) }}>
                                        <Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, age: !showHideOptions.age }) }} />Show Age</label>}
                            </Dropdown.Item>

                            <Dropdown.Item >
                                {showHideOptions.religion &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, religion: !showHideOptions.religion }) }}>
                                        
                                        
                                        <Form.Check inline type="checkbox" checked
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, religion: !showHideOptions.religion }) }} />Hide Religion</label>}

                                    {!showHideOptions.religion && <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, religion: !showHideOptions.religion }) }}><Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, religion: !showHideOptions.religion }) }} />Show Religion</label>}
                            </Dropdown.Item>

                            <Dropdown.Item >
                                {showHideOptions.phone &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, phone: !showHideOptions.phone }) }}>
                                        <Form.Check inline type="checkbox" checked
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, phone: !showHideOptions.phone }) }} />Hide Phone</label>}

                                    {!showHideOptions.phone && <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, phone: !showHideOptions.phone }) }}><Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, phone: !showHideOptions.phone }) }} />Show Phone</label>}
                            </Dropdown.Item>

                            <Dropdown.Item >
                                {showHideOptions.notes &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, notes: !showHideOptions.notes }) }}>
                                        <Form.Check inline type="checkbox" checked
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, notes: !showHideOptions.notes }) }} />Hide Notes</label>}

                                    {!showHideOptions.notes && <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, notes: !showHideOptions.notes }) }}><Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, notes: !showHideOptions.notes }) }} />Show Notes</label>}
                            </Dropdown.Item>

                            <Dropdown.Item >
                                {showHideOptions.literature &&
                                    <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, literature: !showHideOptions.literature }) }}>
                                        <Form.Check inline type="checkbox" checked
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, literature: !showHideOptions.literature }) }} />Hide Literature Sent</label>}

                                    {!showHideOptions.literature && <label className="form-label" onClick={() => { setShowHideOptions({ ...showHideOptions, literature: !showHideOptions.literature }) }}><Form.Check inline type="checkbox"
                                        onChange={(e) => { console.log("changed"); setShowHideOptions({ ...showHideOptions, literature: !showHideOptions.literature }) }} />Show Literature Sent</label>}
                            </Dropdown.Item>

                            <Dropdown.Item >
                            <OverlayTrigger 
            overlay={
                <Tooltip>
                    {toolTipMessage}
                </Tooltip>
            }
        >
            <button className="btn btn-falcon-primary" style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 0, paddingBottom: 1 }} onClick={function () {
                
                let output = "";

                for (let address of addresses){

                    let record = addressWorkRecords[address.id]
                
                    let prefix = "";
                    if (record){
                        if (record.currentState == "NH"){
                            prefix = "NH "
                        }else if (record.currentState == "H"){
                            prefix = "AH ";
                        }
                    }

                    if (address.doNotCall){
                        prefix = "DNC ";
                    }

                output = output + prefix + address.address + "\n";

                }
                
                navigator.clipboard.writeText(output);


                setToolTipMessage("Copied!");
                setTimeout(function () { setToolTipMessage("Copy NHs To Clipboard") }, 1000);
            }} > <FontAwesomeIcon style={{ fontSize: 16 }} icon={faCopy}></FontAwesomeIcon></button>
        </OverlayTrigger>
        
                                    <label style={{marginLeft:10}} className="form-label" >
                                        Copy NHs to Clipboard</label>
                            </Dropdown.Item>




                        </div>
                    </CardDropdown></span></h3>


                <MapContainer style={{ height: 200 }} center={mapCoords
                    //[29.105776, -81.028559]
                } zoom={13} scrollWheelZoom={false}>

                    <TerritoryMap territory={territory} inputAddresses={addresses} addressWorkRecords={addressWorkRecords} showHideOptions={showHideOptions} />


                </MapContainer>

                <div style={{ paddingTop: 25, paddingBottom: 10 }}>
                    <SearchHeader paginationData={paginationData} paginationReducer={paginationReducer}></SearchHeader>
                </div>
            </div >
            <div className="card-body" style={{ minHeight: 300, paddingLeft: 0, paddingRight: 0, paddingTop: 0, marginTop: 0 }}>

                {/*<div style={{display:"inline-block"}}><span>Not Home</span><span>Address</span><span>Home</span></div>
    */}
                <Table>{/*striped */}
                    <thead className="bg-light">
                        <tr>
                            <th style={{ width: 100, textAlign: "center", paddingLeft: 10, verticalAlign: "middle" }}>Not Home</th>
                            <th style={{ width: 70, textAlign: "center", paddingRight: 10, verticalAlign: "middle" }}>Home</th>
                            <th style={{ textAlign: "center", verticalAlign: "middle" }}>Address</th>
                            <th style={{ display: "none", textAlign: "center", verticalAlign: "middle" }}>Last Worked</th>
                            <th style={{ display: "none", textAlign: "center", verticalAlign: "middle" }}>Edit</th>
                        </tr>

                    </thead>
                    <tbody>
                        {addresses.map((address, index) => {
                            return (
                                <DoorRowItem showHideOptions={showHideOptions} trStyle={index % 2 != 0 ? { backgroundColor: "var(--falcon-table-striped-bg)" } : {}} key={address.id} setModalState={setModalState} address={address} workRecord={addressWorkRecords[address.id]} userPermissions={userPermissions} />
                            )
                            //return (<tr key={address.id}><td></td><td></td><td>{address.address}</td></tr>)
                            // return (<DoorToDoorAddressListItem key={index} changePoly={changePoly} showDNCModal={(a) => { console.log("hwfjdka"); showModal(a) }} address={address} />)
                        })}
                        {/*out["addresses"].map((address,index)=>{
      return (<tr><td><input onInput={(event)=>{console.log("sup"); console.log(event); }} type="checkbox" id={address["id"] + "_notHomeCheck"} className="form-check-input"/></td><td style={{textAlign:"center"}}>{address["address"]}</td><td><input style={{float:"right"}} type="checkbox" id={address["id"] + "_atHomeCheck"} className="form-check-input"/></td></tr>)
    })*/}
                    </tbody>
                </Table>

            </div></div >
    );




}

export default ViewTerritory;







