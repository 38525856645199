import React, { useContext, useEffect, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom'
import { Nav, Navbar, Row, Col, Collapse } from 'react-bootstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import NavbarVerticalMenu from 'newComponents/NavbarVerticalMenu';
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
//import ToggleButton from './ToggleButton';//
//import routes from 'routes/routes';
import { capitalize } from 'helpers/utils';
//import NavbarTopDropDownMenus from 'components/navbar/top/NavbarTopDropDownMenus';
//import PurchaseCard from './PurchaseCard';
//import bgNavbar from 'assets/img/generic/bg-navbar.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { GET_TERRITORY_CACHE, Territory, TerritoryQueryFields } from 'models/Territory';
import { gql, useQuery } from 'urql';
import CongregationContext from 'newComponents/CongregationContext';
import { AuthContext } from 'utilities/Authentication';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import { isJsxElement, StringMappingType } from 'typescript';
import { ResolversEnum } from 'utilities/Config';
//

import { JsxElement } from 'typescript';

import { faUserCircle, faUserCog,faDraftingCompass, faClipboardList, faWalking, faEnvelope } from '@fortawesome/free-solid-svg-icons';

var retryOnce = false;



const NavbarCollapseMenu = ({ icon, iconSize, badges, title, children, menu }: { badges: { type: string, text: string }[], icon: string, iconSize: string | null, title: string, children: any, menu: any }) => {

  let open = false;
  if (menu.openMenu == title) {
    open = true;
  }

  return (
    <Nav.Item as="li">
      <Nav.Link
        aria-expanded={open}
        onClick={() => {

          if (menu.openMenu == title) {
            menu.setOpenMenu("")
          } else {
            menu.setOpenMenu(title);
          }

          //Open or close
        }}
        className="dropdown-indicator cursor-pointer"//text-500 if disabled route
      >
        <Flex alignItems="center">
          {icon && (
            <span className="nav-link-icon" >
              <FontAwesomeIcon icon={icon} size={iconSize} />
            </span>
          )}

          <span className="nav-link-text ps-1">{title}</span>
          {badges && badges.map((badge, index) => {
            return (
              <SoftBadge key={index} pill bg={badge.type} className="ms-2">
                {badge.text}
              </SoftBadge>
            )
          })}
        </Flex>

      </Nav.Link>
      <Collapse in={open}>
        <div>
          <Nav style={{ marginLeft: 20 }} className="flex-column nav" as="ul">
            {/*<NavbarVerticalMenu routes={route.children} />*/}

            {children}

          </Nav>
        </div>
      </Collapse>
    </Nav.Item>
  )
}



const NavbarItemLink = ({ icon, iconSize, badges, title, href }: { badges: { type: string, text: string }[], icon: string, title: string, href: string, iconSize:string | null }) => {

  return (
    <Nav.Item as="li" onClick={() => { console.log("clicked") }}>
      <NavLink
        //end={route.exact}//Has something to with whether it should be active at certain urls
        //to={route.to}
        to={href}
        //state={{ open: route.to === '/authentication-modal' }}
        className='active nav-link'
      >
        <Flex alignItems="center">
          {icon && (
            <span className="nav-link-icon">
              <FontAwesomeIcon icon={icon} size={iconSize} />
            </span>
          )}
          <span className="nav-link-text ps-1">{title}</span>
          {badges && badges.map((badge, index) => {
            return (
              <SoftBadge key={index} pill bg={badge.type} className="ms-2">
                {badge.text}
              </SoftBadge>
            )
          })}
        </Flex>
      </NavLink>
    </Nav.Item>
  )
}


import { GET_OWN_TERRITORIES } from 'App';

/*const GET_OWN_TERRITORIES = gql`query{
  getOwnTerritories{
    id
    congregationID
    territoryName
    assignedToPublisherID
    checkedOutAt
    doorToDoor
  }
}`*/
//${TerritoryQueryFields}

const HomePage = () => {

  const [openMenu, setOpenMenu] = useState("");

  let authContext = useContext(AuthContext)

  let congregationContext = useContext(CongregationContext)

  const [query, reexecuteTerritoryQuery] = useQuery({
    query: GET_OWN_TERRITORIES, //GET_ALL_TERRITORIES,//_FOR_PUBLISHER,
    variables: {
      congregationID: "1"
      //id: params.selectedPublisher.id
    },
    requestPolicy: "cache-and-network" //"cache-and-network"
  });

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  //const [Routes,SetRoutes] = useState(routes);

  const [CurrentRoute, SetCurrentRoute] = useState(null);

  let myTerritories = []

  if (query.error && retryOnce == false && query.error.message.includes("not authorized")) {
    retryOnce = true;
    setTimeout(() => { reexecuteTerritoryQuery() }, 300);
  }

  if (!( query.error)) {
    if (query.data && query.data.getOwnTerritories) {

      myTerritories = query.data.getOwnTerritories.filter((terr: Territory) => terr.assignedToPublisherID == authContext.currentUser.id //congregationContext.publisher.publisherID
      ).sort(function(a,b){   
        if (!a.checkedOutAt)return -1;
        if (!b.checkedOutAt)return -1;

        a = a.checkedOutAt.getTime();
        b = b.checkedOutAt.getTime()

        if (a > b)return -1;
        if (a < b)return 1;
        return 0
      })
    }
  }

  console.log("myTerritories");
  console.log(myTerritories);

  if (myTerritories.length >= 1){
    console.log("myTerritories complete");
        console.log(new Date().getTime());
  }

  let allBadges = {}
  let terrBadges = {}
  let now = new Date().getTime();
  for (let territory of myTerritories) {
    terrBadges[territory["id"]] = []
    let t = territory.checkedOutAt.getTime()
    if (t + 60000 * 60 * 24 * 1 > now) {
      terrBadges[territory["id"]].push({ type: 'success', text: 'New' })
      allBadges["New"] = { type: 'success', text: 'New' }
    } else if (t + 60000 * 60 * 24 * 90 < now) {
      terrBadges[territory["id"]].push({ type: 'warning', text: 'Overdue' })
      allBadges["Overdue"] = { type: 'warning', text: 'Overdue' }
    }
  }

  console.log(terrBadges);





  return (
    <div className="card">
      <BreadCrumbHeader />

      <div className="card-body" style={{ paddingTop: 12 }}>

        <div className="navbar-vertical-content">
          <Nav className="flex-column" as="ul">

            <NavbarCollapseMenu badges={Object.values(allBadges)} title="My Territories" icon="map" menu={{ openMenu, setOpenMenu }} >
              {myTerritories.map((territory, index) => {
                return (
                  <NavbarItemLink badges={terrBadges[territory.id]} key={index} icon={territory.doorToDoor ? faWalking : "envelope"} iconSize={territory.doorToDoor ? null : "sm"} title={territory.territoryName}   href={"/viewTerritory/" + territory.id} />
                )
              })}

            </NavbarCollapseMenu>

            {authContext.checkHasPermission({resolver: [ResolversEnum.checkInTerritories,ResolversEnum.checkOutTerritories,ResolversEnum.changeAddressTerritoryID,ResolversEnum.developmentOnly,ResolversEnum.getAllPublishers,ResolversEnum.createPublisher]}) && <NavbarCollapseMenu title="Manage Territories" icon="cog" menu={{ openMenu, setOpenMenu }} >

              {authContext.checkHasPermission({resolver: [ResolversEnum.checkInTerritories,ResolversEnum.checkOutTerritories]})  && <NavbarItemLink icon={faClipboardList}  title="Assign Territories" href="/assignTerritory/NP" ></NavbarItemLink>}

              {authContext.checkHasPermission({resolver: ResolversEnum.getAllTerritories}) && <NavbarItemLink icon="list"  title="View Territories" href="/viewTerritories" ></NavbarItemLink>}

              {authContext.checkHasPermission({resolver: ResolversEnum.changeAddressTerritoryID}) && <NavbarItemLink icon={faDraftingCompass}  title="Draw Territories" href="/modifyTerritoryMap" ></NavbarItemLink> }

              {authContext.checkHasPermission({resolver: ResolversEnum.developmentOnly}) && <NavbarItemLink title="Upload Territory JSON" href="/uploadTerritoryJSON" ></NavbarItemLink>}

            </NavbarCollapseMenu> }

            {authContext.checkHasPermission({resolver: [ResolversEnum.getAllPublishers,ResolversEnum.createPublisher]}) && <NavbarCollapseMenu title="Manage Publishers" icon={faUserCog} menu={{ openMenu, setOpenMenu }} >

{authContext.checkHasPermission({resolver: ResolversEnum.getAllPublishers}) && <NavbarItemLink icon="users"  title="View Publishers" href="/viewPublishers" ></NavbarItemLink>}

{authContext.checkHasPermission({resolver: ResolversEnum.createPublisher}) && <NavbarItemLink icon="user-plus"  title="Create Publisher" href="/createPublisher" ></NavbarItemLink>} 
</NavbarCollapseMenu> }

          </Nav>
        </div>
      </div></div>
  )


};

export default HomePage;
