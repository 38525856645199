import { gql } from "urql";

export class Publisher {//Typescript Class

  constructor(input: Publisher) {
    //super(input);
    Object.assign(this, input);
  }

  id?:string;
  updatedAt?:string;
  createdAt?:string;
  version?:number;

  firstName?: string;
  
  lastName?: string;
  
  middleName?: string;

  displayName?: string;
  
  email?: string;

  backupEmail?: string;

  phone?: string;
  
  backupPhone?: string;

  roles?: number[] = []

  congregationID?: string;
  domain?: string;
  userID?: string;
  userName?: string;
  //domainRoles?: Record<string,UserDomainRoles>//Not necessarily stored on server?//Used for caching by system?
}

export const PublisherQueryFields = `
id
congregationID
domain
userID
userName
roles

firstName
lastName
middleName
displayName

email
phone

version
`

export const GET_PUBLISHER_CACHE = gql`
query getPubCache($congregationID:ID!){
  getPublisherCache(congregationID:$congregationID){
${PublisherQueryFields}
  }
}`

export const UPDATE_PUBLISHER_CACHE = gql`mutation updatePubCache($congregationID:ID!,$lastUpdated:Date!){
  updatePublisherCache(lastUpdated:$lastUpdated,congregationID:$congregationID){
    ${PublisherQueryFields}
  }
}`