import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import Main from './Main';
import Main from './Main';
import 'helpers/initFA';

Date.prototype.toJSON = function () {
  return "" + this.getTime();
}

//import { ApolloClient, InMemoryCache, ApolloProvider, gql,useQuery } from '@apollo/client';
//import {useAuthState} from "react-firebase-hooks/auth";

//const client = new ApolloClient({
//  uri: 'https://23t87si4ij.execute-api.us-east-1.amazonaws.com/graphql', //'https://flyby-gateway.herokuapp.com/',
//  cache: new InMemoryCache(),
//});

if (window.localStorage.getItem("logOutClearCache") != null) {
  console.log("LOGGED OUT, CLEARING");
  window.localStorage.removeItem("authData");
  window.localStorage.removeItem("logOutClearCache")
  window.localStorage.removeItem("GraphCacheDBName");
  window.localStorage.removeItem("firebaseAuthCache");

  const promise = indexedDB.databases()
  promise.then((databases) => {
    console.log("PROMISE DATABASES");
    console.log(databases)
    for (let db of databases) {
      if (db.name?.startsWith("GraphCache")) {
        let DBDeleteRequest = window.indexedDB.deleteDatabase(db.name);
      }
    }
  })

  /*const DBDeleteRequest = window.indexedDB.deleteDatabase("GraphCache");
  
  DBDeleteRequest.onerror =function(e){
    console.log("DELETE ERROR");
    console.log(e)
  }
  
  DBDeleteRequest.onsuccess =function(e){
    console.log("DELETE SUCCESS");
    console.log(e)
    location.reload();
  }*/

}

ReactDOM.render(
  <React.StrictMode>
    <Main>
      <App />
    </Main>
  </React.StrictMode>,
  document.getElementById('main')
);
