import CardDropdown from "components/common/CardDropdown"
import { Address, LiteraturePlacedRecord } from "models/Address"
import { Dropdown } from "react-bootstrap"
import React from "react";
import { DoorToDoorRecordQueryFields, DTDAction } from "models/DoorToDoorRecord";
import { gql, useClient } from "urql";
import { MdPendingActions } from "react-icons/md";

const WORK_DOOR = gql`
mutation work($addressID:ID!,$undoDate:Date,$date:Date!,$doorToDoorRecordID:ID!,$type:String!  ){
    workDoor(undoDate:$undoDate,addressID:$addressID,date:$date,doorToDoorRecordID:$doorToDoorRecordID, type:$type){
        ${DoorToDoorRecordQueryFields}
  }
}`

function removeDuplicateLiterature(ar:LiteraturePlacedRecord[]){
    let arr = [...ar];
//console.log("started removing");
    for (let i =arr.length - 1;i>= 0;i--){
  //      console.log(i);
        let lit = arr[i];

        for (let i2 =0;i2<arr.length;i2++){

        if (i2 != i){
            if ( arr[i2].literature == lit.literature){
    //            console.log("found same lit");
      //          console.log(arr[i2])
        //        console.log(lit);
                if (arr[i2].date?.getTime() >= lit.date?.getTime()){
          //          console.log("filtin");
                    arr.splice(i,1);
                    break;
                    //return false;
                }
            }
        }
    }}
    //console.log("done removing");
    return arr;
}


export function DoorRowItem({ address, setModalState, userPermissions, workRecord, trStyle = {}, showHideOptions }: { showHideOptions: { houseHolders: boolean, age: boolean, religion: boolean, notes: boolean, phone: boolean }, trStyle: any, workRecord?: { doorToDoorWorkID: string, actions: DTDAction[], currentState: "NH" | "H" | "", numberNHs: number, addressID: string }, address: Address, setModalState: any, userPermissions: { placeLiterature: boolean, researchAddress: boolean, markDoNotCall: boolean } }) {

    const workedState = workRecord?.currentState || "";

    //console.log("DoorRowItem");
    //console.log(workRecord);

    const graphQLClient = useClient();



    const OnCheckbox = async function (e) {
        console.log(e);
        console.log(e.target.name);
        console.log(e.target);
        console.log(e.target.checked);
        console.log("workrecord");
        console.log(workRecord);

        if (window.navigator.onLine == false){
            alert("You are not currently connected to the internet.")
            return
            }

        let result = null

        let curDate = new Date();

        try{

        if (e.target.checked == false) {
            let undoDate = null; //new Date()//TODO calculate undoDate

            if (workRecord?.actions.length == 0) {
                return
            }

            let lastRecord = workRecord.actions[workRecord?.actions.length - 1]
            if (lastRecord.action == "UNDO" || lastRecord.undone) {
                return
            }

            console.log("lastRecord")
            console.log(lastRecord);
            console.log({
                addressID: address.id,
                undoDate: lastRecord.date,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "UNDO"
            })

            

            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                undoDate: lastRecord.date,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "UNDO"
            }).toPromise();

        } else if (e.target.name == "NH") {
            //Make NH
            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "NH"
            }).toPromise();

        } else if (e.target.name == "H") {
            //Make H
            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                date: new Date(),
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "H"
            }).toPromise();

        }

        console.log("AFTER WORK_DOOR AWAIT");
        console.log(result);

        if (result.error) {
            console.log("UNDO error");
            alert(result.error.message);
        } else {
            console.log("WORK success");
            console.log(result);
        }

    }catch(e){
        console.log("WORK_DOOR ERROR");
        console.log(e);
    }

    }

    return (
        <>
            <tr style={trStyle}>
                <td style={{ textAlign: "center" }}>
                    <span style={{ fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol", fontWeight:600, visibility: workRecord?.numberNHs ? "visible" : "hidden", marginRight: 10 }}>{workRecord?.numberNHs || "0"}</span>
                    <input name="NH" disabled={workedState == "H" || address.doNotCall} checked={workedState == "NH"} onChange={OnCheckbox} type="checkbox" className="form-check-input NHCheckbox" />
                </td>
                <td style={{ textAlign: "center" }}>
                    <input name="H" disabled={workedState == "NH" || address.doNotCall} checked={workedState == "H"} onChange={OnCheckbox} type="checkbox" className="form-check-input HCheckbox" />
                </td>


                <td style={{ textAlign: "center" }}>
                    <span>
                        <span style={address.doNotCall && { color: "red",fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol" } || {fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}} className={(() => { if (workedState == "NH") { return "strikethroughleft" }; if (workedState == "H") { return "strikethroughright" }; })()}>{address.address}{address.doNotCall && <span><br/>(Do Not Call)</span>}
                        </span>
                        {(userPermissions.placeLiterature || userPermissions.researchAddress || userPermissions.markDoNotCall) && <span style={{ display: "inline-block" }}>
                            <CardDropdown>
                                <div className="py-2">
                                    {userPermissions.placeLiterature && <Dropdown.Item onClick={function () { setModalState({ type: "placeLiterature", address }) }}>Place Literature</Dropdown.Item>}
                                    {userPermissions.researchAddress && <Dropdown.Item href={"#/researchAddress/" + address.id}>Research Address</Dropdown.Item>}
                                    {userPermissions.markDoNotCall && <Dropdown.Item className="text-danger" href={"#/doNotCall/" + address.id}>Do Not Call</Dropdown.Item>}
                                    {/* <Dropdown.Item onClick={() => { params.showDNCModal(address) }} className='text-danger'>Mark Do Not Call</Dropdown.Item> */}
                                </div>
                            </CardDropdown>
                        </span>}
                    </span></td>
            </tr>

            {(() => {

                let hhstrs = []
                address.houseHolders.map(function (houseHolder, index) {
                    let str = [showHideOptions.houseHolders && houseHolder.fullName, ((showHideOptions.age && !showHideOptions.houseHolders && houseHolder.age && "Age: " + houseHolder.age) || showHideOptions.age && houseHolder.age ), showHideOptions.religion && houseHolder.religion, showHideOptions.phone && houseHolder.phone].filter((a) => a).join(", ").trim();
                    if (str) { hhstrs.push(str); }
                })

                if (hhstrs.length == 0 && (!showHideOptions.notes || !address.notes) && (!showHideOptions.literature || !address.literaturePlaced || address?.literaturePlaced?.length ==0)) {
                    return null;
                }


                return (
                    < tr style={trStyle} >
                        <td colSpan="3" style={{ paddingTop: 0, fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol" }}>
                            {hhstrs.map(function (houseHolder, index) {

                                return (
                                    <span key={index} style={{ marginBottom: 10 }}>{houseHolder}<br /></span>
                                )

                                //          let str = [showHideOptions.houseHolders && houseHolder.fullName, showHideOptions.age && houseHolder.age, showHideOptions.religion && houseHolder.religion, showHideOptions.phone && houseHolder.phone].filter((a) => a).join(", ").trim();

                                //        if (!str) {
                                //          return null;
                                //    }


                            })}
                            {showHideOptions.notes && address.notes && <span style={{ marginTop:5,display:"block" }}>Notes: {address.notes}</span>}
                            {showHideOptions.literature && address.literaturePlaced && <>{/*showHideOptions.notes && address.notes && <br/>*/}<span style={{marginTop:5,display:"block"}}>Literature Placed: { removeDuplicateLiterature(address.literaturePlaced).map(function(lit:LiteraturePlacedRecord){
                                return lit.literature + " " + (lit.date?.getMonth() + 1) + "/" + lit.date?.getFullYear().toString().substring(2,4)
                            }).join(", ")}</span></> }
                        </td>
                    </tr>
                )
            })()}




        </>
    )

}

