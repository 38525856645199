import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { AuthContext, getGuestURQLClient } from 'utilities/Authentication';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import { getAuth, sendEmailVerification } from 'firebase/auth';
import { gql, useClient, useQuery } from 'urql';
import { canUseLayoutEffect } from '@apollo/client/utilities';
import { LoadingSpinner } from 'newComponents/LoadingSpinner';
import { auth } from 'firebaseui';

const fbAuth = getAuth();

const REGISTER_AS_NEW_USER = gql`
mutation regis{
    registerAsNewUser{
        id
        congregationID

roles
resolvers

firstName
lastName
middleName
displayName

email
backupEmail
phone
backupPhone

createdAt
updatedAt
updaterName
updaterID
version
        }
    }`

/*const REGISTER_AS_NEW_USER = gql`
mutation regis{
    registerAsNewUser{
        id
        congregationID

roles
resolvers

firstName
lastName
middleName
displayName

email
backupEmail
phone
backupPhone

createdAt
updatedAt
updaterName
updaterID
version
        }
    }`*/

/*
congregationID

roles

firstName
lastName
middleName
displayName

email
backupEmail
phone
backupPhone

createdAt
updatedAt
updaterName
updaterID
version
*/

var unDone = true;

var last

const runRegisterQuery = async function (graphQLClient, authContext, setCurrentPageState) {

    setCurrentPageState("Loading")

    console.log("bef run query in runRegisterQuery");
    console.log(graphQLClient);
    console.log(authContext);
    console.log(setCurrentPageState);
    console.log("bef run 2");
    console.log("bef run 3 new");
    let results = await graphQLClient.mutation(REGISTER_AS_NEW_USER, {}).toPromise()

    /* let results = await graphQLClient.mutation({
         query: REGISTER_AS_NEW_USER,
         variables: {},
         requestPolicy: { requestPolicy: "network-only" }
     }).toPromise();*/
    console.log("aft run query")

    console.log("immed immut");
    console.log({ ...results });


    //Need to handle errors


    //

    console.log("queryResults register as new user");
    console.log(results);

    if (results.data && results.data.registerAsNewUser && results.data.registerAsNewUser.length >= 2) {
        console.log("upd1")
        authContext.dispatchAuthData({ type: "updateUserList", value: results.data.registerAsNewUser });

        setCurrentPageState("Success");
    } else if (results.data && results.data.registerAsNewUser && results.data.registerAsNewUser.length == 1) {
        console.log("upd3")
        authContext.dispatchAuthData({ type: "updateUserList", value: results.data.registerAsNewUser });
        setCurrentPageState("Unconnected");
    } else if (!results.data || results.data.registerAsNewUser || results.data.registerAsNewUser.length == 0) {
        console.log("upd2")
        setCurrentPageState("There was an error creating your account.");

        if (results.error) {
            setCurrentPageState("There was an error. " + results.error.message);
        }

    }

    console.log("upd4")

}

const AwaitDomain = () => {


    const [currentPageState, setCurrentPageState] = useState("Loading");

    let authContext = useContext(AuthContext);

    const graphQLClient = useClient();

    //    let email = auth.userClaims.email;

    const [lastRefreshed, setLastRefreshed] = useState(new Date().getTime());


    useEffect(() => {

        runRegisterQuery(graphQLClient, authContext, setCurrentPageState);

        let interv = setInterval(function () {
            console.log("running interv")
            //if (query && query.data && query.data.registerAsNewUser) {
            if (currentPageState == "Success") {
                fbAuth.currentUser?.getIdToken(true);
                //authContext?.dispatchAuthData({type:"refreshToken"});
                //console.log("interv true");
                //auth.changeAuthState({ refreshToken: true })
            }
        }, 3000);



         return () => { clearInterval(interv) };
    }, []
    )

    /*
    if (query.data && query.data.registerAsNewUser && unDone) {

        //setTimeout(auth.changeAuthState({ refreshToken: true }), 100);

        unDone = false
    }*/

    if (currentPageState == "Loading") {
        //   return (
        //       <></>
        //   )
    };

    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100">
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                    <Logo fontSize="30px" />
                    <Card>
                        <Card.Body className="p-4 p-sm-5">
                            <div className="p-4">

                                <div className="text-center">

                                    {currentPageState == "Loading" && <LoadingSpinner />}


                                    {currentPageState == "Unconnected" && <><span>Your email address {authContext.firebaseEmail}  is not connected to any congregation.<br /><br />Please contact your brothers and ask them to attach your email address to a publisher record.</span><br /><br />
               
                                    <Button onClick={() => { runRegisterQuery(graphQLClient, authContext, setCurrentPageState); }} color="primary" className="mt-3">I've been added</Button>
                                    <Button onClick={() => { authContext.dispatchAuthData({ type: "logOut" }) }} style={{ marginLeft: 15 }} color="primary" className="mt-3">Try A Different Email</Button>
                                    </>}

                                    {currentPageState != "Loading" && currentPageState != "Success" && currentPageState != "Unconnected" && <><p>{currentPageState}</p><br /><br />
                                    
                                    <button onClick={() => { authContext.dispatchAuthData({ type: "logOut" }) }} style={{ marginLeft: "auto", marginRight: "auto" }} className="btn btn-primary">Try A Different Email</button>
                                    </>}



                                    {/*<p>{JSON.stringify(query)}</p>*/}
                                </div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    )

}


export default AwaitDomain;
