import { BaseDatabaseItem } from "./Default"

export class DTDAction {
    date?: Date

    addressID?: string

    userID?: string

    action?: "NH" | "H" | "UNDO"

    undoDate?: Date

    undone?:boolean
}

export class DoorToDoorRecord extends BaseDatabaseItem {
    congregationID?: string

    userID?: string

    publisherID?: string

    territoryID?: string

    territoryName?: string

    actions?: DTDAction[]
}

export const DoorToDoorRecordQueryFields = `
id
congregationID
userID
publisherID
territoryID
territoryName
actions{
    date
    addressID
    userID
    action
    undoDate
}
version`