import { Button, Modal } from "react-bootstrap"
import {gql, useQuery } from "urql";
import React from "react";
import { DoorToDoorRecordQueryFields } from "models/DoorToDoorRecord";
    import { AddressQueryFields } from "models/Address";
import { MapContainer } from "react-leaflet";
import TerritoryMap from "pages/viewTerritory/TerritoryMap";


const GET_TERRITORY_BY_ID = gql`
query getterr($id:ID!){

getTerritoryByID(id:$id){
  id
  congregationID
  territoryName
  gatedCommunity
  doorToDoor
  addresses{
${AddressQueryFields}
  }

  doorToDoorRecord{
    ${DoorToDoorRecordQueryFields}
  }
  
  assignedToPublisherID
  assignedToPublisherName
  checkedOutAt
  version
}
}
`

export function TerritoryMapModal({territoryMapModalData,setTerritoryMapModalData}){

    const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_BY_ID,
        variables: {
            id: territoryMapModalData.territoryID
        },
        requestPolicy: "cache-and-network",
        pause:!territoryMapModalData.territoryID
    })

    const territory = query?.data?.getTerritoryByID || null; 

return (
<Modal show={territoryMapModalData.show} onHide={function () { setTerritoryMapModalData({...territoryMapModalData,show:false}); }}>
<Modal.Header closeButton>
    <Modal.Title>{territory?.territoryName}</Modal.Title>
</Modal.Header>

<Modal.Body >



<MapContainer style={{ height: 400 }} center={[29.105776, -81.028559]} zoom={13} scrollWheelZoom={false}>

                    <TerritoryMap territory={territory} />

                </MapContainer>



</Modal.Body>

<Modal.Footer>
    <Button variant="secondary" onClick={function () { setTerritoryMapModalData({ ...territoryMapModalData, show:false }) }}>Close</Button>
</Modal.Footer>
</Modal>
)
}