//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DefaultAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
//import AppContext from 'context/Context';
import {AuthContext} from "utilities/Authentication";
import { PWAPrompt } from "Main";

const ProfileDropdown = () => {
    const authContext = useContext(AuthContext);
    
  console.log("authContext at profiledropdown");
  console.log(authContext);

    if (authContext.isAuthed == false){
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        href="#!"
        //to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={DefaultAvatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
      {/*  <Dropdown.Item className="fw-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="me-1" />
            <span>Go Pro</span>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item href="#!">Set status</Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/profile">
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Item href="#!">Feedback</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/user/settings">
            Settings
          </Dropdown.Item>*/}
      
       <Dropdown.Item onClick={()=>{console.log("clicked logout"); authContext.dispatchAuthData({type:"logOut"}) }}>
            Login
          </Dropdown.Item>
      {/* <Dropdown.Item onClick={()=>{console.log("clicked"); console.log(authContext); authContext.changeAuthState({"signOut":true});}}>
            Logout
          </Dropdown.Item>*/}



         {/* <Dropdown.Item as={Link} to="/authentication/card/logout">
            Logout
          </Dropdown.Item>*/}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}else{
        
    //if ("photoURL" in authContext.currentUser && authContext.currentUser.photoURL != null){

      //if ("photoURL" in authContext.currentUser && authContext.currentUser.photoURL != null){
        let avatar = authContext.firebasePicture || DefaultAvatar //authContext.user.photoURL;
    //}
    
      return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={avatar} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
        <Dropdown.Header>{authContext.currentUser.displayName}<br/>{authContext.currentDomainName}</Dropdown.Header>
       {/*   <Dropdown.Divider /> */}

       {PWAPrompt.deferredPrompt && <Dropdown.Item onClick={()=>{console.log("install app"); PWAPrompt.deferredPrompt.prompt(); PWAPrompt.deferredPrompt = null;}}>Install App</Dropdown.Item>}

      <Dropdown.Item onClick={()=>{console.log("clicked"); authContext.dispatchAuthData({type:"logOut"});}}>
            Logout
          </Dropdown.Item>

         {/* <Dropdown.Item as={Link} to="/authentication/card/logout">
            Logout
          </Dropdown.Item>*/}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
};

export default ProfileDropdown;
