import React, { useState } from "react";

import { useQuery, gql, useMutation, useClient } from 'urql';

import { AddressQueryFields } from 'models/Address';
import { Col, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { init } from 'echarts';
import ActionButton from "components/common/ActionButton";
import { faTrashAlt, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "newComponents/LoadingButton";
import { time } from "console";

import { ViewAddressHistoryModal } from "./ViewHistoryModal";


const GET_ADDRESS_BY_ID = gql`
query getbyid($id:ID!){
  getAddressByID(id:$id){
    ${AddressQueryFields}
    }
  }
`


const RESEARCH_ADDRESS = gql`
mutation researchAddress($address:AddressInput!){
    researchAddress(data:$address){
      ${AddressQueryFields}
    }
  }
`

//
function formatPhoneNumber(value: string) {
    if (!value) return value;

    // Disallow any alpha characters.
    var phoneNumber = value.replace(/[^\d]/g, "");

    var phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    if (phoneNumberLength < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    if (phoneNumberLength > 13) {
        phoneNumber = phoneNumber.substr(0, 13);
        phoneNumberLength = phoneNumber.length;
    }

    var ccs = phoneNumberLength - 10

    return `+${phoneNumber.slice(0, ccs)} (${phoneNumber.slice(ccs, ccs + 3)}) ${phoneNumber.slice(ccs + 3, ccs + 6)}-${phoneNumber.slice(ccs + 6, ccs + 10)}`;
}
/*
 {validationSchema={YupSchema}}
const YupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too short").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(2, "Too short").required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
    acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions to use the site")
});
*/

const ResearchAddress = ({ addressID }: { addressID: string }) => {

    console.log("adid");
    console.log(addressID);

    const graphqlClient = useClient();

    const [toPause,setToPause] = useState(false);

    const [modalState, setModalState] = useState({show:false})

    //const addressID = params.addressID

    const [query, reexecuteQuery] = useQuery({
        query: GET_ADDRESS_BY_ID,
        variables: {
            id: addressID.trim()
        },
        requestPolicy: "cache-and-network",
        pause:toPause
    });

    console.log("postQuery")

    console.log(query)



    if (query.error) {
setToPause(true);
        console.log(query);
        return (
            <p>{JSON.stringify(query.error)}</p>
        )
    }

    if (!query.data || query.fetching) {
        return (<p>Loading...</p>)
    }


    console.log("getaddr");
    console.log({ ...query });
    //console.log(query.data.getTerritoryByID);

    const address = query?.data?.getAddressByID || null;

    if (!address || !address.version) {
        return (<p>Address Not Found</p>)
    }

    console.log("address");
    console.log(address);

    /*
    TODO:
    
    Householder List
        First Name
        Last Name
        Religion
        Phone
        Age
    
    Notes
    
    DNC?
    Literature Sent?
    
    */

    let initialAddress = { ...address }
    for (let hh of initialAddress.houseHolders) {
        if (hh.firstName == null) {
            hh.firstName = "";
        }
        if (hh.lastName == null) {
            hh.lastName = "";
        }
        if (hh.phone == null) {
            hh.phone = "";
        }
        hh.phone = formatPhoneNumber(hh.phone);

        if (hh.age == null) {
            hh.age = "";
        }
        if (hh.religion == null) {
            hh.religion = "";
        }
    }

    //{/*  isInvalid={touched["houseHolders" + index].lastName && errors["houseHolders" + index].lastName}  */}

    const conAddress = { ...initialAddress }

    return (
        <div>

            <ViewAddressHistoryModal modalState={modalState} setModalState={setModalState}/>

            {/*            <h3 style={{ textAlign: "center" }}>{address.address}</h3>  */}


            {/*  <FloatingLabel
    controlId="floatingInput"
    label="Householder 1 First Name"
    className="mb-3"
  >
    <Form.Control type="text" placeholder="bleh" />
  </FloatingLabel>

    <FloatingLabel controlId="floatingTextarea2" label="Notes">
    <Form.Control
      as="textarea"
      placeholder="Notes"
      style={{ height: 75 }}
      defaultValue={address.notes}
    />
  </FloatingLabel>
*/}




            <datalist id="religions">
                <option value="">Unknown</option>
                <option>Christian</option>
                <option>Protestant</option>
                <option>Catholic</option>
                <option>Jewish</option>
                <option>Eastern Orthodox</option>
                <option>Russian Orthodox</option>
                <option>Islamic</option>
                <option>Hindu</option>
                <option>Buddhist</option>
                <option>Coptic Christian</option>
                <option>Jehovah's Witness</option>
            </datalist>

            <a style={{ marginBottom: 5, marginTop: 5, display: "block" }} onClick={() => { setModalState({...modalState,show:true,address})  }} className="BlueLink">View History</a>

            <Formik
                initialStatus="Submit"//What the button will say by default.
                initialValues={conAddress}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {//onSubmit
                    //console.log("STATE 1")
                    //console.log(setStatus);

                    if (window.navigator.onLine == false){
                        alert("You are not currently connected to the internet.")
                        return
                        }

                    console.log("onSubmit");
                    console.log(values);

                    setSubmitting(true);

                    let result = await graphqlClient.mutation(RESEARCH_ADDRESS, { address: { id: values.id, houseHolders: values.houseHolders.map((hh) => { return { firstName: hh.firstName, lastName: hh.lastName, phone: hh.phone, religion: hh.religion, age: parseFloat(hh.age) } }), notes: values.notes } }
                    ).toPromise();

                    if (result.error) {
                        console.log("WAS AN ERROR");
                        setStatus("ERROR: " + result.error.message);
                        await new Promise(r => setTimeout(r, 3000));
                    } else {
                        console.log("WAS SUCCESSFUL");
                        setStatus("success");
                    }
                    await new Promise(r => setTimeout(r, 3000));

                    setSubmitting(false);
                    setStatus("Submit");

                    console.log("research result");
                    console.log(result);


                    //Can access values here
                    //can setStatus

                    //TODO: send mutation



                }}//End onSubmit

            >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
                values,
                errors,
                touched,
                //  isValid,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                // and other goodies 
            }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    <FieldArray
                        name="houseHolders"
                        render={arrayHelpers => (<>
                            {values.houseHolders.map((houseHolder, index) => {

                                console.log(values);
                                console.log(houseHolder);
                                console.log(index);

                                return (
                                    <div key={index} style={{ paddingTop: 10, marginLeft: -20, paddingLeft: 20, marginRight: -20, paddingRight: 20, borderTop: index % 2 == 1 ? "1px solid whitesmoke" : "", borderBottom: index % 2 == 1 ? "1px solid whitesmoke" : ""  }} className={index % 2 == 1 && "bg-light" || ""}>
                                        <ActionButton onClick={() => { console.log("CLICKITY CLICK " + index); arrayHelpers.remove(index) }} style={{ position: "absolute", right: 10, width: 20, color: "#778899" }} icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        <h5 style={{ textAlign: "center" }}>Householder #{index + 1}</h5>


                                        <Row>
                                            <Col sm={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>First Name</Form.Label>
                                                    <Form.Control
                                                        placeholder={'First Name'}
                                                        value={values.houseHolders[index].firstName}
                                                        name={`houseHolders[${index}].firstName`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].firstName`} /></span>
                                                </Form.Group>
                                            </Col><Col sm={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        placeholder={'Last Name'}
                                                        value={values.houseHolders[index].lastName}
                                                        name={`houseHolders[${index}].lastName`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"

                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].lastName`} /></span>
                                                </Form.Group>
                                            </Col></Row>
                                        <Row>
                                            <Col md={4} sm={6} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Phone</Form.Label>
                                                    <Form.Control
                                                        placeholder={'Phone'}
                                                        value={formatPhoneNumber(values.houseHolders[index].phone)}
                                                        name={`houseHolders[${index}].phone`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"

                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].phone`} /></span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} sm={6} xs={12}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Age</Form.Label>
                                                    <Form.Control
                                                        placeholder={'Age'}
                                                        value={values.houseHolders[index].age}
                                                        name={`houseHolders[${index}].age`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="number"
                                                        min="0"
                                                        max="150"
                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].age`} /></span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={4} sm={6} xs={12}>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Religion</Form.Label>
                                                    <Form.Control

                                                        list="religions"

                                                        placeholder={'Religion'}
                                                        value={values.houseHolders[index].religion}
                                                        name={`houseHolders[${index}].religion`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].religion`} /></span>

                                                </Form.Group>

                                                {/*   <Form.Group className="mb-3">
                    <Form.Label>Religion</Form.Label>
                    <Form.Control
                        placeholder={'Religion'}
                        value={values.houseHolders[index].religion}
                        name={`houseHolders[${index}].religion`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                    />
                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].religion`} /></span>
                </Form.Group>
                        */}

                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}</>)}
                    ></FieldArray>

                    <div>
                        <FieldArray
                            name="houseHolders"
                            render={arrayHelpers => (<>



                                {values.houseHolders.length < 5 && <a style={{ marginBottom: 5, marginTop: 5, display: "block" }} onClick={() => { arrayHelpers.push({ firstName: "", lastName: "", religion: "", age: "", phone: "" }) }} className="BlueLink">+ Add New Householder</a>}

                                {/*<ActionButton onClick={()=>{arrayHelpers.push({firstName:"",lastName:"",religion:"",age:"",phone:""})}} style={{display:"inline-block", width:20,color:"#2c7be5"}} icon="plus-square" title="Delete" variant="action" className="p-0" />
            */}

                            </>)} />


                    </div>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Notes</Form.Label>
                                <Form.Control
                                    placeholder={'Notes'}
                                    value={values.notes}
                                    name={`notes`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    as="textarea"
                                    rows={2}
                                />
                                <span className="invalid-feedback"><ErrorMessage name="notes" /></span>
                            </Form.Group>
                        </Col>
                    </Row>

                   {/* <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                                placeholder={'Notes'}
                                value={values.notes}
                                name={`notes`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="notes" /></span>
                        </Form.Group>
                    </Col></Row> */}

                    <LoadingButton type="submit" loading={isSubmitting} status={status} className="w-100" >Submit</LoadingButton>

                </Form>

            )}
            </Formik>





            {/*<div className="form-floating">
        <textarea defaultValue={address.notes} className="form-control" id="floatingTextarea2" placeholder="Notes" style={{height: 100}}></textarea>
        <label for="floatingTextarea2">Notes</label>
        </div>*/}

        </div>
    );

};

export default ResearchAddress;

