import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import envelope from 'assets/img/icons/spot-illustrations/16.png';
import { AuthContext } from 'utilities/Authentication';
import Section from 'components/common/Section';
import Logo from 'components/common/Logo';
import { applyActionCode, sendEmailVerification } from 'firebase/auth';

import { getAuth } from 'firebase/auth';
import { canUseLayoutEffect } from '@apollo/client/utilities';
import { responsePathAsArray } from 'graphql';

const VerifyEmail = () => {
//

    //const searchParams = useSearchParams();



    let auth = useContext(AuthContext);

    let email = auth.firebaseEmail //auth.userClaims.email;

    /*useEffect(()=>{

        let mode = searchParams.get("mode")
        let actionCode = searchParams.get("oobCode")

        if (mode == "verifyEmail" && actionCode){
            applyActionCode(getAuth(), actionCode).then((resp)=>{
                console.log("ACTION CODE APPLIED");
                console.log("resp");
                console.log(resp)

                //auth.dispatchAuthData({type:"refresh"});
            })
        }

    },[])*/

    return (
        <Section className="py-0">
            <Row className="flex-center min-vh-100"> {/*py-6 adds padding */}
                <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
                    <Logo fontSize="30px" />
                    <Card>
                        <Card.Body className="p-4 p-sm-5">
                            <div className="p-4">

                                <div className="text-center">
                                    <img
                                        className="d-block mx-auto mb-4"
                                        src={envelope}
                                        alt="sent"
                                        width={100}
                                    />
                                    <h4>Please check your email!</h4>
                                    <p>
                                        An email has been sent to <strong>{email}</strong>. Please click on the
                                        included link to verify your email.
                                    </p>

                                    <Button color="primary"  className="mt-3" onClick={()=>{
                                        
                                        auth.dispatchAuthData({type:"refresh"});
                                        //auth.changeAuthState({refreshToken:true});
                                    }}>I Verified</Button> 
                                    
                                    <Button style={{marginLeft:15}} color="primary" className="mt-3" onClick={()=>{

                                        sendEmailVerification(getAuth().currentUser)
                                        .then(() => {
                                          console.log("verification email sent");
                                          // Email verification sent!
                                          // ...
                                        }).catch((err)=>{
console.log(err);
                                        });
                                   

                                    }}>Resend Email</Button>

                                    {/*    <Button
                                        color="primary"
                                        size="sm"
                                        className="mt-3"
                                    >
                                        <FontAwesomeIcon
                                            icon="chevron-left"
                                            transform="shrink-4 down-1"
                                            className="me-1"
                                        />
                                        Return to login
                                    </Button>*/}
                                </div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Section>
    )

}


export default VerifyEmail;
