import { Spinner } from "react-bootstrap";
import React from 'react';
const PageLoadSpinner = (data)=>{
console.log("opac");
console.log(data);

//;transition: opacity .33s ease-in-out;-moz-transition: opacity .33s ease-in-out;-webkit-transition: opacity .33s ease-in-out;

return (
    <Spinner animation="border" role="status" style={{transition:"opacity .5s ease-in-out",MozTransition:"opacity .5s ease-in-out",WebkitTransition:"opacity .5s ease-in-out",opacity:data["opacity"], display:"block",width:100,height:100,color:"#2c7be5",fontSize:"2em",marginLeft:"auto",marginRight:"auto",left:0,right:0,marginTop:20,marginBottom:20,position:"absolute"}}>
  <span className="visually-hidden">Loading...</span>
</Spinner>
) 

/*(
<div style={{position:"absolute",left:0,right:0,top:0,bottom:0,width:140,height:140,borderRadius:25,backgroundColor:"white",marginTop:150,marginLeft:"auto",marginRight:"auto"}}>
{/*<div className="spinner-border spinner-border-sm text-white" style={{width:100,height:100,color:"#344050 !important",fontSize:"2em",marginLeft:20,marginTop:20,}} role="status"></div>
*//*}
<Spinner animation="border" role="status" style={{width:100,height:100,color:"#2c7be5",fontSize:"2em",marginLeft:20,marginTop:20,}}>
  <span className="visually-hidden">Loading...</span>
</Spinner>
</div>
)*/
}

export default PageLoadSpinner;