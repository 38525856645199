import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import React from 'react';

import { useParams } from 'react-router-dom';

import EditPublisher from './EditPublisher';

const EditPublisherPage = () => {

  const {publisherID} = useParams();

    return (
      <div className="card">
         <BreadCrumbHeader/>
        <div className = "card-body" style={{paddingTop:10}}>
        <EditPublisher publisherID={publisherID} />
        </div>
      </div>
    )

  }

  export default EditPublisherPage