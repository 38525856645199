/*
INSTRUCTIONS

set loading/success/failure as true or false
or status as "anything/normal","success","failure","ERROR: ERRORMESSAGE","loading"

Put normal display text as child of button

className w-100 takes up full width

*/

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { propTypes } from "react-bootstrap/esm/Image"

const LoadingButton = ({ type="button", onClick=()=>{}, style={}, disabled=false, loading = false, success = false, failure = false, status="", children, className = "" }) => {

    if (status == "failure" || status.startsWith("ERROR:")){
        failure = true;
    }else if (status == "success"){
        success = true;
    }else if (status == "loading"){
        loading = true;
    }

    
    let cn = "mt-3 btn"
    if (failure){
        cn = cn + " btn-danger"
    }else{
        cn = cn + " btn-primary"
    }
    cn = cn + " " + className

    console.log("loading");
    console.log(loading);

    return (

        <button onClick={onClick} type={type} disabled={loading||failure||success||disabled}  style={{ ...style, position: "relative" }} className={cn}>

            <div className="SubmitButtonSpinner" style={{ position: "absolute", left: "50%", width: "100%", height: 24, transform: "translate(-50%, 0)", top: "20%" }}>
                <div className="InsideSubmitButtonBox SubmitSpinner" style={{
                    opacity: (() => { if (loading && !success && !failure) return 1; return 0; })(),
                    left: "50%", top: "50%", position: "absolute", transform: "translate(-50%, -50%)", transition: "opacity 0.4s",//-webkitTransition: "opacity 0.4s"
                }}>
                    <div className="spinner-border spinner-border-sm text-white" role="status" />
                </div>

                <div className="InsideSubmitButtonBox SubmitSpinner" style={{
                    opacity: (() => { if (success) return 1; return 0; })(),
                    left: "50%", top: "50%", position: "absolute", transform: "translate(-50%, -50%)", transition: "opacity 0.4s",//-webkitTransition: "opacity 0.4s"
                }}>
                    <FontAwesomeIcon style={{color:"lawngreen"}} icon={faCheckCircle}/>
                </div>

                <div className="InsideSubmitButtonBox SubmitSpinner" style={{
                    opacity: (() => { if (failure && status=="failure") return 1; return 0; })(),
                    left: "50%", top: "50%", position: "absolute", transform: "translate(-50%, -50%)", transition: "opacity 0.4s",//-webkitTransition: "opacity 0.4s"
                }}>
                    <FontAwesomeIcon style={{color:"orange"}} icon={faExclamationCircle}/>
            </div>



            </div>


            <span style={(() => {
                if ((loading == false && success == false) || (failure == true && status.startsWith("ERROR:"))) { return { opacity: 1, transition: "opacity 0.5s" } };
                return { opacity: 0, transition: "opacity 0.5s" }
            })()}>{failure == false && children}{failure == true && <span><FontAwesomeIcon style={{color:"orange"}} icon={faExclamationCircle}/> {status.replaceAll("[GraphQL]","")}</span>}</span>
        </button>

    )

}

export default LoadingButton