import { canUseLayoutEffect } from '@apollo/client/utilities';
import { featureGroup, geoJSON, DivIcon, polygon, layerGroup, tileLayer, control, marker, circle, popup as newpopup, Control } from 'leaflet';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMap } from 'react-leaflet';

import 'leaflet.tilelayer.colorfilter';
import AppContext from 'context/Context';

/*const baseMaps = {
    "Light": layerGroup([tileLayer.colorFilter('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 20
    }), tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 20
    })]),
    "OpenStreetMap": tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
    }),
    "CartoDB Voyager": tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 20
    }),
    "Satellite": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }),
    "Topo": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
    }),
    "World Streep Map": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
    }),
    "Toner": tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        subdomains: 'abcd',
        minZoom: 0,
        maxZoom: 20,
        ext: 'png'
    }),
    "CyclOSM": tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
        maxZoom: 20,
        attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }),
    "Open Topo Map": tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    }),
    "OPNV Karte": tileLayer('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    })
}
*/



function MapTileLayers() {

const styleContext = useContext(AppContext);

let filter = []
if (styleContext.config.isDark){
    filter = [
        'grayscale:100%',
        'invert:100%',
   ]
    filter =[
        'invert:0%',
        'grayscale:100%',
        'bright:50%',
        'contrast:111%',
        'hue:0deg',
        'saturate:1000%'
      ]
}

    /*const [baseLayers,setBaseLayers] = useState({
        "Light": layerGroup([tileLayer.colorFilter('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20,
            filter
        }), tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20
        })]),
        "OpenStreetMap": tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '© OpenStreetMap'
        }),
        "CartoDB Voyager": tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20
        }),
        "Satellite": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        }),
        "Topo": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
        }),
        "World Streep Map": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
        }),
        "Toner": tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
            attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            subdomains: 'abcd',
            minZoom: 0,
            maxZoom: 20,
            ext: 'png'
        }),
        "CyclOSM": tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
            maxZoom: 20,
            attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }),
        "Open Topo Map": tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
            maxZoom: 17,
            attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }),
        "OPNV Karte": tileLayer('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        })
    });*/

    const map = useMap()

    const layerControl = useRef(null);

    //useMemo(function(){}[map])

    useEffect(function(){

        if (layerControl.current){
            map.removeControl(layerControl.current);
        }

console.log("FILTERS");
console.log(filter)

        const baseLayers = {
            "Light": layerGroup([tileLayer.colorFilter('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 20,
                filter
            }), tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 20
            })]),
            /* "Light": tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', //'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
                 {
                     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                     subdomains: 'abcd',
                     maxZoom: 19
                 }),*/
            "OpenStreetMap": tileLayer.colorFilter('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap',
                filter
            }),
            "CartoDB Voyager": tileLayer.colorFilter('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                subdomains: 'abcd',
                maxZoom: 20,
                filter
            }),
            "Satellite": tileLayer.colorFilter('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
                filter
            }),
            "Topo": tileLayer.colorFilter('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
                attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
                filter
            }),
            "World Streep Map": tileLayer.colorFilter('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
                attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
                filter
            }),
            "Toner": tileLayer.colorFilter('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
                attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                subdomains: 'abcd',
                minZoom: 0,
                maxZoom: 20,
                ext: 'png',
                filter
            }),
            "CyclOSM": tileLayer.colorFilter('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
                maxZoom: 20,
                attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                filter
            }),
            "Open Topo Map": tileLayer.colorFilter('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
                maxZoom: 17,
                attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
                filter
            }),
            "OPNV Karte": tileLayer.colorFilter('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                filter
            })
        }

        const territoryPoly = geoJSON(CongregationPoly, {
            style: {
              stroke: true, //enable border
              color: "#000000", //border color, hexadecimal
              dashArray: '10, 20',
              dashOffset: '0',
              weight: 2, //border width
              opacity: .5, //border opacity
              lineCap: "round", //how to style end of a line
              lineJoin: "round", //Makes line joints rounded
              fill: true, //To fill polygon with color or not
              fillColor: "#000000",
              fillOpacity: .0,
              interactive: false,
              fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
            }
          })

        const congregationLayer = layerGroup([
            territoryPoly,
            marker([29.055232, -81.069274], {
                icon: new DivIcon({
                    className: 'kingdom-hall-div-icon',
                    interactive: false,
                    html: '<b class="my-div-span" style="color:black;font-size:14px;white-space:nowrap;display:inline-block;transform:translate(-50%,-50%)">Kingdom Hall</b>'
                })
            }),

            marker(territoryPoly.getBounds().getCenter(), {
                icon: new DivIcon({
                    className: 'kingdom-hall-div-icon',

                    interactive: false,
                    html: '<b class="my-div-span" style="color:black;font-size:16px;white-space:nowrap;display:inline-block;transform:translate(-50%,-50%)">West Spruce Creek Territory</b>'
                })
            })
        ]).addTo(map);

        const lc = control.layers(baseLayers, { "Congregation's Territory": congregationLayer }).addTo(map);

        layerControl.current = lc;

        let currentMapLayer = localStorage.getItem("CurrentMapLayer");
        if (!currentMapLayer) {
            currentMapLayer = "Light";
        }
        baseLayers[currentMapLayer].addTo(map);
    },[styleContext.config.isDark])

    useEffect(function () {
        let conn = map.on("baselayerchange", function (e) {
            localStorage.setItem("CurrentMapLayer", e.name)
        });

        return function () { map.off("baselayerchange")
        }
    }, [])

    return (<></>)
}

export default MapTileLayers

const CongregationPoly = {
    type: "MultiPolygon",
    coordinates: [[[
      [-81.204152, 29.046756000000002],
      [-81.204085, 29.04653],
      [-81.203469, 29.044741999999992],
      [-81.202551, 29.042115],
      [-81.20221300000001, 29.040714999999995],
      [-81.201841, 29.038101999999995],
      [-81.201721, 29.035595],
      [-81.201592, 29.033556],
      [-81.201829, 29.031488],
      [-81.201606, 29.029408999999998],
      [-81.200574, 29.026721],
      [-81.199686, 29.023488],
      [-81.199623, 29.023515999999997],
      [-81.199043, 29.023734999999995],
      [-81.19521300000001, 29.025297],
      [-81.19493900000002, 29.025420999999998],
      [-81.194565, 29.025536000000002],
      [-81.19165, 29.026049000000008],
      [-81.187347, 29.026773000000002],
      [-81.187042, 29.026837999999998],
      [-81.185249, 29.027309000000002],
      [-81.184883, 29.02744100000001],
      [-81.18453199999999, 29.02759200000001],
      [-81.183319, 29.028225000000006],
      [-81.18158000000001, 29.029072000000003],
      [-81.180607, 29.029401000000007],
      [-81.179901, 29.029640000000004],
      [-81.17952, 29.029721999999996],
      [-81.179191, 29.029749],
      [-81.174728, 29.029776],
      [-81.170741, 29.029867000000003],
      [-81.169144, 29.029902999999997],
      [-81.167282, 29.029930000000004],
      [-81.16689300000002, 29.029899999999998],
      [-81.166618, 29.029852],
      [-81.163643, 29.029186],
      [-81.163368, 29.029139999999998],
      [-81.161011, 29.028974999999992],
      [-81.158582, 29.028801000000005],
      [-81.156662, 29.028662],
      [-81.156448, 29.028624],
      [-81.156151, 29.028543],
      [-81.15477499999999, 29.028081000000007],
      [-81.151062, 29.02683599999999],
      [-81.149323, 29.026219999999995],
      [-81.149048, 29.026146],
      [-81.14860499999999, 29.026076999999997],
      [-81.14827699999998, 29.026070000000008],
      [-81.14577600000001, 29.025939999999995],
      [-81.142598, 29.025773999999995],
      [-81.141289, 29.025705],
      [-81.12915, 29.024930999999995],
      [-81.128486, 29.024897999999997],
      [-81.126434, 29.024795999999995],
      [-81.126434, 29.024895],
      [-81.126404, 29.024971000000008],
      [-81.126335, 29.025028000000006],
      [-81.126251, 29.025060999999997],
      [-81.12451300000001, 29.02533300000001],
      [-81.114517, 29.026932000000002],
      [-81.113861, 29.026988999999997],
      [-81.110489, 29.027059999999995],
      [-81.109924, 29.027013999999998],
      [-81.108261, 29.026743000000007],
      [-81.107764, 29.026654],
      [-81.10711, 29.026536999999994],
      [-81.104416, 29.026051000000017],
      [-81.104042, 29.02601200000001],
      [-81.10364699999998, 29.02601],
      [-81.103569, 29.02601200000001],
      [-81.103294, 29.026037000000006],
      [-81.098473, 29.026723999999998],
      [-81.098297, 29.026777],
      [-81.098045, 29.026892],
      [-81.097893, 29.026986999999995],
      [-81.097687, 29.027157000000006],
      [-81.09671, 29.028267],
      [-81.096588, 29.028387],
      [-81.096367, 29.028542000000005],
      [-81.091447, 29.030872],
      [-81.090248, 29.031434999999995],
      [-81.09000400000001, 29.031560999999996],
      [-81.089622, 29.031798999999996],
      [-81.089203, 29.032130999999996],
      [-81.08908099999998, 29.03225100000001],
      [-81.088997, 29.032331000000006],
      [-81.088997, 29.032352000000003],
      [-81.088631, 29.032713],
      [-81.088371, 29.033024000000005],
      [-81.086494, 29.035704],
      [-81.086334, 29.035954000000007],
      [-81.086136, 29.036218999999996],
      [-81.085892, 29.036504999999995],
      [-81.085495, 29.036892000000005],
      [-81.085197, 29.037138],
      [-81.084656, 29.037510000000008],
      [-81.08419, 29.037764000000006],
      [-81.083954, 29.037872000000004],
      [-81.083801, 29.037939000000005],
      [-81.075615, 29.041041999999997],
      [-81.06750500000001, 29.043734],
      [-81.061055, 29.045895],
      [-81.059296, 29.046488000000004],
      [-81.056129, 29.047521],
      [-81.052995, 29.048543000000002],
      [-81.051064, 29.049173],
      [-81.049767, 29.049585000000008],
      [-81.04720300000001, 29.05041299999999],
      [-81.046516, 29.050583],
      [-81.04609700000002, 29.050658999999996],
      [-81.045769, 29.05070100000001],
      [-81.04274, 29.050987000000003],
      [-81.042468, 29.051013000000008],
      [-81.04061900000002, 29.051186],
      [-81.040306, 29.051205],
      [-81.039742, 29.051207],
      [-81.039289, 29.05117600000001],
      [-81.03643, 29.05095100000001],
      [-81.03596499999999, 29.050892000000008],
      [-81.035221, 29.050751000000005],
      [-81.03501900000002, 29.050713000000002],
      [-81.03434, 29.050537000000002],
      [-81.033272, 29.050268000000003],
      [-81.03275499999998, 29.050131],
      [-81.031309, 29.049746000000003],
      [-81.030045, 29.049409999999998],
      [-81.027133, 29.048927],
      [-81.020483, 29.047824000000002],
      [-81.017994, 29.047411],
      [-81.015076, 29.046928],
      [-81.014763, 29.046906999999997],
      [-81.01446499999999, 29.046911000000005],
      [-81.01399999999998, 29.046965000000007],
      [-81.009407, 29.0474],
      [-81.009166, 29.047419000000005],
      [-81.007195, 29.047572999999996],
      [-81.006978, 29.047589999999996],
      [-81.006729, 29.047609000000005],
      [-81.00676699999998, 29.04806100000001],
      [-81.006882, 29.049447999999998],
      [-81.007294, 29.053936],
      [-81.00782000000001, 29.058561],
      [-81.00821100000002, 29.062823000000005],
      [-81.00824, 29.063139],
      [-81.00861400000001, 29.064487],
      [-81.00964400000001, 29.067362],
      [-81.009895, 29.067970000000006],
      [-81.010327, 29.068885000000005],
      [-81.017029, 29.083080000000002],
      [-81.021093, 29.091055],
      [-81.020645, 29.091067999999996],
      [-81.019769, 29.091078],
      [-81.019215, 29.091334000000003],
      [-81.01831599999998, 29.091912000000004],
      [-81.017917, 29.092279999999995],
      [-81.017475, 29.092572],
      [-81.017178, 29.092732999999992],
      [-81.016925, 29.092789],
      [-81.01655299999999, 29.092709000000006],
      [-81.016299, 29.092596],
      [-81.016286, 29.092482999999998],
      [-81.016563, 29.091776999999997],
      [-81.016275, 29.090993999999995],
      [-81.016041, 29.090361],
      [-81.01610499999998, 29.090007],
      [-81.016574, 29.089653000000002],
      [-81.016595, 29.089074999999998],
      [-81.016424, 29.088461],
      [-81.015764, 29.0882],
      [-81.014804, 29.08838600000001],
      [-81.013334, 29.088646999999998],
      [-81.012865, 29.088218],
      [-81.011586, 29.088144],
      [-81.009966, 29.08806900000001],
      [-81.009305, 29.087231000000003],
      [-81.009092, 29.08616900000001],
      [-81.00894200000002, 29.084678999999998],
      [-81.008239, 29.083412000000006],
      [-81.006652, 29.08261700000001],
      [-81.005632, 29.08304700000001],
      [-81.004588, 29.083633999999996],
      [-81.003665, 29.084127000000006],
      [-81.002801, 29.084347000000008],
      [-81.002129, 29.084159],
      [-81.001752, 29.083740000000002],
      [-81.00142399999999, 29.082822999999998],
      [-81.000506, 29.082421],
      [-80.99968600000001, 29.083022999999997],
      [-80.998899, 29.082679000000006],
      [-80.998801, 29.081447000000004],
      [-80.999719, 29.080272000000004],
      [-80.99919399999999, 29.079526000000005],
      [-80.997948, 29.078007000000003],
      [-80.997423, 29.076546000000004],
      [-80.99762, 29.075198000000004],
      [-80.998342, 29.073995],
      [-80.998309, 29.072848000000004],
      [-80.99775099999998, 29.07173000000001],
      [-80.996866, 29.071272000000008],
      [-80.996079, 29.071128000000005],
      [-80.995817, 29.07147200000001],
      [-80.996604, 29.072676],
      [-80.997128, 29.073565000000006],
      [-80.996735, 29.074625000000005],
      [-80.995718, 29.074682000000003],
      [-80.993783, 29.074710999999997],
      [-80.992603, 29.073937000000008],
      [-80.99281100000002, 29.075217],
      [-80.992867, 29.075630000000004],
      [-80.992869, 29.075995000000002],
      [-80.992874, 29.076948000000005],
      [-80.99287699999998, 29.07776200000001],
      [-80.992889, 29.079082000000003],
      [-80.992912, 29.081047000000005],
      [-80.99292799999999, 29.082699],
      [-80.992952, 29.084455999999996],
      [-80.992958, 29.084909000000003],
      [-80.992973, 29.086066999999996],
      [-80.99298900000001, 29.087183000000003],
      [-80.992996, 29.087763],
      [-80.993011, 29.088724000000003],
      [-80.993027, 29.089773000000005],
      [-80.993034, 29.090898999999997],
      [-80.99312599999999, 29.092402],
      [-80.993141, 29.094784],
      [-80.993164, 29.097525],
      [-80.993187, 29.100789999999993],
      [-80.993187, 29.100925000000004],
      [-80.993187, 29.102020000000003],
      [-80.993195, 29.103556000000005],
      [-80.993202, 29.104452],
      [-80.99321, 29.106663000000005],
      [-80.99326299999998, 29.106863000000004],
      [-80.99508699999998, 29.106851999999996],
      [-80.99556000000001, 29.106848000000006],
      [-80.99709600000001, 29.106839000000004],
      [-80.99803200000001, 29.106833000000005],
      [-80.998878, 29.106827000000006],
      [-80.999397, 29.106825000000008],
      [-81.001404, 29.106899],
      [-81.00183099999998, 29.106915],
      [-81.002434, 29.106909],
      [-81.005135, 29.106884],
      [-81.010239, 29.106831000000003],
      [-81.011284, 29.106831000000003],
      [-81.01327499999998, 29.106830999999996],
      [-81.015419, 29.106830999999996],
      [-81.017555, 29.106845999999997],
      [-81.018456, 29.106850000000005],
      [-81.019699, 29.106857],
      [-81.021751, 29.106871000000005],
      [-81.02288, 29.106879000000003],
      [-81.023422, 29.106895],
      [-81.023834, 29.106901],
      [-81.025917, 29.106949000000007],
      [-81.02668, 29.107967000000006],
      [-81.026779, 29.107758000000004],
      [-81.026901, 29.107560999999993],
      [-81.027, 29.107409],
      [-81.027145, 29.107225],
      [-81.027435, 29.106941000000006],
      [-81.027679, 29.106748999999997],
      [-81.028069, 29.106483000000008],
      [-81.028496, 29.106236000000003],
      [-81.02874, 29.106777000000008],
      [-81.030441, 29.109979999999993],
      [-81.030922, 29.110882000000004],
      [-81.031929, 29.11226700000001],
      [-81.033638, 29.113856999999992],
      [-81.03476699999999, 29.114716000000012],
      [-81.044378, 29.120511],
      [-81.04557, 29.12122899999999],
      [-81.047737, 29.122527999999996],
      [-81.05601, 29.12753500000001],
      [-81.056564, 29.127869000000004],
      [-81.057213, 29.128262],
      [-81.058243, 29.129056],
      [-81.059525, 29.130045],
      [-81.061745, 29.132389],
      [-81.062859, 29.133656999999996],
      [-81.063424, 29.134646000000004],
      [-81.064849, 29.13714],
      [-81.065247, 29.137837999999995],
      [-81.06764999999999, 29.141888000000005],
      [-81.072533, 29.150263],
      [-81.07352399999999, 29.151995],
      [-81.0753, 29.155061],
      [-81.075509, 29.155420999999997],
      [-81.075607, 29.155608999999995],
      [-81.076691, 29.157503],
      [-81.07675900000001, 29.157609999999995],
      [-81.077651, 29.159162000000006],
      [-81.07952899999998, 29.16243],
      [-81.081604, 29.166042000000004],
      [-81.082687, 29.168024000000003],
      [-81.083099, 29.167844999999996],
      [-81.08683, 29.166252000000004],
      [-81.08664700000001, 29.165535000000006],
      [-81.08522, 29.160740000000008],
      [-81.08466899999999, 29.15871],
      [-81.08318300000002, 29.152388000000002],
      [-81.087639, 29.150534],
      [-81.088631, 29.150121999999996],
      [-81.100437, 29.145169000000003],
      [-81.11080899999999, 29.140818000000003],
      [-81.11300699999998, 29.139822000000013],
      [-81.11541700000001, 29.138823000000002],
      [-81.117973, 29.137237999999993],
      [-81.118972, 29.136414000000002],
      [-81.119064, 29.136337],
      [-81.12061300000002, 29.135069000000005],
      [-81.12204, 29.133674999999997],
      [-81.12397000000001, 29.131782999999995],
      [-81.126259, 29.129389000000007],
      [-81.135223, 29.119885999999997],
      [-81.14325699999999, 29.111401000000008],
      [-81.147125, 29.107244],
      [-81.16410099999999, 29.089306],
      [-81.183952, 29.06840500000001],
      [-81.184249, 29.068093000000005],
      [-81.19263499999998, 29.05926100000001],
      [-81.20418799999999, 29.047066]
    ]]]
  }