import { faFilePdf, faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from 'components/common/Flex';
import { customReactSelectStyle } from 'helpers/reactSelectStyle';
import useWindowDimensions from 'helpers/useWindowDimensions';
import { GET_PUBLISHER_CACHE, Publisher } from "models/Publisher";
import { GET_TERRITORY_CACHE, Territory } from "models/Territory";
import React, { useMemo } from "react";
import { useState, useReducer } from "react";
import { Button, Col, Dropdown, Form, FormControl, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { gql, useClient, useQuery } from "urql";
import CardDropdown from "components/common/CardDropdown"

import { faRulerHorizontal as faRuler, faWalking, faMap } from '@fortawesome/free-solid-svg-icons';

import { SearchHeader, SearchFooter, PaginationDataClass, PaginationDataReducer } from "newComponents/SearchBar";
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';

import { TerritoryWorkRecord } from "models/Territory";
import { Link } from 'react-router-dom';
import { TerritoryMapModal } from './TerritoryMapModal';
import LoadingButton from 'newComponents/LoadingButton';

function WorkHistoryRow({ workRecord }: { workRecord: TerritoryWorkRecord }) {

    if (!workRecord) {
        return <>
        <td className='mobile-table-empty'/>
            <td className='mobile-table-empty'></td>
            <td className='mobile-table-empty'></td>
            <td className='mobile-table-empty'></td>
            <td className='mobile-table-empty'/>
        </>
    }

let daysOut = ""

if (!workRecord.checkedIn){
    daysOut = ""+ Math.ceil( (new Date().getTime() - workRecord.checkedOut.getTime()) / (1000 * 3600 * 24));
}

    return (
        <>
<td className={daysOut != "" && "mobile-table" || "mobile-table-empty"} style={{textAlign:"center"}} data-th="Days Out: " >{daysOut}</td>
            <td className="mobile-table" style={{textAlign:"center"}} data-th="Publisher: " >{workRecord.publisherName}</td>
            <td className="mobile-table" style={{textAlign:"center"}} data-th="Checked Out: " >{workRecord.checkedOut.toLocaleDateString("en-US", {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            })}</td>

            <td className="mobile-table" style={{textAlign:"center"}} data-th="Checked In: " >{workRecord.checkedIn && workRecord.checkedIn.toLocaleDateString("en-US", {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
            })}</td>

        </>
    )


}

export const ViewTerritoriesPage = (params: {}) => {

    const graphQLClient = useClient();

    const [buttonDisabled,setButtonDisabled] = useState(false);

    const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_CACHE, //GET_ALL_PUBLISHERS,
        variables: {
            congregationID: "1"
        },
        requestPolicy: "cache-and-network"//cache-first
    });

    const [territoryMapModalData,setTerritoryMapModalData] = useState({show:false,territoryID:null});

    const windowSize = useWindowDimensions();

    const [showHideOptions, setShowHideOptions] = useState({ daysOut: true, publisher: true, checkedOut: true, checkedIn: true })


    const [paginationData, paginationReducer] = useReducer(PaginationDataReducer, new PaginationDataClass({
        searchFields: ["territoryName"], sortByOptions:
            [
            { label: "Days Out", value: "daysOut", filterFunction: function (paginationData, inputItems) {            
                return inputItems?.filter(function (a) {
                    for (let record of a?.workHistory || []){
                        if (!record?.checkedIn){
                            return true
                        }
                    }
                    return false
                })},

                sortFunction: function (paginationData, inputItems) {       
                    let curTime = new Date().getTime();

                    return inputItems.sort(function (a,b) {

                        let aNumber = 0;
                        let bNumber = 0;

                        for (let record of a.workHistory){
                            if (!record.checkedIn){
                                let t = ( curTime - record.checkedOut.getTime()) / (1000 * 3600 * 24) ;
                                if (t > aNumber) aNumber = t;
                            }
                        }

                        for (let record of b.workHistory){
                            if (!record.checkedIn){
                                let t = ( curTime - record.checkedOut.getTime() ) / (1000 * 3600 * 24) ;
                                if (t > bNumber) bNumber = t;
                            }
                        }

                        if (aNumber > bNumber) return 1;
                        if (bNumber > aNumber) return -1;
                        return 0;
                    }).reverse() }

        
        },
        { label: "Name", value: "territoryName", field: "territoryName" },
            { label: "Assigned To", value: "assignedToPublisherName", field: "assignedToPublisherName" },
            { label: "Door To Door", value: "doorToDoor", field: "doorToDoor" },
            ],

        itemsPerPageOptions: [{ label: "5", value: 5 }, { label: "15", value: 15 }, { label: "50", value: 50 }, { label: "100", value: 100 }, { label: "All", value: 99999 }]

    }));

    let allTerritories = query?.data?.getTerritoryCache.filter((t:Territory)=>t?.numberAddresses);

    console.log("all terrs");
    console.log(allTerritories)

    const filteredTerritories = useMemo(() => paginationData.sortInputItems(allTerritories, paginationData, paginationReducer), [allTerritories, paginationData]);

    console.log("filtered terrs");
    console.log(filteredTerritories);

    if (query.fetching || query.error) {
        return (
            <BreadCrumbHeader query={query} />
        )
    }

    return (
        <div className="card">
            <TerritoryMapModal territoryMapModalData={territoryMapModalData} setTerritoryMapModalData={setTerritoryMapModalData} />

            <BreadCrumbHeader />

            <div className="card-body bg-light"><SearchHeader paginationData={paginationData} paginationReducer={paginationReducer}></SearchHeader></div>

            <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>



                <Table responsive style={{ marginBottom: 0 }}>
                    <thead className="bg-light mobile-table">
                        <tr>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Territory</th>
                            {showHideOptions.daysOut && <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Days Out</th>}
                            {showHideOptions.publisher && <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Publisher</th>}
                            {showHideOptions.checkedOut && <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Checked Out</th>}
                            {showHideOptions.checkedIn && <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Checked In</th>}
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTerritories.map((territory: Territory, index: number) => {
                            return (
                                <React.Fragment key={index}> 
                                    <tr   className={index % 2 == 1 ? "bg-light" : ""}>

                                        <td style={{ paddingLeft: 12,textAlign:"center" }} className="mobile-table" data-th="Territory: "><span> <FontAwesomeIcon icon={territory.doorToDoor ? faWalking : "envelope"} /> {territory.territoryName}
                                        <br/><OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <a onClick={function(){setTerritoryMapModalData({show:true,territoryID:territory.id}) }} style={{color:"var(--falcon-link-color)",marginLeft:0, marginRight:5}}>
                                                {/*Perhaps change assignTerritory so you can set selectedPublisher in the query header?*/}
                                                <FontAwesomeIcon icon={faMap} />
                                            </a>
                                        </OverlayTrigger> <svg style={{ width: 17, height: 17, marginBottom: 5, marginLeft: 5 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"><path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" /></svg>{territory.numberAddresses}</span>  {territory.distanceBetweenHouses && <span> <FontAwesomeIcon style={{ marginLeft: 10 }} icon={faRuler} /> {Math.floor(territory.distanceBetweenHouses)}'</span>}
                                            <span style={{ display: windowSize.isMobile && "inline-block" || "none", float: "right" }}>
                                                <CardDropdown>
                                                    <div className="py-2">
                                                        <Dropdown.Item href={"#/editTerritoryWorkHistory/" + territory.id}>Edit Work Records</Dropdown.Item>

                                                    </div>
                                                </CardDropdown>
                                            </span></td>

                                        <WorkHistoryRow workRecord={territory.workHistory[0]}></WorkHistoryRow>


                                        <td className={!windowSize.isMobile && "mobile-table" || ""} >

                                          {!windowSize.isMobile &&  <span style={{  marginLeft: "auto", marginRight: "auto" }}>
                                                <CardDropdown >
                                                    <div className="py-2">
                                                        <Dropdown.Item href={"#/editTerritoryWorkHistory/" + territory.id}>Edit Work Records</Dropdown.Item>

                                                    </div>
                                                </CardDropdown>
                                            </span>}

                                        </td>
                                    </tr>

                                    {territory.workHistory.map(function (workRecord: TerritoryWorkRecord, index2: number) {

                                        if (index2 == 0) return null;

                                        return (
                                            <tr key={""+index +"." + index2 } className={index % 2 == 1 ? "bg-light" : ""}>
                                                <td className='mobile-table-empty'/>
                                        <WorkHistoryRow key={territory.id+workRecord.checkedOut} workRecord={workRecord}/><td/>
                                        </tr>
                                        )


                                    })}

                                </React.Fragment>

                            )
                        })}
                    </tbody>
                </Table>

            </div>

            <div className="card-footer bg-light">

                <SearchFooter paginationData={paginationData} paginationReducer={paginationReducer} />

            </div>

            <div style={{paddingTop:40}} className="card-footer">

<LoadingButton loading={buttonDisabled} onClick={async function(){
setButtonDisabled(true);

let result = await graphQLClient.query(gql`
query get13($territoriesWithAddressesOnly:Boolean!){
    getS13s(territoriesWithAddressesOnly:$territoriesWithAddressesOnly)
  }
`,{territoriesWithAddressesOnly:false}, {requestPolicy: "network-only"}).toPromise();
console.log("result");
console.log(result)

if (result.error){
    alert(result.error)
}else{
    console.log(result.data);

    window.location.href = result.data.getS13s
}
setButtonDisabled(false);
}} >Download S13s</LoadingButton>

<LoadingButton loading={buttonDisabled} style={{float:"right"}} onClick={async function(){
setButtonDisabled(true);


let result = await graphQLClient.query(gql`
query get13($territoriesWithAddressesOnly:Boolean!){
    getS13s(territoriesWithAddressesOnly:$territoriesWithAddressesOnly)
  }
`,{territoriesWithAddressesOnly:true}, {requestPolicy: "network-only"}).toPromise();
console.log("result");
console.log(result)

if (result.error){
    alert(result.error)
}else{
    console.log(result.data);

    window.location.href = result.data.getS13s
}
setButtonDisabled(false);
}} >Download S13s (New Territories Only)</LoadingButton>

</div>

            {/*<div className="card-body bg-light" style={{ paddingTop: 0 }}>

                {to < numberOfUnassignedTerritories && <button className="btn btn-primary" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                        setPageNumber(pageNumber + 1);
                    }}>Load More</button>
                }

                <i className="mb-0 ms-2" style={{ float: "right", fontSize: 14 }}>
                    Showing {to} of {numberOfUnassignedTerritories} Territories
                </i>

            </div>*/}

        </div>
    )
}