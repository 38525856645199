import React, { useContext } from "react";

import { useQuery, gql, useMutation, useClient } from 'urql';

import { AddressQueryFields } from 'models/Address';
import { Col, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { init } from 'echarts';
import ActionButton from "components/common/ActionButton";
import { faTrashAlt, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AuthContext } from "utilities/Authentication";
import LoadingButton from "newComponents/LoadingButton";
import { ResolversEnum } from "utilities/Config";

const DO_NOT_CALL_ADDRESS = gql`
mutation dnc($address:AddressInput!){
    markDoNotCall(data:$address){
      ${AddressQueryFields}
  }
}`

const GET_ADDRESS_BY_ID = gql`
query getbyid($id:ID!){
  getAddressByID(id:$id){
    ${AddressQueryFields}
    }
  }
`

const DoNotCall = ({ addressID }: { addressID: string }) => {

    const graphqlClient = useClient();
    const authContext = useContext(AuthContext);

    const canEditDNCs = authContext.checkHasPermission({resolver: [ResolversEnum.removeDoNotCall]});

    console.log("CAN EDIT DNCS");
    console.log(canEditDNCs);
    console.log(authContext);
    console.log(ResolversEnum.removeDoNotCall);

    //const addressID = params.addressID

    const [query, reexecuteQuery] = useQuery({
        query: GET_ADDRESS_BY_ID,
        variables: {
            id: addressID
        },
        requestPolicy: "cache-and-network"
    });

    console.log("postQuery")

    console.log(query)
    console.log(reexecuteQuery);

    let loading = true;
    let error = {}
    let data = {}

    console.log(loading);
    console.log(error);
    console.log(data);

    if (!query.data || query.fetching) {
        return (<p>Loading...</p>)
    }

    if (query.error) {
        console.log(query);
        return (
            <p>{JSON.stringify(query.error)}</p>
        )
    }

    console.log("getaddr");
    console.log({ ...query });
    //console.log(query.data.getTerritoryByID);

    const address = query?.data?.getAddressByID || null;

    if (!address || !address.version) {
        return (<p>Address Not Found</p>)
    }

    console.log("address");
    console.log(address);

    /*
type DoNotCallRecord {
  notes: String
  type: String
  date: Date
  lastReviewed: Date
  publisherID: String
  publisherName: String

  attention:String     "Created", "Moved",

  Make the option to add on to notes, but not to delete them...? Could get risky?
  Make DNC notes guarded info?
  I could just go back thru history if I really wanted to.

  Should only publishers be able to mark do not calls? Otherwise there's no paper trail
}

//Make a select box for type, Sex Offender, Disfellowshipped, Do Not Call

//What happened? Notes

//Make a button to disable do not call for service overseers and maybe territory servants?
//lastReviewed defaults to 0, so they can review doNotCalls as they come in?

//Need a way to mark whether the DNC has moved, or needs attention otherwise?

//Need a separate layout if the house is already a doNotCall...
//Maybe an entirely separate page? idk



    */

    const editedAddress = { ...address }

    if (!editedAddress.doNotCall) {
        editedAddress.doNotCall = {}
        if (!editedAddress.doNotCall.type) {
            editedAddress.doNotCall.type = "Do Not Call";
        }
        if (!editedAddress.doNotCall.notes) {
            editedAddress.doNotCall.notes = "";
        }

        editedAddress.doNotCall.Moved = false;

        

    }else{
        if (editedAddress.doNotCall.attention == undefined) {
            editedAddress.doNotCall.attention = "";
        }
    }
    /*
    TODO:
    
    Publisher mark DO Not Call as Moved
    
    Territory Servant/Overseer mark created DNC as Accepted
    
    Territory Overseer mark Moved as Current
    
    Territory Overseer remove DNC
    
    Territory Overseer/Servant? Edit existing notes? Mark as reviewed?
    
    Make a series of checkboxes/radios and a submit button
    
    Allow publishers to remove their own DNCs within a short time?
    
    
    
    
    Make a radio, Reviewed Do Not Call and decided to maintain it
    Reviewed Do Not Call and decided to remove it
    */


    return (
        <div>
            <h4 style={{ textAlign: "center" }}>Do Not Call - {address.address}</h4>


            <Formik
                initialStatus="Submit"//What the button will say by default.
                initialValues={editedAddress}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting, setValues }) => {//onSubmit
                    //console.log("STATE 1")
                    //console.log(setStatus);

                    if (window.navigator.onLine == false){
                        alert("You are not currently connected to the internet.")
                        return
                        }

                    console.log("onSubmit");
                    console.log(values);

                    //Default, attention == null

                    let output = {};
                    if (values.doNotCall.attention == null){
                    output = { id: values.id, doNotCall: { type: values.doNotCall.type, notes: values.doNotCall.notes }}
                    }else if(values.doNotCall.newStatus == "Remove"){
                        output = { id: values.id, doNotCall: { type: values.doNotCall.type, notes: values.doNotCall.notes, attention:"Remove" }}
                    }else if(values.doNotCall.newStatus == "Reconfirm"){
                        output = { id: values.id, doNotCall: { type: values.doNotCall.type, notes: values.doNotCall.notes, attention:"Reconfirm" }}
                    }else if (values.Moved && values.Moved.length >= 1){
                        output = { id: values.id, doNotCall: { type: values.doNotCall.type, notes: values.doNotCall.notes, attention:"Moved" }}
                    }else{
                        output = { id: values.id, doNotCall: { type: values.doNotCall.type, notes: values.doNotCall.notes }}
                    }

                    setSubmitting(true);

                    let result = await graphqlClient.mutation(DO_NOT_CALL_ADDRESS, {address: output}).toPromise();

                    if (result.error) {
                        console.log("WAS AN ERROR");
                        setStatus("ERROR: " + result.error.message);
                        await new Promise(r => setTimeout(r, 3000));
                    } else {

                        const editedAddress = { ...result.data.markDoNotCall }

                        if (!editedAddress.doNotCall) {
                            editedAddress.doNotCall = {}
                            if (!editedAddress.doNotCall.type) {
                                editedAddress.doNotCall.type = "";
                            }
                            if (!editedAddress.doNotCall.notes) {
                                editedAddress.doNotCall.notes = "";
                            }

                            editedAddress.doNotCall.Moved = false;
                        }

                        console.log("WAS SUCCESSFUL");
                        setStatus("success");
                        setValues(editedAddress);
                    }
                    await new Promise(r => setTimeout(r, 3000));

                    setSubmitting(false);
                    setStatus("Submit");

                    console.log("dnc result");
                    console.log(result);


                    //Can access values here
                    //can setStatus

                    //TODO: send mutation



                }}//End onSubmit

            >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
                values,
                errors,
                touched,
                //  isValid,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                // and other goodies 
            }) => (
                <Form noValidate onSubmit={handleSubmit}>

{values.doNotCall.attention != null && <h5 style={{textAlign:"center",marginTop:20,marginBottom:15}}>This address was marked as a do not call{values.doNotCall.publisherName && " by " + values.doNotCall.publisherName} on {values.doNotCall.date.toLocaleDateString()}.</h5>}

                    {/*values.doNotCall.attention != null && <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                    placeholder={'type'}
                                    value={values.doNotCall.attention}
                                    name={'doNotCall.attention'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                >
                                    <option value="">Current</option>
                                    <option value="Created">Created</option>
                                    <option value="Moved">Moved</option>
                                </Form.Select>
                                <span className="invalid-feedback"><ErrorMessage name="doNotCall.attention" /></span>
                            </Form.Group>
                        </Col>
                    </Row>*/}

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Reason</Form.Label>
                                <Form.Select

                                    disabled={values.doNotCall.attention != null && canEditDNCs == false}
                                    placeholder={'type'}
                                    value={values.doNotCall.type}
                                    name={'doNotCall.type'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                >
                                    <option value="Do Not Call">Asked not to return</option>
                                    <option value="Brothers Work">Sex Offender</option>
                                    <option value="Disfellowshipped">Disfellowshipped</option>

                                </Form.Select>
                                <span className="invalid-feedback"><ErrorMessage name="doNotCall.type" /></span>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>What Happened?</Form.Label>
                                <Form.Control
                                    disabled={values.doNotCall.attention != null && canEditDNCs == false}
                                    placeholder={'What Happened?'}
                                    value={values.doNotCall.notes}
                                    name={`doNotCall.notes`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="text"
                                    as="textarea"
                                    rows={3}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {values.doNotCall.attention != null && values.doNotCall.attention != "Moved" && canEditDNCs == false && <Row>
                        <Col>
                            <Form.Group className="mb-3">
                            <Form.Label>
                                <Form.Check
                                    inline
                                    value={"Moved"}
                                    //value={values.doNotCall.attention == "Moved"}
                                    name={`Moved`}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="checkbox"
                                />This householder has moved since being marked as a do not call.</Form.Label>

                            </Form.Group>
                        </Col>
                    </Row>}

                    {((values.Moved && values.Moved.length >= 1) || values.doNotCall.attention == "Moved") && <Row><p>Do not work this house until the service overseer has confirmed whether this house should be removed from the do not call list.</p></Row>}

                    {values.doNotCall.attention != null && canEditDNCs && <Row>
                        <Col>
                            <Form.Group className="mb-3">
                            <Form.Label>
                                <Form.Check
                                    inline
                                    value="Remove"
                                    checked={values.doNotCall.newStatus == "Remove"}
                                    //value={values.doNotCall.Remove}
                                    name={`doNotCall.newStatus`}
                                    onChange={ handleChange  } 
                                    onBlur={handleBlur}
                                    type="radio"
                                />
                                Remove do not call</Form.Label>

                            </Form.Group>
                        </Col>
                    </Row>}

                    {values.doNotCall.attention != null && canEditDNCs && <Row>
                        <Col>
                            <Form.Group className="mb-3">
                            <Form.Label>
                                <Form.Check
                                    inline
                                    value="Reconfirm"
                                    //value={values.doNotCall.Reconfirm}
                                    name={`doNotCall.newStatus`}
                                    onChange={ handleChange  } 
                                    onBlur={handleBlur}
                                    type="radio"
                                />
                                Reconfirm that this is still a do not call</Form.Label>

                            </Form.Group>
                        </Col>
                    </Row>}


                   {(values.doNotCall.attention != "Moved" || canEditDNCs == true) && <LoadingButton type="submit" loading={isSubmitting} status={status} className="w-100" >Submit</LoadingButton> }

                {/*    <button style={{ marginTop: "0px !important" }} type="submit" disabled={(() => {
                        if (isSubmitting) { return true }
                        //  if (Object.keys(errors).length >= 1) { return true }
                        if (Object.keys(touched).length == 0) { return true }
                        return false;
                    })()}

                        className={(() => { if (status == 'Submit') { return 'w-100 btn btn-primary' } return 'mt-3 w-100 btn btn-danger' })()}
                    >{values.doNotCall.attention != null && "Edit Do Not Call" || "Create Do Not Call"}</button> */}



                </Form>

            )}
            </Formik>





        </div>
    );

};

export default DoNotCall;

