import { customReactSelectStyle } from "helpers/reactSelectStyle";
import { Address, AddressQueryFields, LiteraturePlacedRecord } from "models/Address"
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import CreatableSelect from "react-select/creatable";
import { gql, useClient, useQuery } from "urql";

const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    //candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let l = candidate.label.toLowerCase();
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!l.includes(inp[i])) {
            return false
        }
    }
    return true;
};

const GET_ADDRESS_HISTORY = gql`
query getaddrhist($id:ID!){
    getAddressHistory(id:$id){
      ${AddressQueryFields}
      updaterName
      updaterID
      updatedAt
    }
  }
`


export function ViewAddressHistoryModal({ modalState, setModalState}){

    const address = modalState?.address as Address | null

    //const graphQLClient = useClient();

    const [query, reexecuteQuery] = useQuery({
        query: GET_ADDRESS_HISTORY,
        variables: {
            id: modalState?.address?.id || null
        },
        requestPolicy: "cache-and-network",
        pause:!modalState.show
    });


return (
    <Modal show={modalState.show} onHide={function () { setModalState({ ...modalState, show: false }) }}>
    <Modal.Header closeButton>
        <Modal.Title>History - {address?.address}</Modal.Title>
    </Modal.Header>

    {/*address && address.literaturePlaced && address.literaturePlaced.map(function (literature: LiteraturePlacedRecord) {

        return (
            <p>{literature.literature}</p>
        )

    })*/} 

    <Modal.Body>
{query?.data?.getAddressHistory?.map(function(address: Address,index:number){
 //   console.log("iteration");
//console.log(address);

if (address.updaterName == "Admin"){
    return null;
}

return (
    <span key={index}>
        {address.updaterName} on {address?.updatedAt?.toDateString()}<br/>

{address.houseHolders.map(function (houseHolder, index2) {
    let str = [houseHolder.fullName,  houseHolder.age,houseHolder.religion, houseHolder.phone].filter((a) => a).join(", ").trim();
    if (!str) return null;
    return (<span key={index2}>{str}<br/></span>);
})}

{address?.notes && <span>Notes: {address.notes}<br/></span>}
{address?.literaturePlaced?.length && <span>{address.literaturePlaced.map(function(lit:LiteraturePlacedRecord){
                                return lit.literature + " " + (lit.date?.getMonth() + 1) + "/" + lit.date?.getFullYear().toString().substring(2,4)
                            }).join(", ")}<br/></span>}

{address?.doNotCall && <span style={{color:"red"}}>Do Not Call<br/></span>}

        <br/>
    </span>
)

})}

    </Modal.Body>

    <Modal.Footer>
        <Button variant="secondary" onClick={function () { setModalState({ ...modalState, show: false }) }}>Close</Button>
    </Modal.Footer>
</Modal>
)

        }

