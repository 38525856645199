/*
TODOS:
Implement bottom Showing 123 of 456 field.
Implement bottom non-infinite pagination

Implement a gear dropdown at the top right, that lets you show/hide certain fields...? Maybe allow pages to customize this and add on their own fields. Maybe allow changing between infinite and non infinite pagination?
Implement an automatic means of determining when to go to mobile displays? When a field becomes too tall? What if it's a really long field? lol

Implement a custom name for each paginationData, reducer will store any custom data that I want persisted to disk for that page?



*/

import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Flex from "components/common/Flex"
import Pagin from "components/doc-components/Pagination"
import { pagination } from "helpers/utils"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers"
import { multiValueAsValue } from "react-select/dist/declarations/src/utils"

var levDist = function (s: string, t: string) {
    let d = []; //2d matrix
    // Step 1
    let n = s.length;
    let m = t.length;

    if (n == 0) return m;
    if (m == 0) return n;

    //Create an array of arrays in javascript (a descending loop is quicker)
    for (let i = n; i >= 0; i--) d[i] = [];

    // Step 2
    for (let i = n; i >= 0; i--) d[i][0] = i;
    for (let j = m; j >= 0; j--) d[0][j] = j;

    // Step 3
    for (let i = 1; i <= n; i++) {
        let s_i = s.charAt(i - 1);

        // Step 4
        for (let j = 1; j <= m; j++) {

            //Check the jagged ld total so far
            if (i == j && d[i][j] > 4) return n;

            let t_j = t.charAt(j - 1);
            let cost = (s_i == t_j) ? 0 : 1; // Step 5

            //Calculate the minimum
            let mi = d[i - 1][j] + 1;
            let b = d[i][j - 1] + 1;
            let c = d[i - 1][j - 1] + cost;

            if (b < mi) mi = b;
            if (c < mi) mi = c;

            d[i][j] = mi; // Step 6

            //Damerau transposition
            if (i > 1 && j > 1 && s_i == t.charAt(j - 2) && s.charAt(i - 2) == t_j) {
                d[i][j] = Math.min(d[i][j], d[i - 2][j - 2] + cost);
            }
        }
    }
    // Step 7
    return d[n][m];
}

const levenshteinDistance = (str1 = '', str2 = '') => {
    const track = Array(str2.length + 1).fill(null).map(() =>
        Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
        track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
        track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
        for (let i = 1; i <= str1.length; i += 1) {
            const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
            track[j][i] = Math.min(
                track[j][i - 1] + 1, // deletion
                track[j - 1][i] + 1, // insertion
                track[j - 1][i - 1] + indicator, // substitution
            );
        }
    }
    return track[str2.length][str1.length];
};

function GetDescendantProps(target: Object, pathString: string) {
    let arr = pathString.split(".");
    while (arr.length && (target = target[arr.shift()])) {
        if (arr.length && target.length && target.forEach) { // handle arrays
            let remainder = arr.join('.');
            let results = [];
            for (let i = 0; i < target.length; i++) {
                let x = this.GetDescendantProps(target[i], remainder);
                if (x) results = results.concat(x);
            }
            return results;
        }
    }
    return (target) ? [target] : undefined; //single result, wrap in array for consistency
}




export class PaginationDataClass {
    constructor(input: PaginationDataClass) {
        Object.assign(this, input);

        if (input.sortByOptions[0]) {
            this.sortBy = input.sortByOptions[0];
        }
    }

    sortInputItems = function (inputItems: Object[], paginationData: PaginationDataClass, paginationReducer: typeof PaginationDataReducer) {

        if (!inputItems || inputItems == undefined) {
            return [];
        }

        inputItems = [...inputItems];

        console.log("inputItems");
        console.log(inputItems);

        let searchTerm = paginationData.searchTerm.toLowerCase().replaceAll("'", "").replaceAll("#", "").trim();

        //ADD FUZZY SEARCH IF NO RESULTS FOUND

        if (searchTerm) {//Searchs all searchFields for all searchTerms, separated by spaces. A single searchField must contain each of the individual space separated terms to succeed.
            //Later, if nothing is found, add a fuzzy search component.
            let loweredSearchTerms = searchTerm.split(" ");

            // let inputItemsCopy = [...inputItems];

            console.log("ip");


            if (!inputItems || !paginationData){
                console.log("BOTH DONT EXIST");
                return []
            }

            inputItems = inputItems.filter((item) => {
                for (let fieldToSearch of (paginationData?.searchFields || [])) {
                    for (let field of GetDescendantProps(item, fieldToSearch)) {
                        if (field) {
                            field = field.toLowerCase().replaceAll("#", "").replaceAll("'", "").trim();
                            let found = true;
                            for (let loweredSearchTerm of loweredSearchTerms) {
                                if (!field.includes(loweredSearchTerm)) {
                                    found = false
                                    break;
                                }
                            }
                            if (found) {
                                return true;
                            }
                        }
                    }
                }
                return false;
            })


            /*   console.log("BEF LEVEN CHECK");
               if (inputItems.length == 0) {//Redo the search using levehnstein distance
                   console.log("NOTHING FOUND, DOING LEVEN");
                   let LDs = {}
                   inputItemsCopy.forEach((item) => {
                       for (let fieldToSearch of paginationData.searchFields) {
                           for (let field of GetDescendantProps(item, fieldToSearch)) {
                               if (field) {
                                   field = field.toLowerCase().replaceAll("#", "", "'", "").trim();
   
                                   let ld = levDist(searchTerm, field);
                                   if (LDs[item["id"]] == null) {
                                       LDs[item["id"]] = ld;
                                   } else if (ld < LDs[item["id"]]) {
                                       LDs[item["id"]] = ld;
                                   }
   
                               }
                           }
                       }
                   })
   
                   console.log(LDs);
   
                  inputItems=inputItemsCopy.sort(function(a,b){
   
                   let ald = LDs[a["id"]];
                   let bld = LDs[b["id"]];
                   if (!ald && !bld){
                       return 0
                   }else if (!ald){
                       return -1
                   }else if (!bld){
                       return 1
                   }
   
                   if (ald > bld){
                       return 1;
                   }else if (bld > ald){
                       return -1
                   }
                   return 0;
   
                  })
   
                  console.log(inputItems);
                  
                  if (inputItems.length > 3){
                   inputItems.length = 3;
                  }
   
                  console.log(inputItems);
   
               }*/

        }



        //Ensure there's results for the current page before bothering with sorting it.

        if (paginationData.sortBy.filterFunction){
          //  try{
            inputItems = paginationData.sortBy.filterFunction(paginationData, inputItems);
            //}catch(e){
            //    console.error(e);
            //    inputItems = [];
            //}




        }

        if (paginationData.sortBy.sortFunction) {

            inputItems = paginationData.sortBy.sortFunction(paginationData, inputItems);  //inputItems.sort(paginationData.sortBy.sortFunction);

            if (paginationData.ascending == false && !paginationData.sortBy.customAscending) {
                inputItems = inputItems.reverse();
            }

        } else if (paginationData.sortBy.field) {
            let field = paginationData.sortBy.field;
           // if (paginationData.ascending) {
                console.log("SORT BY ASC")
                inputItems = inputItems.sort(function (a, b) {
                    if (!a[field] && !b[field]) {
                        return 0
                    }
                    if (!b[field]) {
                        return -1
                    }
                    if (!a[field]) {
                        return 1
                    }

                    if (a[field].localeCompare) {
                        let comp = a[field].localeCompare(b[field], "en", {numeric:true});
                        if (comp > 0) {
                            return 1
                        } else if (comp < 0) {
                            return -1
                        }

                    } else {
                        if (a[field > b[field]]) {
                            return 1
                        } else if (b[field] > a[field]) {
                            return -1
                        }
                    }

                    return 0

                })
            /*} else {
                console.log("SORT BY DESC")

                inputItems = inputItems.sort(function (a, b) {
                    if (!a[field] && !b[field]) {
                        return 0
                    }
                    if (!b[field]) {
                        return 1
                    }
                    if (!a[field]) {
                        return -1
                    }

                    if (a[field].localeCompare) {
                        let comp = a[field].localeCompare(b[field]);
                        if (comp > 0) {
                            return -1
                        } else if (comp < 0) {
                            return 1
                        }

                    } else {
                        if (a[field > b[field]]) {
                            return -1
                        } else if (b[field] > a[field]) {
                            return 1
                        }
                    }
                    return 0

                })


            }*/

            if (paginationData.ascending == false) {
                inputItems = inputItems.reverse();
            }
        }


        //HOW TO HANDLE PAGINATION...?
        //Make a separate helper function for it...?
        //Just do it by default for right now...

        //Hmm...

        if (inputItems.length <= paginationData.itemsPerPage) {
            //set max page num?
            if (paginationData.currentPage != 0 || paginationData.maxPages != 0) {
                paginationReducer({ type: "setPages", value: { currentPage: 0, maxPages: 0 } });
            }
            return inputItems
        } else {

            let mp = Math.ceil(inputItems.length / paginationData.itemsPerPage) - 1;
            if (mp == -1) {
                mp = 0;
            }
            if (paginationData.maxPages != mp) {
                paginationReducer({ type: "setPages", value: { maxPages: mp } });
            }

            let min = 0;
            if (!paginationData.infiniteScrolling) {
                min = paginationData.currentPage * paginationData.itemsPerPage
            }

            return inputItems.slice(min, (paginationData.currentPage + 1) * paginationData.itemsPerPage)
        }



        //NOW: remove selections for items...?
        //How to do that...? lol

        return inputItems
    }

    disablePagination: boolean = false;
    currentPage: number = 0;
    maxPages: number = 0;
    infiniteScrolling: boolean = true;
    totalNumberItems: number = 0
    totalNumberFilteredItems: number = 0

    inputItems: Object[] = [];
    items: Object[] = [];
    searchTerm: string = "";
    searchFields: string[] = [];

    sortBy: Object[] = [];/* {label: "Last Name", value:"Last Name", sort:function(a:any,b:any){
        if (a.lastName == b.lastName) return 0;
        if (a.lastName && b.lastName && a.lastName > b.lastName) return 1;
        return -1;
    }},*/

    sortByOptions: Object[] = []; /*[{label: "Last Name", value:"Last Name", sort:function(a:any,b:any){
        if (a.lastName == b.lastName) return 0;
        if (a.lastName && b.lastName && a.lastName > b.lastName) return 1;
        return -1;
    }}],*/
    ascending: boolean = true;

    itemsPerPage: number = 5;
    itemsPerPageOptions: Object[] = []; //[{label:"6",value:"6"},{label:"12",value:"12"}],

    selectedItemIDs: Set<string> = new Set();

    shownItemsMinIndex: number = 0;
    shownItemsMaxIndex: number = 0;
}

export const PaginationDataReducer = (paginationData: PaginationDataClass, action: { type: string, value?: any }) => {  //paginationData: PaginationDataClass, action: { type: string, value?: any }) {
    console.log("pag reducer");
    console.log(paginationData);
    console.log("betw")
    console.log(action);

    switch (action.type) {
        case 'toggleAscending': {
            return { ...paginationData, ascending: !paginationData.ascending, currentPage: 0 }
        }

        case 'setSortBy': {
            for (let option of paginationData.sortByOptions) {
                if (option.value == action.value.value) {
                    return { ...paginationData, sortBy: option, currentPage: 0 };
                }
            }
            return paginationData
        }

        case 'setItemsPerPage': {
            return { ...paginationData, itemsPerPage: action.value, currentPage: 0 }
        }

        case 'setSearchTerm': {
            return { ...paginationData, searchTerm: action.value, currentPage: 0 }
        }

        case 'incrementPage': {

            if (paginationData.currentPage >= paginationData.maxPages) {
                return paginationData
            }

            return { ...paginationData, currentPage: paginationData.currentPage + 1 }

        }

        case 'setPages': {
            let changed = false;
            let n = { ...paginationData };
            if ("currentPage" in action.value && action.value.currentPage != n.currentPage) {
                n["currentPage"] = action.value.currentPage;
                changed = true;
            }
            if ("maxPages" in action.value && action.value.maxPages != n.maxPages) {
                n["maxPages"] = action.value.maxPages;
                changed = true;
            }

            if (n.currentPage > n.maxPages) {
                n.currentPage = n.maxPages;
                changed = true;
            }

            if (!changed) {
                console.log("!changed");
                return paginationData;
            }
            console.log("changed");

            return n
        }

        default: {
            throw Error('Unknown action: ' + action.type);
            return paginationData
        }
    }
}

export const SearchHeader = ({ paginationData, paginationReducer, className = "" }: { paginationData: PaginationDataClass, paginationReducer: typeof PaginationDataReducer, className?: string }) => {

    console.log("at searchheader");
    console.log(paginationData)
    console.log(paginationReducer);

    return (
        <Row className={"flex-between-center " + className}>
            {/*<Col
        sm="auto"
        as={Flex}
        alignItems="center"
        className="mb-2 mb-sm-0"
    >
        <Form.Select
            size="sm"
            value={itemsPerPage}
            onChange={({ target }) => {
                setItemsPerPage(Number(target.value));
                setPageNumber(1);
            }}
            style={{ maxWidth: '4.875rem' }}
        >
            <option value={3}>3</option>
            <option value={6}>6</option>
            <option value={9}>9</option>
            <option value={12}>12</option>
            <option value={24}>24</option>
            <option value={200}>200</option>
            <option value={numberOfUnassignedTerritories}>All</option>
        </Form.Select>
        <h6 className="mb-0 ms-2">
            Showing {to} of {numberOfUnassignedTerritories}
        </h6>
    </Col>*/}

            <Col
                sm="auto"
                as={Flex}
                alignItems="center"
                className="mb-2 mb-sm-0"
            >
                <Form as={Row} className="gx-2">

                    <Col xs="auto">
                        <InputGroup size="sm">
                            <InputGroup.Text
                                as={Button}
                                variant="primary"
                            //  onClick={() => {setIsAsc(!isAsc); setPageNumber(1);}}
                            >
                                <FontAwesomeIcon
                                    icon={faSearch} />

                            </InputGroup.Text>
                            <Form.Control
                                className="pe-5"
                                defaultValue=""
                                onChange={({ target }) => {

                                    console.log("at form control onchange");
                                    console.log(paginationData);
                                    console.log({ type: "setSearchTerm", value: target.value })
                                    paginationReducer({ type: "setSearchTerm", value: target.value })

                                    //setPageNumber(1);
                                    //setSearchTerm(target.value);
                                }}
                            >
                            </Form.Control>

                        </InputGroup>
                    </Col>
                </Form>
            </Col>






            <Col sm="auto">
                <Row className="gx-2 align-items-center">

                    <Col xs="auto">
                        <Form as={Row} className="gx-2">
                            {/*<Col xs="auto">
                        <small>Sort by:</small>
                    </Col>*/}
                            <Col xs="auto">
                                <InputGroup size="sm">

                                    <InputGroup.Text
                                        as={Button}
                                        variant="secondary"
                                        onClick={() => { paginationReducer({ type: "toggleAscending" }) }}
                                    >
                                        <FontAwesomeIcon
                                            icon={paginationData.ascending ? 'sort-amount-up' : 'sort-amount-down'}
                                        />
                                    </InputGroup.Text>

                                    <Form.Select
                                        className="pe-5"
                                        defaultValue="price"
                                        onChange={({ target }) => {
                                            paginationReducer({ type: "setSortBy", value: target }) //setSortBy(target.value); setPageNumber(1); 
                                        }}
                                    >

                                        {paginationData.sortByOptions.map((option, index) => {
                                            return (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            )
                                        })}
                                    </Form.Select>


                                   {!paginationData.disablePagination && <Form.Select

                                        size="sm"
                                        value={paginationData.itemsPerPage}
                                        onChange={({ target }) => {

                                            paginationReducer({ type: "setItemsPerPage", value: Number(target.value) })

                                            //   setItemsPerPage(Number(target.value));
                                            //   setPageNumber(1);
                                        }}
                                        style={{ maxWidth: '6.5rem'}}
                                    >
                                        {paginationData.itemsPerPageOptions.map((option, index) => {
                                            return (
                                                <option key={index} value={option.value}>{option.label}</option>
                                            )
                                        })}
                                    </Form.Select>}

                                </InputGroup>
                            </Col>
                        </Form>
                    </Col>
                    {/*<Col xs="auto" className="pe-0">
  <OverlayTrigger
    placement="top"
    overlay={
      <Tooltip>Product {isList ? 'Grid' : 'List'}</Tooltip>
    }
  >
    <Link
      to={`/e-commerce/product/product-${
        isList ? 'grid' : 'list'
      }`}
      className="text-600 px-1"
    >
      <FontAwesomeIcon
        icon={classNames({ th: isList, 'list-ul': isGrid })}
      />
    </Link>
  </OverlayTrigger>
</Col> */}
                </Row>
            </Col>
        </Row>
    )

}

export const SearchFooter = ({ paginationData, paginationReducer }: { paginationData: PaginationDataClass, paginationReducer: typeof PaginationDataReducer }) => {

    return (
        <Row className="flex-between-center">

            {paginationData.currentPage < paginationData.maxPages &&
                <button style={{ marginLeft: "auto", marginRight: "auto", maxWidth: 200 }} className="btn btn-primary" onClick={() => { paginationReducer({ type: "incrementPage" }) }}>Load More...</button>}

            {/*<span style={{float:"right",textAlign:"right"}}><i>Showing xx to xx of xx entries</i></span>*/}

        </Row>
    )

}