import React, { useContext } from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
//import Divider from 'components/common/Divider';
//import SocialAuthButtons from 'newComponents/SocialAuthButtons';
//import Flex from 'components/common/Flex';
import { Formik, ErrorMessage } from 'formik';
//import { setPersistence, signInWithEmailAndPassword, browserSessionPersistence, inMemoryPersistence,browserLocalPersistence} from "firebase/auth";//browserSessionPersistence, inMemoryPersistence,browserLocalPersistence
import * as Yup from 'yup';
import AuthContext from "newComponents/AuthContext";
import {sendPasswordResetEmail, getAuth} from "firebase/auth"


        /* validate={values => {//Validation
      console.log("IS VALIDDING")
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
    
    if (!values.password){
        errors.password = 'Required';
    } else if (values.password.length < 3){
        errors.password = 'Password too short';
    }
    
         return errors;
       }}//End validation*/

 const PasswordResetSchema = Yup.object().shape({
     email: Yup.string().email("Invalid email").required("Required"),
 });

const ForgetPasswordForm = (//{ //hasLabel//, layout}
) => {
    let authContext = useContext(AuthContext);

    return (
        <>
<Formik
      initialStatus="Submit"//What the button will say by default.
        initialValues={{ email: '' }}

validationSchema={PasswordResetSchema}

       
onSubmit= {(values, {setStatus,setSubmitting }) => {//onSubmit

sendPasswordResetEmail(getAuth(), values.email)
  .then(() => {
   toast.success(`A password reset link was sent to ${values.email}.`,{// email was sent to ${email} with password reset link`, {
        theme: 'colored'
      });
    
    setSubmitting(true);
    setStatus("Email Sent");
  })
  .catch((error) => {
    setSubmitting(false);
    //const errorCode = error.code;
    //const errorMessage = error.message;
      //         console.log("errorCode");
               console.log(error.code);
             //  alert(errorMessage);
               
let code = error.code.replace("auth/","").replaceAll("-"," ");
const words = code.split(" ");
for (let i = 0; i < words.length; i++) {
words[i] = words[i][0].toUpperCase() + words[i].substr(1);
}
code = words.join(" ");
setStatus(code);
});
                                                                      
       }}//End on submit
       
     >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
         values,
         errors,
         touched,
      // isValid,
       status,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         // and other goodies 
       }) => (
         <Form noValidate onSubmit={handleSubmit}>
           
                      <Form.Group className="mb-3">
       <Form.Label>Enter your email and we'll send you a link to reset your password.</Form.Label>
        <Form.Control
          placeholder="Email"
          name="email"
           onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           isInvalid={touched.email && errors.email}
          type="email"
        />
           <span className="invalid-feedback"><ErrorMessage name="email" /></span>
      </Form.Group>
           
           <button type="submit" disabled={(()=>{
           if (isSubmitting){return true} 
           if (Object.keys(errors).length >= 1){return true} 
           if (Object.keys(touched).length == 0){return true} 
           return false; })() }
          
          className={ (()=>{if (status=='Submit' || status == "Email Sent"){return 'mt-3 w-100 btn btn-primary'} return 'mt-3 w-100 btn btn-danger' })()}
           >{status}</button>
         </Form>

       )}
     </Formik>

            </>
    );
};

ForgetPasswordForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

ForgetPasswordForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default ForgetPasswordForm;
