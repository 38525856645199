import React, { useState } from "react";
import { useQuery, gql, useMutation, useClient } from 'urql';
import { Col, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import ActionButton from "components/common/ActionButton";
import LoadingButton from "newComponents/LoadingButton";
import { Territory, TerritoryQueryFields, TerritoryWorkRecord } from "models/Territory";

import DatePicker, { registerLocale } from 'react-datepicker';
import CreatableSelect from "react-select/creatable";
import { customReactSelectStyle } from "helpers/reactSelectStyle";
import { useNavigate } from "react-router-dom";
import { GET_PUBLISHER_CACHE, Publisher } from "models/Publisher";


const EDIT_WORK_HISTORY = gql`
      mutation editWorkHistory($territory:TerritoryInput!){
        editTerritoryWorkHistory(territory:$territory){
        ${TerritoryQueryFields}
        }
      }
      `

const GET_TERRITORY_BY_ID = gql`
query getbyid($id:ID!){
  getTerritoryByID(id:$id){
    ${TerritoryQueryFields}
    }
  }
`


const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!candidate.label.includes(inp[i])) {
            return false
        }
    }
    return true;
};

//

/*
 {validationSchema={YupSchema}}
const YupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too short").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(2, "Too short").required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
    acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions to use the site")
});
*/

const EditTerritoryWorkHistory = ({ territoryID }: { territoryID: string }) => {

    console.log("adid");
    console.log(territoryID);

    const navigate = useNavigate();

    const graphqlClient = useClient();

    const [toPause, setToPause] = useState(false);

    //const addressID = params.addressID

    const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_BY_ID,
        variables: {
            id: territoryID.trim()
        },
        requestPolicy: "cache-and-network",
        pause: toPause
    });

    const [pubQuery, reexecutePubQuery] = useQuery({
        query: GET_PUBLISHER_CACHE,
        variables: {
            congregationID: "1"
        },
        requestPolicy: "cache-and-network"
    });

    console.log("postQuery")

    console.log(query)



    if (query.error) {
        setToPause(true);
        console.log(query);
        return (
            <p>{JSON.stringify(query.error)}</p>
        )
    }

    if (!query.data || query.fetching || !pubQuery.data || pubQuery.fetching) {
        return (<p>Loading...</p>)
    }


    console.log("getaddr");
    console.log({ ...query });
    //console.log(query.data.getTerritoryByID);

    const territory: Territory | null = query?.data?.getTerritoryByID || null;

    if (!territory || !territory.version) {
        return (<p>Territory Not Found</p>)
    }

    console.log("territory");
    console.log(territory);


    return (
        <div>



            {/*  <FloatingLabel
    controlId="floatingInput"
    label="Householder 1 First Name"
    className="mb-3"
  >
    <Form.Control type="text" placeholder="bleh" />
  </FloatingLabel>

    <FloatingLabel controlId="floatingTextarea2" label="Notes">
    <Form.Control
      as="textarea"
      placeholder="Notes"
      style={{ height: 75 }}
      defaultValue={address.notes}
    />
  </FloatingLabel>
*/}


            <datalist id="campaigns">
                <option value=""></option>
                <option>2022 Memorial</option>
                <option>2022 Convention</option>
                <option>2023 Memorial</option>
            </datalist>


            <Formik
                initialStatus="Submit"//What the button will say by default.
                initialValues={territory}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {//onSubmit
                    //console.log("STATE 1")
                    //console.log(setStatus);

                    if (window.navigator.onLine == false) {
                        alert("You are not currently connected to the internet.")
                        return
                    }

                    console.log("onSubmit");
                    console.log(values);

                    setSubmitting(true);

                    for (let wh of values.workHistory){
                        delete wh.__typename
                    }

                    let result = await graphqlClient.mutation(EDIT_WORK_HISTORY, { territory: {id:values.id, lastWorked:values.lastWorked, lastResearched:values.lastResearched, workHistory:values.workHistory  }}).toPromise();

                    if (result.error) {
                        console.log("WAS AN ERROR");
                        setStatus("ERROR: " + result.error.message);
                        await new Promise(r => setTimeout(r, 3000));
                    } else {
                        console.log("WAS SUCCESSFUL");
                        setStatus("success");
                    }
                    await new Promise(r => setTimeout(r, 3000));

                    setSubmitting(false);
                    setStatus("Submit");

                    console.log("edit result");
                    console.log(result);


                    //Can access values here
                    //can setStatus

                    //TODO: send mutation



                }}//End onSubmit

            >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
                values,
                errors,
                touched,
                //  isValid,
                setFieldValue,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                // and other goodies 
            }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    <div className="bg-light" style={{ marginLeft: -20, marginRight: -20, paddingLeft: 20, paddingRight: 20 }}>

                        <h5 style={{ textAlign: "center" }}>{territory.territoryName}</h5>

                        <Row style={{ marginBottom: 30 }}>
                            <Col sm={6} xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date Last Worked</Form.Label>

                                    <DatePicker

                                        selected={(values.lastWorked && new Date(values.lastWorked)) || null}
                                        onChange={val => {
                                            setFieldValue(`lastWorked`, val);
                                        }}
                                        formatWeekDay={day => day.slice(0, 3)}
                                        className='form-control'
                                        placeholderText="Select Date"
                                    />

                                    <span className="invalid-feedback"><ErrorMessage name={`lastWorked`} /></span>
                                </Form.Group>
                            </Col>

                            <Col sm={6} xs={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Date Last Researched</Form.Label>

                                    <DatePicker

                                        selected={(values.lastResearched && new Date(values.lastResearched)) || null}
                                        onChange={val => {
                                            setFieldValue(`lastResearched`, val);
                                        }}

                                        //selected={workRecord.checkedIn}
                                        //  onChange={handleChange} //{(date)=>setDate(date)}
                                        formatWeekDay={day => day.slice(0, 3)}
                                        className='form-control'
                                        placeholderText="Select Date"
                                    />

                                    <span className="invalid-feedback"><ErrorMessage name={`lastResearched`} /></span>
                                </Form.Group>
                            </Col>
                        </Row>

                    </div>


                    <FieldArray
                        name="workHistory"
                        render={arrayHelpers => (<>
                            {(values?.workHistory || []).map((workRecord: TerritoryWorkRecord, index) => {

                                console.log(values);
                                console.log(workRecord);
                                console.log(index);

                                return (
                                    <div key={index} style={{ paddingTop: 10, marginLeft: -20, paddingLeft: 20, marginRight: -20, paddingRight: 20, borderTop: index % 2 == 1 ? "1px solid whitesmoke" : "", borderBottom: index % 2 == 1 ? "1px solid whitesmoke" : "" }} className={index % 2 == 1 && "bg-light" || ""}>
                                        <ActionButton onClick={() => { console.log("CLICKITY CLICK " + index); arrayHelpers.remove(index) }} style={{ position: "absolute", right: 10, width: 20, color: "#778899", marginTop: 4 }} icon="trash-alt" title="Delete" variant="action" className="p-0" />
                                        {/*  <h5 style={{ textAlign: "center" }}>{workRecord.publisherName}</h5>*/}

                                        <Row style={{marginBottom:10}}>
                                            <Col sm={6} xs={11}>
                                                <CreatableSelect
                                                    onCreateOption={(option: string) => {
                                                        navigate("/createPublisher?publisherName=" + option);
                                                    }}
                                                    closeMenuOnSelect={true}
                                                    options={pubQuery.data.getPublisherCache.map((item: Publisher) => { return { value: item.id, label: item.displayName } })}
                                                    styles={customReactSelectStyle}
                                                    //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
                                                    isSearchable={true}
                                                    //onCreateOption={handleCreate}
                                                    //menuIsOpen={true}




                                                    value={{ value: workRecord.publisherID, label: workRecord.publisherName }}

                                                    placeholder='Select Publisher...'
                                                    classNamePrefix="react-select2"
                                                    //value={value}
                                                    onChange={(option: Option) => {
                                                        setFieldValue(`workHistory[${index}].publisherID`, option.value)
                                                        setFieldValue(`workHistory[${index}].publisherName`, option.label)
                                                    }}
                                                    //onChange={handleChange}
                                                    menuPlacement="bottom"
                                                    width="100%"
                                                    filterOption={reactSelectCustomSearch}
                                                />
                                            </Col>

                                            <Col sm={6} xs={12}>

                                                <Form.Group className="mb-3" style={{display:"none"}}>
                                                    <Form.Label style={{ marginTop: 10 }}>
                                                        {workRecord.researched &&
                                                            <Form.Check
                                                                inline
                                                                //  placeholder={'Researched'}
                                                                checked
                                                                name={`workHistory[${index}].researched`}
                                                                //onChange={fun
                                                                onChange={e => setFieldValue(`workHistory[${index}].researched`, e.target.checked)}
                                                                onBlur={handleBlur}
                                                                type="checkbox"
                                                            /> || <Form.Check
                                                                inline
                                                                //  placeholder={'Researched'}
                                                                name={`workHistory[${index}].researched`}
                                                                onChange={e => setFieldValue(`workHistory[${index}].researched`, e.target.checked)}
                                                                //onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                type="checkbox"
                                                            />}Did Research</Form.Label>



                                                    <span className="invalid-feedback"><ErrorMessage name={`workHistory[${index.toString()}].researched`} /></span>

                                                </Form.Group>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col sm={4} xs={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Checked Out</Form.Label>

                                                    <DatePicker

                                                        selected={(workRecord.checkedOut && new Date(workRecord.checkedOut)) || null}
                                                        onChange={val => {

                                                            setFieldValue(`workHistory[${index}].checkedOut`, val);
                                                        }}


                                                        formatWeekDay={day => day.slice(0, 3)}
                                                        className='form-control'
                                                        placeholderText="Select Date"
                                                    />

                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].firstName`} /></span>
                                                </Form.Group>
                                            </Col>

                                            <Col sm={4} xs={6}>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Checked In</Form.Label>

                                                    <DatePicker

                                                        selected={(workRecord.checkedIn && new Date(workRecord.checkedIn)) || null}
                                                        onChange={val => {

                                                            setFieldValue(`workHistory[${index}].checkedIn`, val);
                                                        }}

                                                        //selected={workRecord.checkedIn}
                                                        //  onChange={handleChange} //{(date)=>setDate(date)}
                                                        formatWeekDay={day => day.slice(0, 3)}
                                                        className='form-control'
                                                        placeholderText="Select Date"
                                                    />

                                                    <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].firstName`} /></span>
                                                </Form.Group>
                                            </Col>



                                            <Col sm={4} xs={12}>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign</Form.Label>
                                                    <Form.Control

                                                        list="campaigns"

                                                        placeholder={'Campaign'}
                                                        value={workRecord.campaign || ""}
                                                        name={`workHistory[${index}].campaign`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`workHistory[${index.toString()}].campaign`} /></span>

                                                </Form.Group>

                                            </Col>



                                        </Row>



                                    </div>
                                )
                            })}</>)}
                    ></FieldArray>







                    <LoadingButton type="submit" loading={isSubmitting} status={status} className="w-100" >Submit</LoadingButton>

                </Form>

            )}
            </Formik>





            {/*<div className="form-floating">
        <textarea defaultValue={address.notes} className="form-control" id="floatingTextarea2" placeholder="Notes" style={{height: 100}}></textarea>
        <label for="floatingTextarea2">Notes</label>
        </div>*/}

        </div>
    );



};

export default EditTerritoryWorkHistory;

/*
        <datalist id="campaigns">
            <option value=""></option>
            <option>2022 Memorial</option>
            <option>2022 Convention</option>
            <option>2023 Memorial</option>
        </datalist>









        <h5 style={{ textAlign: "center" }}>{workRecord.publisherName}</h5>


                                    <Row>
                                        <Col sm={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Checked Out</Form.Label>

                                                <DatePicker
                                                    selected={workRecord.checkedOut}
                                                    onChange={handleChange} //{(date)=>setDate(date)}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    placeholderText="Select Date"
                                                />

                                                <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].firstName`} /></span>
                                            </Form.Group>
                                        </Col>

                                        <Col sm={6}>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Checked In</Form.Label>

                                                <DatePicker
                                                    selected={workRecord.checkedIn}
                                                    onChange={handleChange} //{(date)=>setDate(date)}
                                                    formatWeekDay={day => day.slice(0, 3)}
                                                    className='form-control'
                                                    placeholderText="Select Date"
                                                />

                                                <span className="invalid-feedback"><ErrorMessage name={`houseHolders[${index.toString()}].firstName`} /></span>
                                            </Form.Group>
                                        </Col>



                                        <Row>


                                            <Col md={4} sm={6} xs={12}>

                                                <Form.Group className="mb-3">
                                                    <Form.Label>Campaign</Form.Label>
                                                    <Form.Control

                                                        list="campaigns"

                                                        placeholder={'Campaign'}
                                                        value={values.workHistory[index].campaign || ""}
                                                        name={`workHistory[${index}].campaign`}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        type="text"
                                                    />
                                                    <span className="invalid-feedback"><ErrorMessage name={`workHistory[${index.toString()}].campaign`} /></span>

                                                </Form.Group>

                                            </Col>
                                        </Row>

*/