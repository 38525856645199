export const customReactSelectStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        //border: '1px solid black',
        color: "rgba(255,0,0,0) !important !important",

        fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important",
        

        backgroundColor: state.isSelected ? "rgba(44, 123, 229, .1)" //"#2c7be5" //"rgba(189,197,209,.2)" 
            : state.isFocused ? "rgba(189,197,209,.1)" : state.isDisabled ? "rgba(24, 103, 209, .1)" :        "white",



        ":active": { backgroundColor: "rgba(189,197,209,.3)" },
        //   ":selected":{backgroundColor:"rgba(189,197,209,.3)"},
        // ":focus":{backgroundColor:"rgba(189,197,209,.1)"},
        //isSelected:{
        //  backgroundColor:"red !important"
        //}
        //  borderBottom: '1px dotted pink',
        //color: state.isSelected ? 'red' : 'blue',
    }),

    multiValue: (provided,state)=>({
...provided,
backgroundColor:"#E9EBEf"
    }),

    singleValue: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgb(52,64,80) !important",
        fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),

    placeholder: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgba(52,64,80,.6) !important",
        
        fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important"
        //fontFamily: "var(--falcon-body-font-family) !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),

    control: (provided, state) => ({
        ...provided,
        borderColor: "rgba(80,80,80,.3) !important",
        borderWidth: "1px !important",
        borderRadius: "4px !important",
        boxShadow: "var(--falcon-box-shadow-inset)",
        border: "1px solid var(--falcon-input-border-color)",
        transition:"border-color .15s ease-in-out,box-shadow .15s ease-in-out",


        ...((()=>{
            let out = {}
            if (state.isFocused){
                return {
                    borderColor: "var(--falcon-input-focus-border-color)",
                    boxShadow: "var(--falcon-box-shadow-inset),0 0 0 .25rem rgba(44,123,229,.25)"
                };
            }
            return {};
            })())
        //focus  border-color: var(--falcon-input-focus-border-color)        box-shadow:var(--falcon-box-shadow-inset),0 0 0 .25rem rgba(44,123,229,.25)
    }),

    //control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    //}),
    //singleValue: (provided, state) => {
    //  const opacity = state.isDisabled ? 0.5 : 1;
    //  const transition = 'opacity 300ms';
    // return { ...provided, opacity, transition };
    //}
}