import { faFilePdf, faFileImage, faMap, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faRulerHorizontal as faRuler } from '@fortawesome/free-solid-svg-icons';
import { faWalking } from '@fortawesome/free-solid-svg-icons';
//import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customReactSelectStyle } from 'helpers/reactSelectStyle';
import useWindowDimensions from 'helpers/useWindowDimensions';

import { Publisher } from "models/Publisher";
import { GET_TERRITORY_CACHE, Territory, TerritoryQueryFields } from "models/Territory";
import React, { useContext } from "react";
import { useState } from "react";
import { Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import CreatableSelect from "react-select/creatable";
import { gql, useClient, useQuery } from "urql";
import { AuthContext } from 'utilities/Authentication';


var debounce = false;


const CHECK_IN_TERRITORIES = gql`
mutation checkIn($didResearch:Boolean,$didCampaign:String,$territoryIDs:[ID!]!){
    checkInTerritories(didResearch:$didResearch,didCampaign:$didCampaign,territoryIDs:$territoryIDs){
        ${TerritoryQueryFields}
    }
  }`



  

const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!candidate.label.includes(inp[i])) {
            return false
        }
    }
    return true;
};

/*
const customReactSelectStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        //border: '1px solid black',
        color: "rgba(255,0,0,0) !important !important",

        

        backgroundColor: state.isSelected ? "rgba(44, 123, 229, .1)" //"#2c7be5" //"rgba(189,197,209,.2)" 
            : state.isFocused ? "rgba(189,197,209,.1)" : "white",


        ":active": { backgroundColor: "rgba(189,197,209,.3)" },
        //   ":selected":{backgroundColor:"rgba(189,197,209,.3)"},
        // ":focus":{backgroundColor:"rgba(189,197,209,.1)"},
        //isSelected:{
        //  backgroundColor:"red !important"
        //}
        //  borderBottom: '1px dotted pink',
        //color: state.isSelected ? 'red' : 'blue',
    }),

    singleValue: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgb(52,64,80) !important",
        fontFamily: "var(--falcon-body-font-family) !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),

    placeholder: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgba(52,64,80,.6) !important",
        fontFamily: "var(--falcon-body-font-family) !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),



    control: (provided, state) => ({
        ...provided,
        borderColor: "rgba(80,80,80,.3) !important",
        borderWidth: "1px !important",
        borderRadius: "4px !important",
        boxShadow: "var(--falcon-box-shadow-inset)",
        border: "1px solid var(--falcon-input-border-color)",
        transition:"border-color .15s ease-in-out,box-shadow .15s ease-in-out",


        ...((()=>{
            let out = {}
            if (state.isFocused){
                return {
                    borderColor: "var(--falcon-input-focus-border-color)",
                    boxShadow: "var(--falcon-box-shadow-inset),0 0 0 .25rem rgba(44,123,229,.25)"
                };
            }
            return {};
            })())
        //focus  border-color: var(--falcon-input-focus-border-color)        box-shadow:var(--falcon-box-shadow-inset),0 0 0 .25rem rgba(44,123,229,.25)
    }),

    //control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    //}),
    //singleValue: (provided, state) => {
    //  const opacity = state.isDisabled ? 0.5 : 1;
    //  const transition = 'opacity 300ms';
    // return { ...provided, opacity, transition };
    //}
}
*/

export const AssignedTerritoriesCard = (params: { selectedPublisher: Publisher, setTerritoryMapModalData, territoryMapModalData }) => {

    const [checkInDebugState,setCheckInDebugState] = useState("");

    const graphQLClient = useClient();
    const authContext= useContext(AuthContext);

    const [query, reexecuteTerritoryQuery] = useQuery({
        query: GET_TERRITORY_CACHE, //GET_ALL_TERRITORIES,//_FOR_PUBLISHER,
        variables: {
            congregationID: authContext.currentDomain
            //id: params.selectedPublisher.id
        },
        requestPolicy: "cache-first" //"cache-and-network"
    });

    const [campaign, setCampaign] = useState("");
    const [researched, setResearched] = React.useState(false);

    
    const [selectedTerritories,setSelectedTerritories] = React.useState<Record<string,boolean>>({});

    const windowDimensions = useWindowDimensions();

    console.log("WUZ UPDATED");
    console.log(selectedTerritories);
//let whateva: Record<string,boolean>;


    if (query.fetching) {
        return (
            <div className="card" style={{ marginTop: 15 }}>
                <div className="card-body">
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    if (query.error) {
        console.log(query);
        return (
            <div className="card" style={{ marginTop: 15 }}>
                <div className="card-body">
                    <p>{JSON.stringify(query.error)}</p>
                </div>
            </div>
        )
    }

    console.log("TERRQUERY");
    console.log(query);

    let publishersTerritories = query.data.getTerritoryCache as Territory[];

    publishersTerritories = publishersTerritories.filter((terr)=>{return terr.assignedToPublisherID == params.selectedPublisher.id}).sort(function(a:Territory,b:Territory){if (a.checkedOutAt > b.checkedOutAt) return 1; return -1;})

    let numberOfSelectedTerritories = 0
    let unSelect :Record<string,boolean> = {}
    for (const [key, value] of Object.entries(selectedTerritories)) {
        if (value){
            let found = false
            for (let t of publishersTerritories){
                if (t.id == key){
                    numberOfSelectedTerritories = numberOfSelectedTerritories + 1;
                    found = true;
                    break
                }
            }
            if (found == false){
                unSelect[key] = false
            }
        }
      }
      if (Object.keys(unSelect).length >= 1){
        console.log("UNSELECTING");
        console.log(unSelect);
        setSelectedTerritories({...selectedTerritories,...unSelect});
      }

    console.log("pubs terrs");
    console.log(publishersTerritories);

    let now = new Date();

    return (
        <div className="card" style={{ marginTop: 15 }}>
            <div className="card-header bg-light">
                <h5>{params.selectedPublisher.firstName}'s Assigned Territories</h5>
            </div>
            <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0, paddingTop:0 }}>

                <Table striped responsive>
                    <thead className="bg-light mobile-table">
                        <tr>
                            <th className="mobile-table" scope="col">Territory</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Checked Out</th>
                        </tr>
                    </thead>
                    <tbody>
                        {publishersTerritories.map((territory: any) => {
                            // let outdatestr = "" + Math.floor((territory.checkedOutAt.getTime() - territory.checkedOutAt.getTime()) / (1000 * 3600 * 24)) + " Days Ago<br>" + (territory.checkedOutAt.getMonth() + 1) + "/" + territory.checkedOutAt.getDate() + "/" + territory.checkedOutAt.getFullYear().toString().slice("-2");
                            return (
                                <tr key={territory.id}>
                                    <td className="mobile-table" style={{paddingLeft:12}}>
                                        <label style={{marginBottom:0}}>
                                            <input type="checkbox" className="form-check-input" checked={selectedTerritories.hasOwnProperty(territory["id"]) && selectedTerritories[territory["id"]]} onChange={(event)=>{
                                                console.log("onCheckboxChange");
                                                console.log(event.target);
                                                console.log(event.target.checked);

                                                if (event.target.checked){
                                                    let newSelectedTerritories = {...selectedTerritories}
                                                    newSelectedTerritories[territory["id"]] = true;
                                                    setSelectedTerritories(newSelectedTerritories)
                                                }else{
                                                    let newSelectedTerritories = {...selectedTerritories}
                                                    newSelectedTerritories[territory["id"]] = false;
                                                    setSelectedTerritories(newSelectedTerritories)
                                                }

                                            }} /> {territory.territoryName}
                                                </label><br/>
                                                <span style={{marginTop:5}}><OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <a onClick={function(){params.setTerritoryMapModalData({show:true,territoryID:territory.id}) }} style={{color:"var(--falcon-link-color)",marginLeft:0, marginRight:5}}>
                                                {/*Perhaps change assignTerritory so you can set selectedPublisher in the query header?*/}
                                                <FontAwesomeIcon icon={faMap} />
                                            </a>
                                        </OverlayTrigger>
                                      
                                      {/*  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <Link to={"/viewTerritory/" + territory.id} style={{color:"var(--falcon-link-color)",marginLeft:8}}>
                                                <FontAwesomeIcon icon={faMap} />
                                            </Link>
                                        </OverlayTrigger>*/}

                                        <span><svg style={{ width: 17, height: 17, marginBottom: 5, marginLeft: 5 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"><path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" /></svg>{territory.numberAddresses}</span>  {territory.distanceBetweenHouses && <span> <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faRuler} /> {Math.floor(territory.distanceBetweenHouses)}'</span>}
</span>
                                        

<div style={{float:"right"}}>

<OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <Link to={"/viewTerritory/" + territory.id} style={{color:"var(--falcon-link-color)",marginRight:10}}>
                                                {/*Perhaps change assignTerritory so you can set selectedPublisher in the query header?*/}

                                                <FontAwesomeIcon icon={faMap} />
                                            </Link>
                                        </OverlayTrigger>



                                        <OverlayTrigger placement="top" overlay={<Tooltip>Download PDF</Tooltip>} >
                                            
                                            {/*<a href={`https://qxcwwcayj6.execute-api.us-east-2.amazonaws.com/ScreenShot?APIKey=Yo&FileOutputName=Test&FullPage=true&ViewPortWidth=800&Delay=-1&png=true&URL=${encodeURIComponent("https://westsprucecreekterritory.s3.amazonaws.com/territory.html?TerritoryNameNumber=")}${encodeURIComponent(territory.territoryName)}`} style={{ color: "red", marginRight: 10 }}>*/}
                                            <a href={`https://qxcwwcayj6.execute-api.us-east-2.amazonaws.com/Print?APIKey=Yo&FileOutputName=${encodeURIComponent(territory.territoryName)}&FullPage=true&ViewPortWidth=800&Delay=-1&png=true&URL=${encodeURIComponent("https://l2b2wk3sw6i7au2alwzuinorga0shzut.lambda-url.us-east-1.on.aws/viewTerritory.html?territoryID=" + territory.id )}`} style={{ color: "red", marginRight: 10 }}>


                                                <FontAwesomeIcon icon={faFilePdf} />
                                            </a>
                                        </OverlayTrigger>

                                        <OverlayTrigger placement="top" overlay={<Tooltip>Download Image</Tooltip>} >
                                            
                                            {/*<a href={`https://qxcwwcayj6.execute-api.us-east-2.amazonaws.com/ScreenShot?APIKey=Yo&FileOutputName=Test&FullPage=true&ViewPortWidth=800&Delay=-1&png=true&URL=${encodeURIComponent("https://westsprucecreekterritory.s3.amazonaws.com/territory.html?TerritoryNameNumber=")}${encodeURIComponent(territory.territoryName)}`} style={{ color: "red", marginRight: 10 }}>*/}
                                            <a href={`https://qxcwwcayj6.execute-api.us-east-2.amazonaws.com/ScreenShot?APIKey=Yo&FileOutputName=${encodeURIComponent(territory.territoryName)}&FullPage=true&DeviceScaleFactor=3&ViewPortWidth=800&Delay=-1&png=true&URL=${encodeURIComponent("https://l2b2wk3sw6i7au2alwzuinorga0shzut.lambda-url.us-east-1.on.aws/viewTerritory.html?territoryID=" + territory.id + "&png=true" )}`} style={{ marginRight: 10 }}>
                                                <FontAwesomeIcon icon={faFileImage} />
                                            </a>
                                        </OverlayTrigger>
                                        
                                        </div>

                                    </td>

                                    

                                        <td className="mobile-table" data-th="Checked Out: " style={{ textAlign: "center", fontSize: 14 }}>
                                            <OverlayTrigger placement="top" overlay={<Tooltip>{(territory.checkedOutAt.getMonth() + 1) + "/" + territory.checkedOutAt.getDate() + "/" + territory.checkedOutAt.getFullYear().toString().slice("-2")}</Tooltip>} ><span>{"" + Math.floor((now.getTime() - territory.checkedOutAt.getTime()) / (1000 * 3600 * 24)) + " Days Ago"}</span></OverlayTrigger>
                                            </td>
                        

                          {/*          <td className="mobile-table" data-th="Checked Out: " style={{ textAlign: "center", fontSize: 14 }}>{"" + Math.floor((now.getTime() - territory.checkedOutAt.getTime()) / (1000 * 3600 * 24)) + " Days Ago"} {windowDimensions.isMobile == false && <br/>}{(territory.checkedOutAt.getMonth() + 1) + "/" + territory.checkedOutAt.getDate() + "/" + territory.checkedOutAt.getFullYear().toString().slice("-2")}</td>
                        */}
                               
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>

</div>
<div className="card-body">

<Row>
<Col md={6} sm={12}>
                <CreatableSelect
                    closeMenuOnSelect={true}
                    options={[{ value: "", label: "Worked Campaign..." }, { value: "Convention 2022", label: "Convention 2022" }, { value: "Memorial 2022", label: "Memorial 2022" }, { value: "Memorial 2023", label: "Memorial 2023" }, { value: "Convention 2023", label: "Convention 2023" }, { value: "Memorial 2024", label: "Memorial 2024" }, { value: "Convention 2024", label: "Convention 2024" } ]}
                    styles={customReactSelectStyle}
                    //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
                    isSearchable={true}
                    //onCreateOption={handleCreate}
                    placeholder='Worked Campaign...'
                    classNamePrefix="react-select2"
                    //value={{value:"",label:"Worked Campaign..."}}
                    //value={value}
                    onChange={(inputValue: any) => { setCampaign(inputValue.value) }}
                    menuPlacement="top"
                    width="100%"
                    filterOption={reactSelectCustomSearch}
                />
</Col>
</Row>
                <label style={{marginTop:20,marginBottom:20}}>
                    <input className="form-check-input"
                        type="checkbox"
                        checked={researched}
                        onChange={() => { setResearched(!researched) }}
                    /> Researched Territory
                </label>
             

                <button disabled={!numberOfSelectedTerritories} style={{marginLeft:"auto",marginRight:"auto", display:"block"}} className="btn btn-primary" onClick={async ()=>{
console.log("yo")

setCheckInDebugState("Button Pressed");

if (window.navigator.onLine == false){
    setCheckInDebugState("Not connected to internet");
    alert("You are not currently connected to the internet.")
    return
    }

let outputTerritories = []

for (let territory of publishersTerritories){
    if (selectedTerritories[territory["id"]]){

        console.log(territory.territoryName + " was selected.");
        outputTerritories.push(territory.id) //({id:territory.id,wasResearched:researched,workedCampaign:campaign});
    }
}

if (outputTerritories.length >= 1){

    setCheckInDebugState("Territories found");

    if (debounce){
        setCheckInDebugState("Debounced");
        return 
    }
    debounce = true;
    setTimeout(function(){debounce = false;},700);

let output = {didCampaign:campaign,didResearch:researched,territoryIDs:outputTerritories};

setCheckInDebugState("Sent Request");
try{
let result = await graphQLClient.mutation(CHECK_IN_TERRITORIES, output
    ).toPromise();

    setCheckInDebugState("Received Response");

console.log("res");
    console.log(result)
    if (result.error){
        setCheckInDebugState("Error in response");
        alert(result.error)
    }   
} catch (e : Error | any){
setCheckInDebugState("Error making request");
setCheckInDebugState("Error making request: " + e?.stack || e?.message || e);

let errmess = {};
try{
  console.log(JSON.stringify(e, Object.getOwnPropertyNames(e)));
  errmess = JSON.parse(JSON.stringify(e, Object.getOwnPropertyNames(e)))
}catch(err){     
  console.log("caught error");
  console.log(err);  
}

let outp = {"Message":e?.message,"Source":e?.source,"LineNumber":e?.lineno,"ColumnNumber":e?.colno,"Error": errmess, "from request send":true}

// Make the call to log the client error.
var xhr = new XMLHttpRequest();
xhr.open('POST', 'https://53sgfpjtke45rq5h24d4zvcpd40ubxfn.lambda-url.us-east-1.on.aws/LogClientError', true);
xhr.setRequestHeader("Content-Type", "application/json");
xhr.send(JSON.stringify(outp));

}

//alert(JSON.stringify(output));

}else{
    setCheckInDebugState("No territories found");
}

                }}>Check In{(() => { if (numberOfSelectedTerritories == 0) return ""; if (numberOfSelectedTerritories == 1) return " 1 Territory"; if (numberOfSelectedTerritories >= 2) return " " + numberOfSelectedTerritories + " Territories"; })()}</button>
                {checkInDebugState && <span><br/>Button Debug State: {checkInDebugState}</span>}

            </div>
        </div>
    )
}