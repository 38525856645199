import React, {useContext} from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col, Form } from 'react-bootstrap';
import {
  marketShare,
 // totalOrder,
  //totalSales,
  //weeklySalesData,
  weather//,
  //product-,
  //storageStatus,
  //files,
  //users,
  //topProducts,
  //runningProjects
} from 'data/dashboard/default';


//
//import TotalOrder from './TotalOrder';
//import MarketShare from './MarketShare';
//import TotalSales from './TotalSales';
//import RunningProjects from './RunningProjects';
//import StorageStatus from './StorageStatus';
//import SpaceWarning from './SpaceWarning';
//import BestSellingProducts from './BestSellingProducts';
//import SharedFiles from './SharedFiles';
//import ActiveUsers from './ActiveUsers';
//import BandwidthSaved from './BandwidthSaved';
//import TopProducts from './TopProducts';
//import Weather from './Weather';

import AuthContext from "newComponents/AuthContext";
import { useQuery,gql,useMutation } from 'urql';

import { useClient } from "urql";
//import {useQuery, gql, useMutation} from '@apollo/client';
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import PageLoadSpinner from 'newComponents/PageLoadSpinner';

const completeExport = gql`
query export{
  completeDataExport
}`

const createTerritory = gql`
mutation qt($ti :TerritoryInput){
  createTerritory(TerritoryInput:$ti){
    id
    territoryName
  }
}
`

const createAddress = gql`
mutation qt($ti :[AddressInput!]!){
  bulkImportAddress(data:$ti)
}
`
    
const UploadTerritoryJSON = () => {
    
var client = useClient();

//var [result,executeMutation] = useMutation(createTerritory)

  //const [mutateFunction, { data1, loading1, error1 }] = useMutation(createTerritory);
  
  //const [mutationResult,callMutation] = useMutation(createTerritory,{});



  async function listOfLists(){

    console.log(document.getElementById("filid"));
  console.log(
  document.getElementById("filid").value);
  
  var file = document.getElementById("filid").files[0];
  if (file) {
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = async function (evt) {
        console.log(evt.target.result);
  
  let terrdata = JSON.parse(evt.target.result);
  
let foundNum = 1;
let ind = -1;

  while (foundNum != 0){

//if (foundNum == 0){
//  break;
//}

ind = ind + 1;

    foundNum = 0;
  let ts = [];

  for (let tn of terrdata){
let t = null;
    if (tn.length >= ind + 1){
      t = tn[ind];
    }else{
      continue;
    }

  //console.log(t);
  //console.log(t["id"])
  
  let time = Date.now()
  
  //console.log("bef... changed");
  
  if ("coordinates" in t){
    t["coordinates"] = JSON.stringify(t["coordinates"])
  }
  
  if ("parcel" in t){
    t["parcel"] = JSON.stringify(t["parcel"])
  }

  if ("roadLine" in t){
    t["roadLine"] = JSON.stringify(t["roadLine"])
  }
  //
  
  foundNum = foundNum + 1;
  
  ts.push(t);
  
  if (ts.length >= 1){
    console.log(""+ind + " " + foundNum + " " + ts[0]["id"]) //+ ts[0]["id"])
    console.log(ts);
  let ack = await client.mutation(createAddress, { ti:ts }).toPromise()
  //await new Promise(resolve => setTimeout(resolve, 3000));

if (ack.error){
  console.log("THERE WAS AN ERROR");
  console.log(ack);
}

  ts = [];
  console.log(ack);
  }
   // .then(result => {
   //   console.log(result); // { data: ... }
  //  });
   // let ack = await callMutation(variables= {
   //   "ti": t
    //});
  
    console.log("time " + Date.now() - time);
  
  
  
  
  //dateLiteratureSent isn't valid..
  
    //break;
  }
  
  if (ts.length >= 1){
    console.log(""+ind + " " + foundNum + " " + ts[0]["id"]) //+ ts[0]["id"]) //console.log(ts[0]["id"])
    let ack = await client.mutation(createAddress, { ti:ts }).toPromise()
    await new Promise(resolve => setTimeout(resolve, 3000));
    ts = [];
    console.log(ack);
    }


  }
  
  
          //document.getElementById("fileContents").innerHTML = evt.target.result;
      }
      reader.onerror = function (evt) {
          document.getElementById("fileContents").innerHTML = "error reading file";
      }
  }
  }




async function sub(){

  console.log(document.getElementById("filid"));
console.log(
document.getElementById("filid").value);

var file = document.getElementById("filid").files[0];
if (file) {
    var reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = async function (evt) {
      console.log(evt.target.result);

let terrdata = JSON.parse(evt.target.result);


let ts = [];

for (let t of terrdata){
console.log(t);
console.log(t["id"])

let time = Date.now()

console.log("bef... changed");
/*
if ("coordinates" in t){
  t["coordinates"] = JSON.stringify(t["coordinates"])
}

if ("parcel" in t){
  t["parcel"] = JSON.stringify(t["parcel"])
}*/
//


ts.push(t);

if (ts.length >= 100){
  console.log(ts[0]["id"])
let ack = await client.mutation(createAddress, { ti:ts }).toPromise()
await new Promise(resolve => setTimeout(resolve, 3000));
ts = [];
console.log(ack);
}
 // .then(result => {
 //   console.log(result); // { data: ... }
//  });
 // let ack = await callMutation(variables= {
 //   "ti": t
  //});

  console.log("time " + Date.now() - time);




//dateLiteratureSent isn't valid..

  //break;
}

if (ts.length >= 1){
  console.log(ts[0]["id"])
  let ack = await client.mutation(createAddress, { ti:ts }).toPromise()
  ts = [];
  console.log(ack);

  await new Promise(resolve => setTimeout(resolve, 3000));

  }


        //document.getElementById("fileContents").innerHTML = evt.target.result;
    }
    reader.onerror = function (evt) {
        document.getElementById("fileContents").innerHTML = "error reading file";
    }
}
}



return (
<div className="card">
      <BreadCrumbHeader data={{routes:["Upload Territory JSON"]}}/> 

<div className="card-body" style={{minHeight:180}}>

  <Form.Group controlId="formFile" className="mb-3">
  <Form.Label>Upload JSON of address data</Form.Label>
  <Form.Control type="file" id="filid"/>
</Form.Group>


<button className="btn btn-primary" onClick={sub}>Submit List</button><br/><br/>
<button className="btn btn-primary" onClick={listOfLists}>Submit List of Lists</button><br/><br/>


<button className = "btn btn-primary" onClick={()=>{
  throw new Error("This is a test error");
}}>Throw Error</button><br/><br/>

<button className="btn btn-primary" onClick={async function(){
 let ack = await client.query (completeExport, {},{requestPolicy:"network-only"}).toPromise()

window.location.href = ack.data.completeDataExport;

}}>Do Complete Export</button>




<br/><br/>
<a href="https://reactstatictest.s3.amazonaws.com/export.zip">Download Last Export</a>
</div></div>
)


};

export default UploadTerritoryJSON;
