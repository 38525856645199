import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">

<div style={{textAlign:"center",display:"none"}}>
  <span><Link to="/">Terms of Service</Link> | <Link to="/">Privacy Policy</Link> | TerritoryDB 2022 ©</span>
  
   </div>

    {/*<Row className="justify-content-between text-center fs--1 mt-4 mb-3">
      <Col sm="auto">
        <p className="mb-0 text-600">
          Thank you for using Territory Toolkit{' '}
          <span className="d-none d-sm-inline-block">| </span>
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
          <a href="https://themewagon.com">TerritoryDB</a>
        </p>
      </Col>
      <Col sm="auto">
        <p className="mb-0 text-600">v{version}</p>
      </Col>
    </Row>*/}
  </footer>
);

export default Footer;
