//leaflet-control-layers-list paddingTop paddingBottom : 5

//CONSIDER: Hold down to search for a lot... Is that possible?

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col, Form, Table, Modal, Button } from 'react-bootstrap';

import { TileLayer, MapContainer, useMap, Marker, Popup, LayersControl, LayerGroup, LayersControl as BaseLayer, useMapEvent, Rectangle } from 'react-leaflet';

//var segmentInput = import("newComponents/Segments.json");

//import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet.fullscreen/Control.FullScreen.js";

import AuthContext from "newComponents/AuthContext";

//import {useQuery, gql, useMutation} from '@apollo/client';

import { useQuery, gql, useMutation, useClient } from 'urql';

import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import PageLoadSpinner from 'newComponents/PageLoadSpinner';
import { useParams } from 'react-router-dom';

import "leaflet-lasso";

import { UPDATE_ADDRESS_CACHE } from "models/Address";

import { featureGroup, geoJSON, DivIcon, polygon, layerGroup, tileLayer, control, marker, circle, popup as newpopup, Control } from 'leaflet';
import { map as leafletmap } from "leaflet";

import * as turf from '@turf/turf'

import { lineString, lineOffset, cleanCoords, multiLineString, center, pointOnFeature, nearestPointOnLine, nearestPointToLine, bbox, points } from "@turf/turf"

import 'leaflet/dist/leaflet.css';

//import { lasso as newlasso } from 'leaflet';

import { lasso as newlasso } from 'leaflet';
//import DoorToDoorAddressListItem from './DoorToDoorAddressListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { object } from "is_js";

import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import { useEventHandlers } from "@react-leaflet/core";

//import Select from 'react-select';

import Select from "react-select";


//import CreatableSelect from 'react-select/creatable';
import { BsTruckFlatbed } from "react-icons/bs";
import { GET_ADDRESS_TERRITORY_CACHES } from "models/Territory";

var allSegmentGroups = {};
var allAddresses = [];
var allTerritoryNames = { "1_ter_0": "New Territory" };
var allTerritories = [];
var allTerritoryColors = { "1_ter_0": "#0000ff" };
var selectStreet;
var lastSelectedSegment;

//import Range from "components/doc-components/Range";
//const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);

const reactSelectCustomSearch = (candidate, input) => {
  //candidate = { label: candidate.label.toLowerCase().replaceAll("'",""),, value: candidate.value.toLowerCase() };
  let lab = candidate.label.toLowerCase().replaceAll("'", "").replaceAll("#", "")
  //console.log("custom search");
  //console.log(candidate);
  //console.log(input);
  let inp = input.toLowerCase().replaceAll("'", "").replaceAll("#", "").split(" ");
  for (let i = 0; i < inp.length; i++) {
    if (!lab.includes(inp[i])) {
      return false
    }
  }
  return true;
};

const MUTATION_REQUEST = gql`
mutation updateTerritoryIDs($inp:[AddressInput!]!){
  changeAddressTerritoryID(data:$inp){
    id
    moveToTerritoryID
    territoryID
  }
}
`

const GET_ADDRESS_BY_COORDINATES = gql`
query getHouseByCoordinates($longitude:Float!,$latitude:Float!){
  getNearestAddress(longitude:$longitude,latitude:$latitude){
    id
    address
    parcel{
      type
      coordinates
    }
    coordinates{
      type
      coordinates
    }
    territoryName
    territoryid
    territory{
    assignedToid
    assignedToName
    }
  }
}`

const GET_TERRITORY_BY_ID = gql`
query getterr($id:ID!){

getTerritory(id:$id){
  id
  congregationid
  territoryName
  gatedCommunity

  workHistory{
    publisherid
    publisherName
    checkedOut
    checkedIn
    campaign
    researched
  }

  addresses{
    id
    congregationid
    address
    territoryid
    territoryName
    addressNumber
    gatedCommunity
    doorToDoor
    streetName
    notes
    coordinates{
      type
      coordinates
    }
    parcel{
      type
      coordinates
    }
     houseHolders{
      firstName
      lastName
      age
      religion
      phone
      fullName
     }
    postalCode
    city
    county
    country
    customid1
    updaterid
    updaterName
    createdAt
    updatedAt
    version
  }

  assignedToid
  assignedToName

  checkedOutAt

  lastWorked
  lastResearched
  
  postalCode
  city
  county
  country
  customid1

  updaterid
  updaterName
  createdAt
  updatedAt
  version
}
}
`
var addressPolies = {}

/*const GET_ALL_TERRITORIES = gql`
query getTerrNames{
  getAllTerritories{
    id
    territoryName
  }

  getAllAddresses{
    id
    address
    addressNumber
    territoryID
    territoryName
    streetName
    coordinates
    parcel
    roadLine
    moveToTerritoryID
    doorToDoor
    gatedCommunity
    neighborhoodName
  }
}
`*/


function getTerritoryNameFromID(id) {

  return allTerritoryNames[id]
  /*
    if ("id" == "1_ter_0"){
      return "New Territory";
    }
  
    for (let t of allTerritories){
      if (t["id"] == id){
        return t.territoryName;
      }
    }*/
}

function SingleSelectExample() {
  const [value, setValue] = useState(null);

  const [query, reexecuteQuery] = useQuery({
    query: GET_ADDRESS_TERRITORY_CACHES,
    variables: {
      congregationID: "1"
    },
    requestPolicy: "cache-and-network", //"cache-and-network"
  });

  const graphQLClient = useClient();

  useEffect(async () => {

    let addrCache = window.localStorage.getItem("addressCacheTime");
    if (addrCache == null) {
      addrCache = new Date().getTime();
    }

    let newTime = new Date().getTime();

    //    let results = await graphQLClient.mutation(UPDATE_ADDRESS_CACHE, { congregationID: "1", lastUpdated: addrCache.toString() }).toPromise()

    console.log("UPDATE_ADDRESS_CACHE");
    console.log(addrCache);
    console.log(new Date(parseFloat(addrCache)));
    //console.log(results);

    window.localStorage.setItem("addressCacheTime", newTime);

  }, [])

  const [options, setOptions] = useState([
    /*{ value: '1_terr_1', label: 'Tomoka Farms #1' },
    { value: '1_terr_2', label: 'Tomoka Farms #2' },
    { value: '1_terr_3', label: 'Tomoka Farms #3' },
    { value: '1_terr_4', label: 'Tomoka Farms #4' },
    { value: '1_terr_5', label: 'Tomoka Farms #5' },
    { value: '3', label: 'Browns Landing #1' },
    { value: '4', label: 'Browns Landing #2' }*/
  ]);

  if (query.data && options.length == 0) {
    console.log("SETTING OPTIONS");
    console.log(query);
    setOptions(query.data.getTerritoryCache.map(item => { return { value: item.id, label: item.territoryName } } ).sort(function(a,b){ if (a.label.localeCompare(b.label,undefined,{numeric:true}) < 0 ) return -1; return 1;  }) );
    let colorList = [
      "#ff0000",
      "#00ff00",
      "#0000ff",
      "#ffff00",
      "#ff00ff",
      "#00ffff",
      "#ffd000",
    ]
    
    /*['#e6194b', 
    '#3cb44b', 
    '#ffe119', 
    '#000000', 
    '#4363d8', 
    '#f58231', 
    '#911eb4', 
    '#46f0f0', 
    '#000075', 
    '#f032e6', 
    '#bcf60c', 
    '#fabebe', 
    '#008080', 
    '#e6beff',  
    //'#fffac8',
      '#800000', 
      '#aaffc3', 
      //'#9a6324', 
      '#808000', 
      //'#ffd8b1',
      '#808080' 
      //'#ffffff', 
    ]*/
    let colorNum = 0;
    for (let terr of query.data.getTerritoryCache) {

      allTerritoryColors[terr["id"]] = colorList[colorNum];
      colorNum = colorNum + 1;
      if (colorNum == colorList.length) {
        colorNum = 0;
      }

      allTerritoryNames[terr["id"]] = terr["territoryName"];

      /*allTerritoryColors[terr["id"]] = "#" + Math.floor(Math.random() * 16777215
      ).toString(16)

      if (allTerritoryColors[terr["id"]].length < 7 ){
        allTerritoryColors[terr["id"]] = "#" + Math.floor(Math.random() * 16777215
        ).toString(16)
        if (allTerritoryColors[terr["id"]].length < 7 ){
          allTerritoryColors[terr["id"]] = "#" + Math.floor(Math.random() * 16777215
          ).toString(16)
        }
        if (allTerritoryColors[terr["id"]].length < 7 ){
          allTerritoryColors[terr["id"]] = "#" + Math.floor(Math.random() * 16777215
          ).toString(16)
        }
      }*/

    }

    console.log(query.data.getAddressCache);
    allAddresses = query.data.getAddressCache
    allAddresses = allAddresses.filter((addr) => { return (addr.doorToDoor == true) });
    allTerritories = query.data.getTerritoryCache;

    console.log("allAddresses");
    console.log(allAddresses);

    let drawnSegments = new Set();
    for (let addr of allAddresses) {
      //console.log("itting addr");
      //console.log(addr);
      if (addr.roadLine && !drawnSegments.has(addr.roadLine.properties.segment)) {
        drawnSegments.add(addr.roadLine.properties.segment);
        drawSegment({ map, segment: addr.roadLine.properties.segment, addresses: allAddresses });



      }
    }
  }


  /* useEffect(function(){
   useClient().query(GET_ALL_TERRITORIES).toPromise().then(function(value){
     console.log("AFTER USECLIENT QUERY")
     console.log(value);
   })
 })*/


  const handleChange = useCallback((inputValue) => {
    map.dragging.enable();
    map.touchZoom.enable();
    map.doubleClickZoom.enable();
    map.scrollWheelZoom.enable();
    map.boxZoom.enable();
    map.keyboard.enable();
    if (map.tap) map.tap.enable();

    selectStreet({ assignToTerritoryID: inputValue.value });

    return setValue(inputValue), [];
  });

  const handleCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setValue(newValue);
    },
    [options]
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //border: '1px solid black',
      color: "rgba(255,0,0,0) !important !important",

      backgroundColor: state.isSelected ? "rgba(189,197,209,.2)" : state.isFocused ? "rgba(189,197,209,.1)" : "white",


      ":active": { backgroundColor: "rgba(189,197,209,.3)" },
      //   ":selected":{backgroundColor:"rgba(189,197,209,.3)"},
      // ":focus":{backgroundColor:"rgba(189,197,209,.1)"},
      //isSelected:{
      //  backgroundColor:"red !important"
      //}
      //  borderBottom: '1px dotted pink',
      //color: state.isSelected ? 'red' : 'blue',
    }),

    singleValue: (provided, state) => ({//Selected value, not placeholder
      ...provided,
      color: "rgb(52,64,80) !important",
      fontFamily: "var(--falcon-body-font-family) !important"
      //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),

    placeholder: (provided, state) => ({//Selected value, not placeholder
      ...provided,
      color: "rgba(52,64,80,.6) !important",
      fontFamily: "var(--falcon-body-font-family) !important"
      //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),



    control: (provided, state) => ({
      ...provided,
      borderColor: "rgba(80,80,80,.3) !important",
      borderWidth: "1px !important",
      borderRadius: "4px !important"
    }),

    //control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    //}),
    //singleValue: (provided, state) => {
    //  const opacity = state.isDisabled ? 0.5 : 1;
    //  const transition = 'opacity 300ms';
    // return { ...provided, opacity, transition };
    //}
  }

  //menuIsOpen={true}
  return (
    <div style={{ width: "100%" }}>
      <Select
        closeMenuOnSelect={true}
        options={options}
        styles={customStyles}
        //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
        isSearchable={true}
        onCreateOption={handleCreate}
        placeholder='Select Territory...'
        classNamePrefix="react-select2"
        value={value}
        filterOption={reactSelectCustomSearch}
        onChange={handleChange}
        menuPlacement="top"
        width="100%"
      />
    </div>
  )

}



/*
const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

const BOUNDS_STYLE = { weight: 1 }

function MinimapBounds({ parentMap, zoom }) {
  const minimap = useMap()

  // Clicking a point on the minimap sets the parent's map center

  const onClick = useCallback(
    (e) => {
      parentMap.setView(e.latlng, parentMap.getZoom())
    },
    [parentMap],
  )
  useMapEvent('click', onClick)

  // Keep track of bounds in state to trigger renders
  const [bounds, setBounds] = useState(parentMap.getBounds())
  const onChange = useCallback(() => {
    setBounds(parentMap.getBounds())
    // Update the minimap's view to match the parent map's center and zoom
    minimap.setView(parentMap.getCenter(), zoom)
  }, [minimap, parentMap, zoom])

  // Listen to events on the parent map
  const handlers = useMemo(() => ({ move: onChange, zoom: onChange }), [])
  useEventHandlers({ instance: parentMap }, handlers)

  return <Rectangle bounds={bounds} pathOptions={BOUNDS_STYLE} />
}

function MinimapControl({ position, zoom }) {
  const parentMap = useMap()
  const mapZoom = 9 //zoom || 0

  // Memoize the minimap so it's not affected by position changes
  const minimap = useMemo(
    () => (
      <MapContainer
        style={{ height: 80, width: 80 }}
        center={parentMap.getCenter()}
        zoom={mapZoom}
        dragging={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        attributionControl={false}
        zoomControl={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MinimapBounds parentMap={parentMap} zoom={mapZoom} />
      </MapContainer>
    ),
    [],
  )

  const positionClass =
    (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright
  return (
    <div className={positionClass}>
      <div className="leaflet-control leaflet-bar">{minimap}</div>
    </div>
  )
}
*/

var map;

var territoryControlGeometryGroup;

function TerritoryControl() {
  map = useMap()

  const [territoryControlState, setTerritoryControlState] = useState({ leftEnabled: true, rightEnabled: true, segmentData: null, assignToTerritoryID: null, streetName: "No street selected.", territoryName: "", addresses: [], current: [0, 0], max: 0 , selectedAddressID:null});

  selectStreet = function (input) {
    setTerritoryControlState({ ...territoryControlState, ...input })
  }

  let stateAddresses = [] //territoryControlState.addresses;

  if (territoryControlState.leftEnabled && territoryControlState.rightEnabled) {
    stateAddresses = territoryControlState.addresses;
  } else {
    for (let addr of territoryControlState.addresses) {
      if ("roadLine" in addr) {
        let side = addr["roadLine"]["properties"]["side"];
        if (territoryControlState.leftEnabled && side == "left") {
          stateAddresses.push(addr);
        } else if (territoryControlState.rightEnabled && side == "right") {
          stateAddresses.push(addr);
        }
      }

    }
  }


if (territoryControlState.selectedAddressID){

  for (let i = 0; i < stateAddresses.length; i++) {
    let adr = stateAddresses[i];
    if (adr.id == territoryControlState.selectedAddressID){
      selectStreet({selectedAddressID:null,current:[i,i]})
    }
  }
}


  //segmentState {segmentData,streetName, addresses, current, max}

  let minLabel = stateAddresses.length >= 1 ? stateAddresses[0].addressNumber : "";
  let maxLabel = stateAddresses.length >= 1 ? stateAddresses[stateAddresses.length - 1].addressNumber : "";
  let maxnum = stateAddresses.length - 1;
  if (maxnum == -1) {
    maxnum = 0;
  }


  if (territoryControlState.current[0] > maxnum || territoryControlState.current[1] > maxnum) {
    setTerritoryControlState({ ...territoryControlState, current: [0, maxnum] });
    territoryControlState.current = [0, maxnum];
  }

  let marks = { 0: { label: minLabel } }//,maxnum.toString():{label:maxLabel}}



  if (territoryControlState.current[1] != maxnum) {
    marks[territoryControlState.current[1]] = { label: stateAddresses[territoryControlState.current[1]].addressNumber + "(" + (territoryControlState.current[1] - territoryControlState.current[0] + 1) + ")" }
    marks[maxnum] = { label: maxLabel, style: { opacity: .5 } }
  }
  else if (territoryControlState.segmentNumber == null || stateAddresses.length == 0) {
    marks[maxnum] = { label: "" }
  }
  else {
    marks[maxnum] = { label: maxLabel + "(" + (territoryControlState.current[1] - territoryControlState.current[0] + 1) + ")" }
  }

  if (territoryControlState.current[0] != 0) {
    marks[territoryControlState.current[0]] = { label: stateAddresses[territoryControlState.current[0]].addressNumber }
    marks[0] = { label: minLabel, style: { opacity: .5 } }
  } else if (territoryControlState.segmentNumber == null || stateAddresses.length == 0) {
    marks[0] = { label: "" }
  } else {
    marks[0] = { label: minLabel }
  }

  if ((territoryControlState.segmentNumber != null && stateAddresses.length != 0) &&
    territoryControlState.current[0] == territoryControlState.current[1]) {
    marks[territoryControlState.current[0]] = { label: stateAddresses[territoryControlState.current[0]].addressNumber + " (" + (territoryControlState.current[1] - territoryControlState.current[0] + 1) + ")" }
  }


  let segmentAddressList = []

  let outAddrs = []

  for (let index = 0; index < territoryControlState.addresses.length; index++) {
    let address = territoryControlState.addresses[index];
    outAddrs.push({ ...address });
  }

  //for (let [index, address] of stateAddresses.entries()) {
  for (let index = 0; index < stateAddresses.length; index++) {
    let address = stateAddresses[index];

    let isSelected = false;

    let sty = { //Normal polygon styling
      stroke: true, //enable border
      color: "#0000ff", //border color, hexadecimal
      weight: 2, //border width
      opacity: .2, //border opacity
      lineCap: "round", //how to style end of a line
      lineJoin: "round", //Makes line joints rounded
      fill: true, //To fill polygon with color or not
      fillColor: "#ff0000",
      fillOpacity: .01,
      fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
    }

    if (index >= territoryControlState.current[0] && index <= territoryControlState.current[1]) {
      isSelected = true;
      sty = { //Normal polygon styling
        stroke: true, //enable border
        color: "#0000ff", //border color, hexadecimal
        weight: 2, //border width
        opacity: 1, //border opacity
        lineCap: "round", //how to style end of a line
        lineJoin: "round", //Makes line joints rounded
        fill: true, //To fill polygon with color or not
        fillColor: "#0000ff",
        fillOpacity: .035,
        fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
      }

      let tid = "1_ter_0";
      if (territoryControlState.assignToTerritoryID) {
        tid = territoryControlState.assignToTerritoryID;
      }

      //outAddrs.push({ ...address, selectedTerritoryID: tid });

      for (let a of outAddrs) {
        if (a["id"] == address["id"]) {
          a["selectedTerritoryID"] = tid;
          break
        }
      }

    }

    let addressPoly;

    if (address["parcel"]) {
      addressPoly = geoJSON(address["parcel"], { style: sty }).bindPopup("<b>" + address["address"] + "</b><i style='display:none'>~"+address.id+"~</i>");
    } else if (isSelected) {
      addressPoly = geoJSON(address["coordinates"], { style: sty }).bindPopup("<b>" + address["address"] + "</b><i style='display:none'>~"+address.id+"~</i>");
    }
    if (addressPoly) {
      if (!isTouchDevice()) {
        addressPoly.bindTooltip(address["address"], { permanent: false, direction: "center", opacity: 1 })
      }
      segmentAddressList.push(addressPoly)
    }
  }

  if (territoryControlGeometryGroup) {
    map.removeLayer(territoryControlGeometryGroup);
  }
  territoryControlGeometryGroup = featureGroup(segmentAddressList).addTo(map);

  if (lastSelectedSegment && lastSelectedSegment != territoryControlState.segmentNumber) {
    drawSegment({ map, segment: lastSelectedSegment, addresses: allAddresses });
  }
  lastSelectedSegment = territoryControlState.segmentNumber

  if (outAddrs.length > 0) {
    drawSegment({
      map, segment: territoryControlState.segmentNumber, addresses: outAddrs //territoryControlState.addresses 
    });
  }

  if (territoryControlState.segmentNumber == null || stateAddresses.length == 0) {
    marks = { 0: { label: minLabel } }
  }

  return (
    <div className="leaflet-bottom leaflet-left">
      <div className="leaflet-control leaflet-bar" style={{ backgroundColor: "white", paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, marginLeft: 5, marginBottom: 5, width: 395 }}
        onMouseOver={() => {
          //    console.log("mouseOver");
          map.dragging.disable();
          map.touchZoom.disable();
          map.doubleClickZoom.disable();
          map.scrollWheelZoom.disable();
          map.boxZoom.disable();
          map.keyboard.disable();
          if (map.tap) map.tap.disable();
          //document.getElementById('map').style.cursor='default';
          //  map.dragging.disable()
        }}
        onMouseOut={() => {
          //console.log("mouseOut");
          map.dragging.enable();
          map.touchZoom.enable();
          map.doubleClickZoom.enable();
          map.scrollWheelZoom.enable();
          map.boxZoom.enable();
          map.keyboard.enable();
          if (map.tap) map.tap.enable();
          //document.getElementById('map').style.cursor='grab';
          //   map.dragging.enable()
        }}
      >
        <div style={{ marginBottom: 5, float: "right" }}>
          <button style={{ backgroundColor: "rgba(44, 123, 229," + (() => { if (territoryControlState.leftEnabled) return "1"; return ".75" })() + ")" }} className="btn btn-primary btn-sm"
            onClick={function () {

              let leftEnabled = !territoryControlState.leftEnabled
              let rightEnabled = territoryControlState.rightEnabled

              let selAddresses = []

              if (leftEnabled && rightEnabled) {
                selAddresses = territoryControlState.addresses;
              } else {
                for (let addr of territoryControlState.addresses) {
                  if ("roadLine" in addr) {
                    let side = addr["roadLine"]["properties"]["side"];
                    if (leftEnabled && side == "left") {
                      selAddresses.push(addr);
                    } else if (rightEnabled && side == "right") {
                      selAddresses.push(addr);
                    }
                  }

                }
              }

              let mn = selAddresses.length;
              if (mn < 0) {
                mn = 0
              }

              setTerritoryControlState({ ...territoryControlState, current: [0, mn], leftEnabled, rightEnabled })
            }}


          >L</button>

          <button style={{ marginLeft: 3, backgroundColor: "rgba(44, 123, 229," + (() => { if (territoryControlState.rightEnabled) return "1"; return ".75" })() + ")" }} className="btn btn-primary btn-sm"
            onClick={function () {

              let leftEnabled = territoryControlState.leftEnabled
              let rightEnabled = !territoryControlState.rightEnabled

              let selAddresses = []

              if (leftEnabled && rightEnabled) {
                selAddresses = territoryControlState.addresses;
              } else {
                for (let addr of territoryControlState.addresses) {
                  if ("roadLine" in addr) {
                    let side = addr["roadLine"]["properties"]["side"];
                    if (leftEnabled && side == "left") {
                      selAddresses.push(addr);
                    } else if (rightEnabled && side == "right") {
                      selAddresses.push(addr);
                    }
                  }

                }
              }

              let mn = selAddresses.length;
              if (mn < 0) {
                mn = 0
              }

              setTerritoryControlState({ ...territoryControlState, current: [0, mn], leftEnabled, rightEnabled })
            }}
          >R</button>
        </div>

        <h5 style={{ textAlign: "center" }}>{territoryControlState.streetName}</h5>
        <h6 style={{ textAlign: "center", display:"none" }}>{territoryControlState.territoryName}</h6>
        <div style={{ marginLeft: 15, marginRight: 15, marginBottom: 25, marginTop:15 }}>
          <Slider disableSwap range tipFormatter={(value) => `${ value }!`} tipProps={{
            placement: 'top', prefixCls: 'rc-slider-tooltip',
            overlay: (value) => `${ value }!`,
          }}
            onChange={(value) => {
              setTerritoryControlState({ ...territoryControlState, current: value });
            }}
            min={0} max={maxnum} value={territoryControlState.current} draggableTrack={true} step={1} pushable={0} dots={false} marks={marks} />
        </div>




        <div style={{ display: "flex", width: "100%" }}>
          <button

            disabled={territoryControlState.assignToTerritoryID == null}
            style={{ marginBottom: 1, marginTop: 1 }} className="btn btn-sm btn-primary" onClick={async () => {

              let addrsToUpdate = []

              let curSegment = null

              //for (let [index, address] of stateAddresses){//.entries()) {
              for (let index = 0; index < stateAddresses.length; index++) {
                let address = stateAddresses[index];

                if (index >= territoryControlState.current[0] && index <= territoryControlState.current[1]) {
                  curSegment = address.roadLine.properties.segment;
                  //selected
                  //outAddrs.push({ ...address, selectedTerritoryID: "1_ter_0" });


                  if (address.moveToTerritoryID != territoryControlState.assignToTerritoryID && (address.territoryID != territoryControlState.assignToTerritoryID || address.moveToTerritoryID)) {
                    address.moveToTerritoryID = territoryControlState.assignToTerritoryID;
                    addrsToUpdate.push({ id: address.id, moveToTerritoryID: territoryControlState.assignToTerritoryID });
                  }

                  //({ segmentData: null, assignToTerritoryID:null, streetName: "No street selected.", territoryName: "", addresses: [], current: [0, 0], max: 0 });

                }
              }


              if (addrsToUpdate.length > 0) {
                selectStreet({ streetName: "Addresses were assigned.", territoryName: "", addresses: [], current: [0, 0], max: 0, segmentNumber: null, leftEnabled: true, rightEnabled: true });


                let ack = [...stateAddresses];
                setTimeout(drawSegment({map,addresses:ack,segment:curSegment}),150);

                let result2 = await graphqlClient.mutation(MUTATION_REQUEST, { inp: addrsToUpdate }
                ).toPromise();

                if (result2.error) {
                  alert(result2.error.message)
                };

                console.log("RESULT2")
                console.log(result2);
                if (result2.data && result2.data.changeAddressTerritoryID) {
                  console.log("GOT RESULT");
                  console.log(result2.data.changeAddressTerritoryID)
                  console.log(stateAddresses)

                  for (let adr of result2.data.changeAddressTerritoryID) {
                    for (let index = 0; index < stateAddresses.length; index++) {
                      let address = stateAddresses[index];

                      if (address.id == adr.id) {
                        console.log("FOUND AN ADDRESS THAT WAS MUTATED AND UPDATING ITS TERRITORYID");
                        address.moveToTerritoryID = adr.moveToTerritoryID;
                      }

                    }
                  }
                  console.log("REDRAWING");
                  drawSegment({map,segment:curSegment,addresses:allAddresses })
                }


              }

            }}>Assign</button>
          <div style={{ display: "flex", marginLeft: 10, width: "100%" }}>
            <SingleSelectExample ></SingleSelectExample>
          </div>
          {/*<input style={{display:"inline-block",width:"unset",marginLeft:15}} className="form-control" value="Tomoka Farms Rd #1"></input>
*/}

        </div>

        {/*    <button style={{marginLeft:"auto",marginRight:"auto",marginTop:20,display:"inline-block"}} className="btn btn-sm btn-primary">Add</button>
*/}
      </div>
    </div>
  )
}


//
/*
handleRender={renderProps => {
                        return (
                            <div {...renderProps.props}>
                                Sup
                            </div>
                        );
                    }}
*/

var lineStringStyling = { //Normal polygon styling
  stroke: true, //enable border
  color: "#0000ff", //border color, hexadecimal
  weight: 5, //border width
  opacity: .3, //border opacity
  lineCap: "butt", //"round", //how to style end of a line
  lineJoin: "round", //Makes line joints rounded
  fill: false, //To fill polygon with color or not
  //fillColor: "#0000ff",
  //fillOpacity: .0,//.075
  //fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
}

var polygonStyling = { //Normal polygon styling
  stroke: true, //enable border
  color: "#0000ff", //border color, hexadecimal
  weight: 5, //border width
  opacity: .3, //border opacity
  lineCap: "butt", //"round", //how to style end of a line
  lineJoin: "round", //Makes line joints rounded
  fill: true, //To fill polygon with color or not
  fillColor: "#0000ff",
  fillOpacity: .0,//.075
  fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
}

var dncPolygonStyling = { ...polygonStyling, color: "#ff0000" } //Styling for polygons that are do not call

function isTouchDevice() {
  return false
  return (('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0));
}




function getPointByDistance(pnts, distance) {
  let tl = totalLen(pnts);
  let cl = 0;
  let ol;
  let result;
  pnts.forEach(function (point, i, points) {
    ol = cl;
    cl += i ? lineLen([points[i - 1], point]) : 0;
    if (distance <= cl && distance > ol) {
      let dd = distance - ol;
      result = pntOnLine([points[i - 1], point], dd);
    }
  });
  return result
};
// returns a point on a single line (two points) using distance // line=[[x0,y0],[x1,y1]]
function pntOnLine(line, distance) {
  let t = distance / lineLen(line)
  let xt = (1 - t) * line[0][0] + (t * line[1][0])
  let yt = (1 - t) * line[0][1] + (t * line[1][1])
  return [xt, yt]
};
// returns the total length of a linestring (multiple points) // pnts=[[x0,y0],[x1,y1],[x2,y2],...]
function totalLen(pnts) {
  let tl = 0;
  pnts.forEach(function (point, i, points) {
    tl += i ? lineLen([points[i - 1], point]) : 0;
  });
  return tl;
};
// returns the length of a line (two points) // line=[[x0,y0],[x1,y1]]
function lineLen(line) {
  let xd = line[0][0] - line[1][0];
  let yd = line[0][1] - line[1][1];
  return Math.sqrt(xd * xd + yd * yd);
};

var graphqlClient;

var mapchildcalled = "";
const MapChild = React.memo((params) => {
  map = useMap();
  graphqlClient = useClient();

  if (map == mapchildcalled) {//mapchildcalled == true){
    console.log("MAP ALREADY RAN");
    return (<></>)
  }

  mapchildcalled = map;
  console.log("MAP RAN")

  map.on('click', function (event) {
    console.log("clk")
    console.log(event.latlng);

  });


  control.fullscreen({
    position: 'topleft', // change the position of the button can be topleft, topright, bottomright or bottomleft, default topleft
    title: 'Show me the fullscreen !', // change the title of the button, default Full Screen
    titleCancel: 'Exit fullscreen mode', // change the title of the button when fullscreen is on, default Exit Full Screen
    content: null, // change the content of the button, can be HTML, default null
    forceSeparateButton: true, // force separate button to detach from zoom buttons, default false
    forcePseudoFullscreen: true, // force use of pseudo full screen even if full screen API is available, default false
    fullscreenElement: false // Dom element to render in full screen, false by default, fallback to map._container
  }).addTo(map);

  map.on("popupopen", function (e) {
    console.log("popupopen");
    console.log(e);
    e.popup._closeButton.removeAttribute("href");
    e.popup._closeButton.style.cursor = "pointer";

console.log(e.popup._content)


let addresssplit = e.popup._content.split("~");

if (addresssplit.length == 3){
  let addressID = addresssplit[1];
  console.log(addressID);
  selectStreet({selectedAddressID:addressID})
}

  })



  var baseMaps = {
    "Light": layerGroup([tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }), tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    })]),
    /* "Light": tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', //'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
         {
             attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
             subdomains: 'abcd',
             maxZoom: 19
         }),*/
    "OpenStreetMap": tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap'
    }),
    "CartoDB Voyager": tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }),
    "Satellite": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    }),
    "Topo": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community'
    }),
    "World Streep Map": tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
    }),
    "Toner": tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
      attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      ext: 'png'
    }),
    "CyclOSM": tileLayer('https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png', {
      maxZoom: 20,
      attribution: '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }),
    "Open Topo Map": tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 17,
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    }),
    "OPNV Karte": tileLayer('https://tileserver.memomaps.de/tilegen/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Map <a href="https://memomaps.de/">memomaps.de</a> <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    })
  }

  let territoryPoly = geoJSON(CongregationPoly, {
    style: {
      stroke: true, //enable border
      color: "#000000", //border color, hexadecimal
      dashArray: '10, 20',
      dashOffset: '0',
      weight: 2, //border width
      opacity: .5, //border opacity
      lineCap: "round", //how to style end of a line
      lineJoin: "round", //Makes line joints rounded
      fill: true, //To fill polygon with color or not
      fillColor: "#000000",
      fillOpacity: .0,
      interactive: false,
      fillRule: "nonzero" //Prevents fills from being empty on the inside in case of complicated overlapping geometry
    }
  })


  var congregationLayer = layerGroup([
    territoryPoly,
    marker([29.055232, -81.069274], {
      icon: new DivIcon({
        className: 'kingdom-hall-div-icon',
        interactive: false,
        html: '<b class="my-div-span" style="color:black;font-size:14px;white-space:nowrap;display:inline-block;transform:translate(-50%,-50%)">Kingdom Hall</b>'
      })
    }),



    marker(territoryPoly.getBounds().getCenter(), {
      icon: new DivIcon({
        className: 'kingdom-hall-div-icon',

        interactive: false,
        html: '<b class="my-div-span" style="color:black;font-size:16px;white-space:nowrap;display:inline-block;transform:translate(-50%,-50%)">West Spruce Creek Territory</b>'
      })
    })
  ]).addTo(map);

  //{ "Congregation's Territory": congregationLayer }
  var layerControl = control.layers(baseMaps, { "Congregation's Territory": congregationLayer }).addTo(map);

  let currentMapLayer = localStorage.getItem("CurrentMapLayer");
  map.on('baselayerchange', function (e) {
    currentMapLayer = e.name
    localStorage.setItem("CurrentMapLayer", e.name)
  });
  if (currentMapLayer != null && currentMapLayer in baseMaps) {
    baseMaps[currentMapLayer].addTo(map);
  }
  else {
    baseMaps["Light"].addTo(map);
  }




  ////////////////
  /*
    let segmentPolyList = []
  
    console.log("BEF SEG INP")
    console.log(segmentInput)
  
    segmentInput.then(seginp => {
      console.log("LOaded");
      console.log(seginp)
      console.log(typeof seginp);
  
      console.log(Object.keys(seginp));
  
  
      for (let streetName of Object.keys(seginp)) { //( [streetName, segmentParent] of Object.entries( seginp )) {
        console.log("itting seg par")
  
        if (streetName != "default") {
          let segmentParent = seginp[streetName];
          for (let segment of segmentParent) {
            console.log("itting segm")
            //let segmentPoly = geoJSON(segment.outline, { style: {...polygonStyling,color:"#"+Math.floor(Math.random()*16777215).toString(16)} }).bindPopup("<b>" + streetName + "</b> " + segment["lowestNumber"] + " - " + segment["highestNumber"]);
            let randcolor = "#" + Math.floor(Math.random() * 16777215).toString(16)
  
            let segmentPoly = geoJSON(segment.geometry, { style: { ...polygonStyling, color: randcolor } }).on('click', function (e) {
  
              selectStreet({ segmentData: segment, streetName, addresses: segment["addresses"], max: segment["addresses"].length, current: [0, segment["addresses"].length - 1], territoryName: segment["addresses"][0].territoryName });//{segmentData:null,streetName:"No street selected.", territoryName:"Tomoka Farms #2", addresses:[], current:0, max:0}
              //console.log(e.latlng);
            });
  
  
            //.bindPopup("<b>" + streetName + "</b><br/>" + segment["lowestNumber"] + " - " + segment["highestNumber"]);
  
            if (!isTouchDevice()) {
              segmentPoly.bindTooltip(streetName + "<br/>" + segment["lowestNumber"] + " - " + segment["highestNumber"], { permanent: false, direction: "center", opacity: 1 })
            }
  
            var points = segment.geometry.coordinates
            var totalLength = totalLen(points);
            var midDistance = totalLength / 2;
            var midPoint = getPointByDistance(points, midDistance)
            //alert ("midPoint = " + midPoint[0] + ", " + midPoint[1])
            // main function
  
            console.log(midPoint);
  
            let mp = [midPoint[1], midPoint[0]]
  
            let m = marker(mp, {
              icon: new DivIcon({
                className: 'kingdom-hall-div-icon',
                interactive: false,
                html: '<div class="my-div-span" style="white-space:nowrap;display:inline-block;transform:translate(-50%,-50%);text-align:center"><b  style="color:' + "#000000" + ';font-size:14px;white-space:nowrap;display:inline-block;text-align:center">' + segment.addresses.length + '</b></div>'//<br/>'+streetName+
              })
            }).bindPopup("<b>" + streetName + "</b><br/>" + segment["lowestNumber"] + " - " + segment["highestNumber"]).addTo(map);
  
            if (!isTouchDevice()) {
              m.bindTooltip(streetName + "<br/>" + segment["lowestNumber"] + " - " + segment["highestNumber"], { permanent: false, direction: "center", opacity: 1 })
            }
  
            segmentPolyList.push(segmentPoly);
          }
        }
      }
  
      var segmentPolyGroup = featureGroup(segmentPolyList).addTo(map); //geoJSON().addTo(map);
  */



  //drawSegment({map,segmentNumber,addresses});
  //})//Not sure what this was originally?



  /*
  const wmrkClass = Control.extend({
    onAdd: function(map) {
        var img = L.DomUtil.create('img');
  
        img.src = 'https://leafletjs.com/docs/images/logo.png';
        img.style.width = '200px';
  
  
        return img;
    },
  
    onRemove: function(map) {
        // Nothing to do here
    }
  });
  
  
  var watermark = new wmrkClass({ position: 'bottomleft' }).addTo(map)
  
  /*
  
  /*
  control.watermark = function(opts) {
    return new (opts);
  }
  
  control.watermark({ position: 'bottomleft' }).addTo(map);
  */


  map.fitBounds(territoryPoly.getBounds());

  return (<></>)
})

const ModifyTerritoryMap = (params) => {

  const [query, reexecuteQuery] = useQuery({
    query: GET_ADDRESS_TERRITORY_CACHES,
    variables: {
      congregationID: "1"
    },
    requestPolicy: "cache-and-network", //"cache-and-network"
  });

  if (!query?.data?.getTerritoryCache || !query?.data?.getAddressCache){
    return(
    <BreadCrumbHeader query={query} />
    )
  }

  return (
    <div className="card">
      <BreadCrumbHeader />{/*data={{ routes: [out["territoryName"]] }} */}

      <div className="card-body bg-light" style={{ paddingTop: 0, paddingBottom: 0 }}>
        {/*<MapWrapper></MapWrapper>*/}

        <h3 style={{ display:"none", textAlign: "center", marginTop: 0, marginBottom: 15 }}>Draw Territory Map</h3>

        <MapContainer style={{ height: 500 }} center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false}>
          <MapChild />
          <TerritoryControl />
        </MapContainer>


<br/>

        
      </div>

      <div className = "card-footer">

<p>This feature is not recommended for usage on mobile devices.</p>
<p>This feature requires a large amount of data to be cached locally, and may cause other parts of the site to run slower as a result. Until this issue is addressed, we recommend that after you use this feature, log out and back in, in order to clear the cache and make the rest of the site run at normal speeds.</p>

        </div>

      {/*<div className="card-body" style={{ minHeight: 300, paddingLeft: 0, paddingRight: 0 }}>






      </div>*/}</div>
  );

};

export default ModifyTerritoryMap;

var CongregationPoly = {
  type: "MultiPolygon",
  coordinates: [[[
    [-81.204152, 29.046756000000002],
    [-81.204085, 29.04653],
    [-81.203469, 29.044741999999992],
    [-81.202551, 29.042115],
    [-81.20221300000001, 29.040714999999995],
    [-81.201841, 29.038101999999995],
    [-81.201721, 29.035595],
    [-81.201592, 29.033556],
    [-81.201829, 29.031488],
    [-81.201606, 29.029408999999998],
    [-81.200574, 29.026721],
    [-81.199686, 29.023488],
    [-81.199623, 29.023515999999997],
    [-81.199043, 29.023734999999995],
    [-81.19521300000001, 29.025297],
    [-81.19493900000002, 29.025420999999998],
    [-81.194565, 29.025536000000002],
    [-81.19165, 29.026049000000008],
    [-81.187347, 29.026773000000002],
    [-81.187042, 29.026837999999998],
    [-81.185249, 29.027309000000002],
    [-81.184883, 29.02744100000001],
    [-81.18453199999999, 29.02759200000001],
    [-81.183319, 29.028225000000006],
    [-81.18158000000001, 29.029072000000003],
    [-81.180607, 29.029401000000007],
    [-81.179901, 29.029640000000004],
    [-81.17952, 29.029721999999996],
    [-81.179191, 29.029749],
    [-81.174728, 29.029776],
    [-81.170741, 29.029867000000003],
    [-81.169144, 29.029902999999997],
    [-81.167282, 29.029930000000004],
    [-81.16689300000002, 29.029899999999998],
    [-81.166618, 29.029852],
    [-81.163643, 29.029186],
    [-81.163368, 29.029139999999998],
    [-81.161011, 29.028974999999992],
    [-81.158582, 29.028801000000005],
    [-81.156662, 29.028662],
    [-81.156448, 29.028624],
    [-81.156151, 29.028543],
    [-81.15477499999999, 29.028081000000007],
    [-81.151062, 29.02683599999999],
    [-81.149323, 29.026219999999995],
    [-81.149048, 29.026146],
    [-81.14860499999999, 29.026076999999997],
    [-81.14827699999998, 29.026070000000008],
    [-81.14577600000001, 29.025939999999995],
    [-81.142598, 29.025773999999995],
    [-81.141289, 29.025705],
    [-81.12915, 29.024930999999995],
    [-81.128486, 29.024897999999997],
    [-81.126434, 29.024795999999995],
    [-81.126434, 29.024895],
    [-81.126404, 29.024971000000008],
    [-81.126335, 29.025028000000006],
    [-81.126251, 29.025060999999997],
    [-81.12451300000001, 29.02533300000001],
    [-81.114517, 29.026932000000002],
    [-81.113861, 29.026988999999997],
    [-81.110489, 29.027059999999995],
    [-81.109924, 29.027013999999998],
    [-81.108261, 29.026743000000007],
    [-81.107764, 29.026654],
    [-81.10711, 29.026536999999994],
    [-81.104416, 29.026051000000017],
    [-81.104042, 29.02601200000001],
    [-81.10364699999998, 29.02601],
    [-81.103569, 29.02601200000001],
    [-81.103294, 29.026037000000006],
    [-81.098473, 29.026723999999998],
    [-81.098297, 29.026777],
    [-81.098045, 29.026892],
    [-81.097893, 29.026986999999995],
    [-81.097687, 29.027157000000006],
    [-81.09671, 29.028267],
    [-81.096588, 29.028387],
    [-81.096367, 29.028542000000005],
    [-81.091447, 29.030872],
    [-81.090248, 29.031434999999995],
    [-81.09000400000001, 29.031560999999996],
    [-81.089622, 29.031798999999996],
    [-81.089203, 29.032130999999996],
    [-81.08908099999998, 29.03225100000001],
    [-81.088997, 29.032331000000006],
    [-81.088997, 29.032352000000003],
    [-81.088631, 29.032713],
    [-81.088371, 29.033024000000005],
    [-81.086494, 29.035704],
    [-81.086334, 29.035954000000007],
    [-81.086136, 29.036218999999996],
    [-81.085892, 29.036504999999995],
    [-81.085495, 29.036892000000005],
    [-81.085197, 29.037138],
    [-81.084656, 29.037510000000008],
    [-81.08419, 29.037764000000006],
    [-81.083954, 29.037872000000004],
    [-81.083801, 29.037939000000005],
    [-81.075615, 29.041041999999997],
    [-81.06750500000001, 29.043734],
    [-81.061055, 29.045895],
    [-81.059296, 29.046488000000004],
    [-81.056129, 29.047521],
    [-81.052995, 29.048543000000002],
    [-81.051064, 29.049173],
    [-81.049767, 29.049585000000008],
    [-81.04720300000001, 29.05041299999999],
    [-81.046516, 29.050583],
    [-81.04609700000002, 29.050658999999996],
    [-81.045769, 29.05070100000001],
    [-81.04274, 29.050987000000003],
    [-81.042468, 29.051013000000008],
    [-81.04061900000002, 29.051186],
    [-81.040306, 29.051205],
    [-81.039742, 29.051207],
    [-81.039289, 29.05117600000001],
    [-81.03643, 29.05095100000001],
    [-81.03596499999999, 29.050892000000008],
    [-81.035221, 29.050751000000005],
    [-81.03501900000002, 29.050713000000002],
    [-81.03434, 29.050537000000002],
    [-81.033272, 29.050268000000003],
    [-81.03275499999998, 29.050131],
    [-81.031309, 29.049746000000003],
    [-81.030045, 29.049409999999998],
    [-81.027133, 29.048927],
    [-81.020483, 29.047824000000002],
    [-81.017994, 29.047411],
    [-81.015076, 29.046928],
    [-81.014763, 29.046906999999997],
    [-81.01446499999999, 29.046911000000005],
    [-81.01399999999998, 29.046965000000007],
    [-81.009407, 29.0474],
    [-81.009166, 29.047419000000005],
    [-81.007195, 29.047572999999996],
    [-81.006978, 29.047589999999996],
    [-81.006729, 29.047609000000005],
    [-81.00676699999998, 29.04806100000001],
    [-81.006882, 29.049447999999998],
    [-81.007294, 29.053936],
    [-81.00782000000001, 29.058561],
    [-81.00821100000002, 29.062823000000005],
    [-81.00824, 29.063139],
    [-81.00861400000001, 29.064487],
    [-81.00964400000001, 29.067362],
    [-81.009895, 29.067970000000006],
    [-81.010327, 29.068885000000005],
    [-81.017029, 29.083080000000002],
    [-81.021093, 29.091055],
    [-81.020645, 29.091067999999996],
    [-81.019769, 29.091078],
    [-81.019215, 29.091334000000003],
    [-81.01831599999998, 29.091912000000004],
    [-81.017917, 29.092279999999995],
    [-81.017475, 29.092572],
    [-81.017178, 29.092732999999992],
    [-81.016925, 29.092789],
    [-81.01655299999999, 29.092709000000006],
    [-81.016299, 29.092596],
    [-81.016286, 29.092482999999998],
    [-81.016563, 29.091776999999997],
    [-81.016275, 29.090993999999995],
    [-81.016041, 29.090361],
    [-81.01610499999998, 29.090007],
    [-81.016574, 29.089653000000002],
    [-81.016595, 29.089074999999998],
    [-81.016424, 29.088461],
    [-81.015764, 29.0882],
    [-81.014804, 29.08838600000001],
    [-81.013334, 29.088646999999998],
    [-81.012865, 29.088218],
    [-81.011586, 29.088144],
    [-81.009966, 29.08806900000001],
    [-81.009305, 29.087231000000003],
    [-81.009092, 29.08616900000001],
    [-81.00894200000002, 29.084678999999998],
    [-81.008239, 29.083412000000006],
    [-81.006652, 29.08261700000001],
    [-81.005632, 29.08304700000001],
    [-81.004588, 29.083633999999996],
    [-81.003665, 29.084127000000006],
    [-81.002801, 29.084347000000008],
    [-81.002129, 29.084159],
    [-81.001752, 29.083740000000002],
    [-81.00142399999999, 29.082822999999998],
    [-81.000506, 29.082421],
    [-80.99968600000001, 29.083022999999997],
    [-80.998899, 29.082679000000006],
    [-80.998801, 29.081447000000004],
    [-80.999719, 29.080272000000004],
    [-80.99919399999999, 29.079526000000005],
    [-80.997948, 29.078007000000003],
    [-80.997423, 29.076546000000004],
    [-80.99762, 29.075198000000004],
    [-80.998342, 29.073995],
    [-80.998309, 29.072848000000004],
    [-80.99775099999998, 29.07173000000001],
    [-80.996866, 29.071272000000008],
    [-80.996079, 29.071128000000005],
    [-80.995817, 29.07147200000001],
    [-80.996604, 29.072676],
    [-80.997128, 29.073565000000006],
    [-80.996735, 29.074625000000005],
    [-80.995718, 29.074682000000003],
    [-80.993783, 29.074710999999997],
    [-80.992603, 29.073937000000008],
    [-80.99281100000002, 29.075217],
    [-80.992867, 29.075630000000004],
    [-80.992869, 29.075995000000002],
    [-80.992874, 29.076948000000005],
    [-80.99287699999998, 29.07776200000001],
    [-80.992889, 29.079082000000003],
    [-80.992912, 29.081047000000005],
    [-80.99292799999999, 29.082699],
    [-80.992952, 29.084455999999996],
    [-80.992958, 29.084909000000003],
    [-80.992973, 29.086066999999996],
    [-80.99298900000001, 29.087183000000003],
    [-80.992996, 29.087763],
    [-80.993011, 29.088724000000003],
    [-80.993027, 29.089773000000005],
    [-80.993034, 29.090898999999997],
    [-80.99312599999999, 29.092402],
    [-80.993141, 29.094784],
    [-80.993164, 29.097525],
    [-80.993187, 29.100789999999993],
    [-80.993187, 29.100925000000004],
    [-80.993187, 29.102020000000003],
    [-80.993195, 29.103556000000005],
    [-80.993202, 29.104452],
    [-80.99321, 29.106663000000005],
    [-80.99326299999998, 29.106863000000004],
    [-80.99508699999998, 29.106851999999996],
    [-80.99556000000001, 29.106848000000006],
    [-80.99709600000001, 29.106839000000004],
    [-80.99803200000001, 29.106833000000005],
    [-80.998878, 29.106827000000006],
    [-80.999397, 29.106825000000008],
    [-81.001404, 29.106899],
    [-81.00183099999998, 29.106915],
    [-81.002434, 29.106909],
    [-81.005135, 29.106884],
    [-81.010239, 29.106831000000003],
    [-81.011284, 29.106831000000003],
    [-81.01327499999998, 29.106830999999996],
    [-81.015419, 29.106830999999996],
    [-81.017555, 29.106845999999997],
    [-81.018456, 29.106850000000005],
    [-81.019699, 29.106857],
    [-81.021751, 29.106871000000005],
    [-81.02288, 29.106879000000003],
    [-81.023422, 29.106895],
    [-81.023834, 29.106901],
    [-81.025917, 29.106949000000007],
    [-81.02668, 29.107967000000006],
    [-81.026779, 29.107758000000004],
    [-81.026901, 29.107560999999993],
    [-81.027, 29.107409],
    [-81.027145, 29.107225],
    [-81.027435, 29.106941000000006],
    [-81.027679, 29.106748999999997],
    [-81.028069, 29.106483000000008],
    [-81.028496, 29.106236000000003],
    [-81.02874, 29.106777000000008],
    [-81.030441, 29.109979999999993],
    [-81.030922, 29.110882000000004],
    [-81.031929, 29.11226700000001],
    [-81.033638, 29.113856999999992],
    [-81.03476699999999, 29.114716000000012],
    [-81.044378, 29.120511],
    [-81.04557, 29.12122899999999],
    [-81.047737, 29.122527999999996],
    [-81.05601, 29.12753500000001],
    [-81.056564, 29.127869000000004],
    [-81.057213, 29.128262],
    [-81.058243, 29.129056],
    [-81.059525, 29.130045],
    [-81.061745, 29.132389],
    [-81.062859, 29.133656999999996],
    [-81.063424, 29.134646000000004],
    [-81.064849, 29.13714],
    [-81.065247, 29.137837999999995],
    [-81.06764999999999, 29.141888000000005],
    [-81.072533, 29.150263],
    [-81.07352399999999, 29.151995],
    [-81.0753, 29.155061],
    [-81.075509, 29.155420999999997],
    [-81.075607, 29.155608999999995],
    [-81.076691, 29.157503],
    [-81.07675900000001, 29.157609999999995],
    [-81.077651, 29.159162000000006],
    [-81.07952899999998, 29.16243],
    [-81.081604, 29.166042000000004],
    [-81.082687, 29.168024000000003],
    [-81.083099, 29.167844999999996],
    [-81.08683, 29.166252000000004],
    [-81.08664700000001, 29.165535000000006],
    [-81.08522, 29.160740000000008],
    [-81.08466899999999, 29.15871],
    [-81.08318300000002, 29.152388000000002],
    [-81.087639, 29.150534],
    [-81.088631, 29.150121999999996],
    [-81.100437, 29.145169000000003],
    [-81.11080899999999, 29.140818000000003],
    [-81.11300699999998, 29.139822000000013],
    [-81.11541700000001, 29.138823000000002],
    [-81.117973, 29.137237999999993],
    [-81.118972, 29.136414000000002],
    [-81.119064, 29.136337],
    [-81.12061300000002, 29.135069000000005],
    [-81.12204, 29.133674999999997],
    [-81.12397000000001, 29.131782999999995],
    [-81.126259, 29.129389000000007],
    [-81.135223, 29.119885999999997],
    [-81.14325699999999, 29.111401000000008],
    [-81.147125, 29.107244],
    [-81.16410099999999, 29.089306],
    [-81.183952, 29.06840500000001],
    [-81.184249, 29.068093000000005],
    [-81.19263499999998, 29.05926100000001],
    [-81.20418799999999, 29.047066]
  ]]]
}

/*
let revpoly = []
for (let p of CongregationPoly["coordinates"][0][0]){
  console.log(p);
revpoly.push(p[1],p[0]);


}
console.log("revpoly")
console.log(revpoly);
*/

function drawSegment({ map, segment, addresses }) {
  /*
  When a segment is clicked, it will select the whole segment? Or just the segment that is colored in? idk For now just do the whole thing
  */

  let addrs = []

  let segmentItems = []



  for (let addr of addresses) {
    if (addr.roadLine && addr.roadLine.properties.segment == segment) {

      if (addr.selectedTerritoryID) {
        addr.showTerritoryID = addr.selectedTerritoryID
      }
      else if (addr.moveToTerritoryID) {
        addr.showTerritoryID = addr.moveToTerritoryID
      } else {
        addr.showTerritoryID = addr.territoryID
      }

      addrs.push(addr);
    }
  }

  addrs.sort(function (a, b) { if (a.roadLine.properties.index > b.roadLine.properties.index) { return 1 } return -1 });

  let terrs = new Set()
  for (let i = 0; i < addrs.length; i++) {
    let addr = addrs[i];
  }

  for (let addr of addrs) {
    terrs.add(addr.showTerritoryID);
  }

  if (terrs.size == 1) {//Show one line
    let firstSide = null;
    let lineStr = []

    let lowestNumber = 999999999;
    let highestNumber = -1;


    for (let i = 0; i < addrs.length; i++) {
      let addr = addrs[i];
      if (firstSide == null) {
        firstSide = addr.roadLine.properties.side;
      }

      if (addr.addressNumber > highestNumber) {
        highestNumber = addr.addressNumber
      }
      if (addr.addressNumber < lowestNumber) {
        lowestNumber = addr.addressNumber
      }

      if (addr.roadLine.properties.side == firstSide) {
        lineStr = [...lineStr, ...addr.roadLine.coordinates];
      }

    }

    let ls = { type: "LineString", coordinates: cleanCoords(lineString(lineStr)).geometry.coordinates };

    let segmentLine = geoJSON(ls, {
      style: {
        ...lineStringStyling, weight: 5, color: allTerritoryColors[addrs[0].showTerritoryID] //"#" + Math.floor(Math.random() * 16777215).toString(16)//"#ff0000"
      }
    })

    //segmentLine.bindPopup(addrs[0].streetName + " (" + addrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber)

    let terrName = getTerritoryNameFromID(addrs[0].showTerritoryID)

    if (!isTouchDevice()) {
      segmentLine.bindTooltip(addrs[0].streetName + " (" + addrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber + "<br/> " + terrName, { permanent: false, direction: "center", opacity: 1 })
    }

    var points = ls.coordinates
    var totalLength = totalLen(points);
    var midDistance = totalLength / 2;
    var midPoint = getPointByDistance(points, midDistance)
    //alert ("midPoint = " + midPoint[0] + ", " + midPoint[1])
    // main function

    let mp = [midPoint[1], midPoint[0]]



    let m = marker(mp, {
      icon: new DivIcon({
        className: 'kingdom-hall-div-icon',
        interactive: false,
        //html: '<div class="my-div-span" style="white-space:nowrap;display:inline-block;transform:translate(-50%,-50%);text-align:center"><b  style="color:' + "#000000" + ';font-size:14px;white-space:nowrap;display:inline-block;text-align:center">' + addrs.length + '</b></div>'//<br/>'+streetName+
        html: '<div class="my-div-span" style="white-space:nowrap;display:inline-block;transform:translate(-50%,-50%);text-align:center"><b  style="color:' + "#000000" + ';font-size:14px;white-space:nowrap;display:inline-block;text-align:center">' +
          '<i>' + "" //terrName  
          + '</i>' + '<span style="text-decoration:underlined">' + addrs.length + '</span>' + '</b></div>'//<br/>'+streetName+

      })
    }).on('click', function (e) {
      selectStreet({
        segmentData: null,
        segmentNumber: segment,
        streetName: addrs[0].streetName,
        addresses: addrs,
        max: addrs.length,
        current: [0, addrs.length - 1],
        territoryName: addrs[0].territoryName, leftEnabled: true, rightEnabled: true
      });//{segmentData:null,streetName:"No street selected.", territoryName:"Tomoka Farms #2", addresses:[], current:0, max:0}  
    })

    if (!isTouchDevice()) {
      m.bindTooltip(addrs[0].streetName + " (" + addrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber + "<br/> " + terrName, { permanent: false, direction: "center", opacity: 1 })
    }

    segmentItems.push(m);


    segmentItems.push(segmentLine);

    segmentLine.on('click', function (e) {
      selectStreet({
        segmentData: null,
        segmentNumber: segment,
        streetName: addrs[0].streetName,
        addresses: addrs,
        max: addrs.length,
        current: [0, addrs.length - 1],
        territoryName: addrs[0].territoryName, leftEnabled: true, rightEnabled: true
      });//{segmentData:null,streetName:"No street selected.", territoryName:"Tomoka Farms #2", addresses:[], current:0, max:0}  
    })


  } else {//Show 2 lines

    let terrLines = {}

    let curTerritory = "";
    let curSide = "";
    let lineStr = [];
    let curAddrs = [];

    let finishLineString = function (addr) {
      if (lineStr.length > 0) {
        /*
                let offs = .00003;
                if (curSide == "left"){
                  offs = -.000003;
                }
        
        
        
        let lstr = cleanCoords(lineString(lineStr));
        
        
                console.log("bef lineStr")
        console.log(lineStr);
        console.log(lstr);
        
               // console.log(lineStr)
               // console.log(lineString(lineStr));
               // console.log(lineOffset(cleanCoords(lineString(lineStr)),offs, {units:"degrees"} ));
                //console.log(cleanCoords( lineOffset(lineString(lineStr),offs, {units:"degrees"} )));
        
                var offsetLine = lineOffset(lineString(address.roadLine.coordinates), offs, {units: 'degrees'});
                console.log("RL       RL          RL");
                    let rl = {type:"LineString",coordinates:offsetLine.geometry.coordinates//address["roadLine"]["coordinates"]
        
        
        let offsetLine = lineOffset(lstr, offs, {units: 'degrees'});
        console.log(lstr)
        console.log(lstr.geometry.coordinates);*/

        let lowestNumber = 999999999;
        let highestNumber = -1;

        for (let i = 0; i < curAddrs.length; i++) {
          let addr = curAddrs[i];
          if (addr.addressNumber > highestNumber) {
            highestNumber = addr.addressNumber
          }
          if (addr.addressNumber < lowestNumber) {
            lowestNumber = addr.addressNumber
          }
        }

        let ls = {
          type: "MultiLineString", coordinates: lineStr //offsetLine.geometry.coordinates  //lineOffset( cleanCoords(lineString(lineStr)),offs, {units:"degrees"} ).geometry.coordinates 
        };

        let segmentLine = geoJSON(ls, {
          style: {
            ...lineStringStyling, weight: 5, color: allTerritoryColors[curTerritory] //"#" + Math.floor(Math.random() * 16777215).toString(16)//"#ff0000"
          }
        })

        segmentLine.bindPopup(curAddrs[0].streetName + " (" + curAddrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber)

        if (!isTouchDevice()) {

          let terrName = getTerritoryNameFromID(curAddrs[0].showTerritoryID)

          segmentLine.bindTooltip(curAddrs[0].streetName + " (" + curAddrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber + "<br>" + terrName, { permanent: false, direction: "center", opacity: 1 })
        }

        segmentLine.on('click', function (e) {
          selectStreet({
            segmentData: null,
            segmentNumber: segment,
            streetName: addrs[0].streetName,
            addresses: addrs,
            max: addrs.length,
            current: [0, addrs.length - 1],
            territoryName: "Multiple Territories", leftEnabled: true, rightEnabled: true //addrs[0].territoryName 
          });//{segmentData:null,streetName:"No street selected.", territoryName:"Tomoka Farms #2", addresses:[], current:0, max:0}  
        })


        /*   var points = []; //ls.coordinates
           for (let lstr in ls.coordinates){
             points = [...points,...lstr.coordinates]
           }
       
   
           var totalLength = totalLen(points);
           var midDistance = totalLength / 2;
           var midPoint = getPointByDistance(points, midDistance)
           //alert ("midPoint = " + midPoint[0] + ", " + midPoint[1])
           // main function
           console.log(midPoint);
           let mp = [midPoint[1], midPoint[0]]*/

        /*let mp = pointOnFeature(multiLineString(ls.coordinates)).geometry.coordinates;

        let m = marker([mp[1], mp[0]], {
          icon: new DivIcon({
            className: 'kingdom-hall-div-icon',
            interactive: false,
            html: '<div class="my-div-span" style="white-space:nowrap;display:inline-block;transform:translate(-50%,-50%);text-align:center"><b  style="color:' + "#000000" + ';font-size:14px;white-space:nowrap;display:inline-block;text-align:center">' + curAddrs.length + '</b></div>'//<br/>'+streetName+
          })
        }).bindPopup(curAddrs[0].streetName + " (" + curAddrs.length + ")" + "<br/>" + lowestNumber + " - " + highestNumber)

        segmentItems.push(m);*/
        segmentItems.push(segmentLine);
      }
      curTerritory = addr.showTerritoryID;
      //curSide = ""addr.roadLine.properties.side;
      lineStr = [];
      curAddrs = [];
    }

    for (let side of ["left", "right"]) {
      curSide = side;
      let offs = .00004;
      if (curSide == "left") {
        offs = -.00004;
      }
      let lastAddr;
      for (let i = 0; i < addrs.length; i++) {
        let addr = addrs[i];
        lastAddr = addr;
        if (addr.roadLine.properties.side == side) {
          if (addr.showTerritoryID != curTerritory) {
            finishLineString(addr);
          }//else{


          let lstr = lineString(addr.roadLine.coordinates);
          let offsetLine = lineOffset(lstr, offs, { units: 'degrees' }).geometry.coordinates;

          lineStr = [...lineStr, ...[offsetLine]] //...addr.roadLine.coordinates];
          curAddrs.push(addr);

          if (!terrLines[addr.showTerritoryID]) {
            terrLines[addr.showTerritoryID] = []
          }
          terrLines[addr.showTerritoryID].push([offsetLine]) //= //[...terrLines[addr.showTerritoryID],...offsetLine]
        }
      }
      finishLineString(lastAddr);//Check to make sure this works right...
    }
    //finishLineString(addrs[0]);

    for (let terr in terrLines) {
      let pnts = []
      for (let mlstr of terrLines[terr]) {
        for (let lstr of mlstr) {
          for (let coord of lstr) {
            pnts.push(coord);
          }
        }
      }

      let lines = multiLineString(terrLines[terr]);
      let num = terrLines[terr].length;

      let terrName = getTerritoryNameFromID(terr);
      let pnts2 = turf.points(pnts);

      //let centpoint = center(pnts2);
      let centpoint = turf.centerMean(pnts2)
      /*let pnts = points(lines);
      console.log(pnts)

      let bb = bbox(pnts);
      console.log(bb);
      let cent = center(bb);
      console.log(cent);
      let npl = nearestPointOnLine(lines,cent);
      console.log(npl);*/

      //let ack = nearestPointOnLine(lines, center(lines)).geometry.coordinates;



      let ack = nearestPointOnLine(lines, centpoint).geometry.coordinates;

      ack = turf.nearestPoint(centpoint, pnts2).geometry.coordinates;

      let m = marker([ack[1], ack[0]], {
        icon: new DivIcon({
          className: 'kingdom-hall-div-icon',
          interactive: false,
          html: '<div class="my-div-span" style="white-space:nowrap;display:inline-block;transform:translate(-50%,-50%);text-align:center"><b  style="color:' + "#000000" + ';font-size:14px;white-space:nowrap;display:inline-block;text-align:center">' +
            '<i>' + "" //terrName
            + '</i>' + '<span style="text-decoration:underlined">' + num + '</span>' + '</b></div>'//<br/>'+streetName+
        })
      }).on('click', function (e) {
        selectStreet({
          segmentData: null,
          segmentNumber: segment,
          streetName: addrs[0].streetName,
          addresses: addrs,
          max: addrs.length,
          current: [0, addrs.length - 1],
          territoryName: "Multiple Territories", leftEnabled: true, rightEnabled: true //addrs[0].territoryName 
        });//{segmentData:null,streetName:"No street selected.", territoryName:"Tomoka Farms #2", addresses:[], current:0, max:0}  
      })

      if (!isTouchDevice()) {

        let curAddrs = []

        let streetName = ""

        for (let addr of addrs) {
          if (addr.showTerritoryID == terr) {
            curAddrs.push(addr);
            streetName = addr.streetName
          }
        }
        let lowestNumber = 999999999;
        let highestNumber = -1;

        for (let i = 0; i < curAddrs.length; i++) {
          let addr = curAddrs[i];
          if (addr.addressNumber > highestNumber) {
            highestNumber = addr.addressNumber
          }
          if (addr.addressNumber < lowestNumber) {
            lowestNumber = addr.addressNumber
          }
        }

        m.bindTooltip(streetName + " (" + num + ")" + "<br/>" + lowestNumber + " - " + highestNumber + "<br>" + terrName, { permanent: false, direction: "center", opacity: 1 })
      }

      segmentItems.push(m);

    }

  }
  //Now... Create a feature group. And add to map.


  //allSegmentGroups[segment]; delete it

  if (segment in allSegmentGroups) {
    allSegmentGroups[segment].remove();
  }

  let segmentFeatureGroup = featureGroup(segmentItems).addTo(map);
  allSegmentGroups[segment] = segmentFeatureGroup;
}





