import { gql } from "urql"
import { BaseDatabaseItem } from "./Default"
import { Territory } from "./Territory"

export class GeoJSON{
  "type":'Point'|'MultiPoint'|'Polygon'|'MultiPolygon'|'LineString'|'MultiLineString';
  properties?:Object
  "coordinates":number[]|number[][]|number[][][]|number[][][][]
}

export class DoNotCallRecord {
  notes?: string
  type?: string//Either or?
  date?: Date
  lastReviewed?: Date
  publisherID?: string
  publisherName?: string
  attention?: string
}

export class HouseHolder {
  firstName?: string
  lastName?: string
  middleName?: string
  fullName?: string
  birthDate?: Date
  age?: number
  phone?: string
  religion?: string
}

export class LiteraturePlacedRecord {
  literature?: string
  date?: Date
  publisherID?: string
  publisherName?: string
}

export class Address extends BaseDatabaseItem {
  address?: string

  congregationID?: string

  territoryID?: string

  territoryName?: string

  notes?: string

  literaturePlaced?: LiteraturePlacedRecord[]

  doNotCall?: DoNotCallRecord

  addressNumber?: number

  streetName?: string

  apartment?: string

  coordinates?: GeoJSON //GeoJSON

  parcel?: GeoJSON

  roadLine?: GeoJSON

  houseHolders?: HouseHolder[]

  moveToTerritoryID?: string

  doorToDoor?: boolean

  gatedCommunity?: boolean

  city?: string

  postalCode?: string

  county?: string

  country?: string

  neighborhoodName?: string

  customID1?: string//AltKey Volusia county prop appraiser

  placedLiterature?: string//For input only
  dateLiteratureSent?: Date//For input only

  territory?: typeof Territory
}

export const AddressQueryFields = `
id
congregationID
address
territoryID
territoryName
notes
literaturePlaced{
  literature
  publisherID
  publisherID
  date
}
doNotCall{
  publisherID
  publisherName
  type
  date
  notes
  lastReviewed
  attention
}
addressNumber
streetName
apartment
coordinates
parcel
roadLine
houseHolders{
  fullName
  firstName
  phone
  religion
  age
  lastName
}
moveToTerritoryID
doorToDoor
gatedCommunity
city
postalCode
county
country
neighborhoodName

version
`

export const UPDATE_ADDRESS_CACHE = gql`
mutation updateAddrCache($congregationID:ID!,$lastUpdated:Date!){
  updateAddressCache(congregationID:$congregationID,lastUpdated:$lastUpdated){
   ${AddressQueryFields}
  }
  }`



export const GET_ADDRESS_CACHE = gql`
query getaddrCache($congregationID:ID!){
  getAddressCache(congregationID:$congregationID){
   ${AddressQueryFields}
  }
  }`