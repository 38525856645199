import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import { LoadingSpinner } from "./LoadingSpinner";

import { gql, useQuery } from "urql";

const GET_TERRITORY_NAME = gql`
query getterrnamefromid($id:ID!){
  getTerritoryByID(id:$id){
    id
    territoryName
    }
  }
`

const GET_PUBLISHER_NAME = gql`
query getpubnamefromid($id:ID!){
  getPublisherByID(id:$id){
    id
    displayName
    }
  }
`

const GET_ADDRESS_NAME = gql`
query getaddrnamefromid($id:ID!){
  getAddressByID(id:$id){
    id
    address
    }
  }
`

let delDuplicates = function (urlBC) {
  for (let i = 0; i < urlBC.length; i++) {
    let firstBC = urlBC[i]//.split("/")[1]
    console.log("fbc")
    console.log(firstBC);

    for (let i2 = i + 1; i2 < urlBC.length; i2++) {
      {
        let secondBC = urlBC[i2]//.split("/")[1];
        if (secondBC == firstBC) {
          urlBC.length = i + 1;
          return urlBC

        }
      }
    }
  }
  return urlBC;
}

const BreadCrumbHeader = ({ query, loading }) => {

  const location = useLocation()

  let route = location.pathname.trim()


  //routes = routes.unshift({title:"Home",route:"/"})

  let urlBC = window.sessionStorage.getItem("bc") //searchParams.get("bc"); //new URLSearchParams(location.search).get("bc")

  let origURLBC = urlBC || ""

  if (urlBC) {
    urlBC = JSON.parse(urlBC)
  } else {
    urlBC = ["/"]
  }

  if (urlBC.length >= 1 && urlBC[urlBC.length - 1].replaceAll("/", "") != route.replaceAll("/", "")) {//Append current breadcrumb to the end of the url.
    urlBC.push(route);
  }

  if (urlBC[0] && urlBC[0] != "/") {
    urlBC.unshift("/");
  }

  if (urlBC[urlBC.length - 1].trim() == "/") {
    urlBC = ["/"];
  }

  delDuplicates(urlBC);//If a field shows up twice, it deletes everything after the first copy. Keeps hitting "Go Back" from duplicating entries over and over





  useEffect(() => {
    let jstr = JSON.stringify(urlBC);
    if (jstr != origURLBC) {
      window.sessionStorage.setItem("bc", jstr)
    }
  })

  if (query) {
    return (
      <div className="card" style={{ marginTop: 15 }}>
        <div className="card-header bg-light">
          <Breadcrumb>
            {urlBC.map((route, index) => {
              return (
                <BreadCrumbWrapper key={index} urlBC={[...urlBC]} route={route} index={index} active={index == urlBC.length - 1} />
              )
            })}
          </Breadcrumb>
        </div>
        <div className="card-body">{/*  <span className="ellipsis-loading">Loading</span> */}
          <span>{(query.fetching || loading ) && !query.error && <LoadingSpinner/>}{query.error && <span>{"ERROR: " + query.error.message.replaceAll("[GraphQL]", "") //JSON.stringify(query.error)
          }
          </span>}</span>

        </div>
      </div>
    )
  }


  {/*return (
    <div className="bg-shape modal-shape-header px-4 position-relative card-header">
    <div className="position-relative z-index-1 light" style={{color:"white !important"}}>
    <Breadcrumb>
        {urlBC.map((route, index) => {
          return (
            <BreadCrumbWrapper key={index} urlBC={[...urlBC]} route={route} index={index} active={index == urlBC.length - 1} />
          )
        })}
      </Breadcrumb>
    </div>
  </div>
  )*/}

  return (
    <div className="card-header bg-light">
      <Breadcrumb>
        {urlBC.map((route, index) => {
          return (
            <BreadCrumbWrapper key={index} urlBC={[...urlBC]} route={route} index={index} active={index == urlBC.length - 1} />
          )
        })}
      </Breadcrumb>
    </div>
  )





}

export default BreadCrumbHeader;

const BreadCrumbWrapper = ({ route, active = false, urlBC, index }) => {

  let routePieces = route.split("/").filter(str => str);

  let title = "";
  let href = route;

  if (routePieces.length == 0) {
    title = "Home";
  } else if (routePieces.length == 1) {
    if (routePieces[0] == "modifyTerritoryMap") {
      title = "Draw Territories"
    } else if (routePieces[0] == "createPublisher") {
      title = "Create New Publisher"
    } else if (routePieces[0] == "uploadTerritoryJSON") {
      title = "Upload JSON"
    } else if (routePieces[0] == "viewPublishers") {
      title = "View Publishers"
    }else if (routePieces[0] == "viewTerritories") {
      title = "View Territories"
    }
  }
  else if (routePieces.length == 2) {
    if (routePieces[0] == "assignTerritory") {
      title = "Assign Territories"
    }
    else if (routePieces[0] == "viewTerritory") {
      const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_NAME,
        variables: {
          id: routePieces[1]
        },
        requestPolicy: "cache-first"
      });

      if (query.data && query.data.getTerritoryByID && query.data.getTerritoryByID.territoryName) {
        title = "" + query.data.getTerritoryByID.territoryName;
      }
    }
    else if (routePieces[0] == "editPublisher") {
      const [query, reexecuteQuery] = useQuery({
        query: GET_PUBLISHER_NAME,
        variables: {
          id: routePieces[1]
        },
        requestPolicy: "cache-first"
      });

      if (query.data && query.data.getPublisherByID && query.data.getPublisherByID.displayName) {
        title = "" + query.data.getPublisherByID.displayName;
      }
    }
    else if (routePieces[0] == "researchAddress") {
      const [query, reexecuteQuery] = useQuery({
        query: GET_ADDRESS_NAME,
        variables: {
          id: routePieces[1]
        },
        requestPolicy: "cache-first"
      });

      if (query.data && query.data.getAddressByID && query.data.getAddressByID.address) {
        title = "" + query.data.getAddressByID.address;
      }
    } else if (routePieces[0] == "editTerritoryWorkHistory") {
      const [query, reexecuteQuery] = useQuery({
        query: GET_TERRITORY_NAME,
        variables: {
          id: routePieces[1]
        },
        requestPolicy: "cache-first"
      });

      if (query.data && query.data.getTerritoryByID && query.data.getTerritoryByID.territoryName) {
        title = "" + query.data.getTerritoryByID.territoryName;
      }
    }  else if (routePieces[0] == "doNotCall") {
      const [query, reexecuteQuery] = useQuery({
        query: GET_ADDRESS_NAME,
        variables: {
          id: routePieces[1]
        },
        requestPolicy: "cache-first"
      });

      if (query.data && query.data.getAddressByID && query.data.getAddressByID.address) {
        title = "" + query.data.getAddressByID.address;
      }
    }

  }

  //need to set HREF so it changes the BC to disinclude stuff that came after?

  return (
    <Breadcrumb.Item onClick={() => { window.sessionStorage.setItem("bc", JSON.stringify(urlBC.slice(0, index + 1))) }} href={"#" + href} active={active} >{title}</Breadcrumb.Item>
  )
}