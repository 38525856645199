import { faFilePdf, faFileImage } from '@fortawesome/free-regular-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
//import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from 'components/common/Flex';
import { customReactSelectStyle } from 'helpers/reactSelectStyle';
import useWindowDimensions from 'helpers/useWindowDimensions';
import { GET_PUBLISHER_CACHE, Publisher } from "models/Publisher";
import { GET_TERRITORY_CACHE, Territory } from "models/Territory";
import React, { useMemo } from "react";
import { useState, useReducer } from "react";
import { Button, Col, Dropdown, Form, FormControl, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { gql, useClient, useQuery } from "urql";
import CardDropdown from "components/common/CardDropdown"

import { SearchHeader, SearchFooter, PaginationDataClass, PaginationDataReducer } from "newComponents/SearchBar";
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';

export const ViewPublishersPage = (params: {}) => {

    const [query, reexecuteQuery] = useQuery({
        query: GET_PUBLISHER_CACHE, //GET_ALL_PUBLISHERS,
        variables: {
            congregationID: "1"
        },
        requestPolicy: "cache-and-network"//cache-first
    });

    const windowSize = useWindowDimensions();

    const [paginationData, paginationReducer] = useReducer(PaginationDataReducer, new PaginationDataClass({
        searchFields: ["displayName"], sortByOptions:
            [{ label: "Full Name", value: "displayName", field: "displayName" },
            { label: "Last Name", value: "lastName", field: "lastName" },
            { label: "First Name", value: "firstName", field: "firstName" },
            { label: "Email", value: "Email", field: "email" },
            { label: "Phone", value: "phone", field: "phone" }],

        itemsPerPageOptions: [{ label: "5", value: 5 }, { label: "15 per page", value: 15 }, { label: "50 per page", value: 50 }, { label: "100", value: 100 }, { label: "All", value: 99999 }]

    }));

    let allPublishers = query?.data?.getPublisherCache;

    console.log("all pubs");
    console.log(allPublishers)

    const filteredPublishers = useMemo(() => paginationData.sortInputItems(allPublishers, paginationData, paginationReducer), [allPublishers, paginationData]);

    console.log("filtered pubs");
    console.log(filteredPublishers);

    if (query.fetching || query.error) {
        return (
            <BreadCrumbHeader query={query} />
        )
    }

    return (
        <div className="card">

            <BreadCrumbHeader />

            <div className="card-body bg-light"><SearchHeader paginationData={paginationData} paginationReducer={paginationReducer}></SearchHeader></div>

            <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>



                <Table striped responsive style={{ marginBottom: 0 }}>
                    <thead className="bg-light mobile-table">
                        <tr>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Publisher</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Email</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Phone</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Roles</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredPublishers.map((publisher: Publisher, index: number) => {
                            return (
                                <tr key={index}>
                                    <td style={{ paddingLeft: 12 }} className="mobile-table" data-th="Publisher: ">{publisher.displayName}
                                        <span style={{ display: windowSize.isMobile && "inline-block" || "none", float: "right" }}>
                                            <CardDropdown>
                                                <div className="py-2">
                                                    <Dropdown.Item href={"#/editPublisher/" + publisher.id}>Edit Publisher</Dropdown.Item>

                                                </div>
                                            </CardDropdown>
                                        </span></td>
                                    <td className={publisher.email && "mobile-table" || "mobile-table-empty"} data-th="Email: " >{publisher.email || ""}</td>
                                    <td className={publisher.phone && "mobile-table" || "mobile-table-empty"} data-th="Phone: ">{publisher.phone || ""}</td>
                                    <td className="mobile-table" data-th="Roles: ">{publisher.roles.map((r) => { if (r == 2) return "Publisher"; if (r == 1) return "Attendee"; if (r == 3) return "Researcher"; if (r==10) return "Admin"; if (r==11) return "Service Overseer"; if (r==12) return "Territory Servant"; }).join(", ")}</td>
                                    <td className={!windowSize.isMobile && "mobile-table" || "mobile-table-empty"} >

                                        <span style={{ display: !windowSize.isMobile && "block" || "none", marginLeft:"auto",marginRight:"auto" }}>
                                            <CardDropdown>
                                                <div className="py-2">
                                                    <Dropdown.Item href={"#/editPublisher/" + publisher.id}>Edit Publisher</Dropdown.Item>

                                                </div>
                                            </CardDropdown>
                                        </span>

                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>

            </div>

            <div className="card-footer bg-light">

                <SearchFooter paginationData={paginationData} paginationReducer={paginationReducer} />

            </div>

            {/*<div className="card-body bg-light" style={{ paddingTop: 0 }}>

                {to < numberOfUnassignedTerritories && <button className="btn btn-primary" style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                    onClick={() => {
                        setPageNumber(pageNumber + 1);
                    }}>Load More</button>
                }

                <i className="mb-0 ms-2" style={{ float: "right", fontSize: 14 }}>
                    Showing {to} of {numberOfUnassignedTerritories} Territories
                </i>

            </div>*/}

        </div>
    )
}