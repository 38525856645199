import { faFilePdf, faFileImage, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faRulerHorizontal as faRuler, faSearch, faWalking } from '@fortawesome/free-solid-svg-icons';
import {faMap} from "@fortawesome/free-regular-svg-icons"
//import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from 'components/common/Flex';
import { customReactSelectStyle } from 'helpers/reactSelectStyle';
import useWindowDimensions from 'helpers/useWindowDimensions';
import { Publisher } from "models/Publisher";
import { GET_TERRITORY_CACHE, Territory } from "models/Territory";
import { PaginationDataClass, PaginationDataReducer, SearchFooter, SearchHeader } from 'newComponents/SearchBar';
import React, { useContext, useMemo, useReducer } from "react";
import { useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { Link } from 'react-router-dom';
import CreatableSelect from "react-select/creatable";
import { gql, useClient, useQuery } from "urql";
import { useFormContext } from 'react-hook-form';
import { AuthContext } from 'utilities/Authentication';
import { time } from 'console';

var debounce = false;


const CHECK_OUT_TERRITORIES = gql`
  mutation checkOut($territoryIDs:[ID!]!,$publisherID:ID!,$email:String,$phone:String){
    checkOutTerritories(territoryIDs:$territoryIDs,publisherID:$publisherID,email:$email,phone:$phone){
        id
        territoryName
        assignedToPublisherID
        assignedToPublisherName
        checkedOutAt
        lastWorked
        lastResearched
        congregationID
        doorToDoor
        gatedCommunity
        updatedAt
        createdAt
        pendingAddressChanges
        workHistory{
          campaign
          checkedIn
          checkedOut
          researched
          publisherID
          publisherName
        } 
    }
  }`

const GET_ALL_TERRITORIES = gql`
query getAllTerrs{
    getAllTerritories{
      id
      territoryName
      assignedToPublisherID
      assignedToPublisherName
      checkedOutAt
      lastWorked
      lastResearched
      congregationID
      doorToDoor
      gatedCommunity
      updatedAt
      createdAt
      pendingAddressChanges
      workHistory{
        campaign
        checkedIn
        checkedOut
        researched
        publisherID
        publisherName
      } 
    }
    }
`
function researchedSearchFunction (paginationData, inputItems) {
    let d = new Date().getTime()
    return inputItems.sort(function (a, b) {
        let adays = Math.ceil((d - a.lastWorked ) / (2592000000 * 3))
        let bdays = Math.ceil((d - b.lastWorked) / (2592000000 * 3))

        
        console.log("adays");
        console.log(adays);
        console.log(bdays);

        if (adays > bdays) {
            return -1 
        } else if (adays < bdays) {
            return 1
        }

        if (!a.lastResearched && !b.lastResearched) {
            return 0
        } else if (!a.lastResearched) {
            return 1 
        } else if (!b.lastResearched) {
            return -1 
        }

        if (a.lastResearched > b.lastResearched) {
            return 1 
        } else if (a.lastResearched < b.lastResearched) {
            return -1
        }
        return 0;

    })
}

const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!candidate.label.includes(inp[i])) {
            return false
        }
    }
    return true;
};


/*
const customReactSelectStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        //border: '1px solid black',
        color: "rgba(255,0,0,0) !important !important",

        backgroundColor: state.isSelected ? "rgba(44, 123, 229, .3)" //"#2c7be5" //"rgba(189,197,209,.2)" 
            : state.isFocused ? "rgba(189,197,209,.1)" : "white",


        ":active": { backgroundColor: "rgba(189,197,209,.1)" },
        //   ":selected":{backgroundColor:"rgba(189,197,209,.3)"},
        // ":focus":{backgroundColor:"rgba(189,197,209,.1)"},
        //isSelected:{
        //  backgroundColor:"red !important"
        //}
        //  borderBottom: '1px dotted pink',
        //color: state.isSelected ? 'red' : 'blue',
    }),

    singleValue: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgb(52,64,80) !important",
        fontFamily: "var(--falcon-body-font-family) !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),

    placeholder: (provided, state) => ({//Selected value, not placeholder
        ...provided,
        color: "rgba(52,64,80,.6) !important",
        fontFamily: "var(--falcon-body-font-family) !important"
        //      fontFamily:"Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;"
    }),



    control: (provided, state) => ({
        ...provided,
        borderColor: "rgba(80,80,80,.3) !important",
        borderWidth: "1px !important",
        borderRadius: "4px !important"
    }),

    //control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
    //}),
    //singleValue: (provided, state) => {
    //  const opacity = state.isDisabled ? 0.5 : 1;
    //  const transition = 'opacity 300ms';
    // return { ...provided, opacity, transition };
    //}
}
*/

export const UnassignedTerritoriesCard = (params: { selectedPublisher: Publisher , territoryMapModalData, setTerritoryMapModalData}) => {

    const graphQLClient = useClient();

    const authContext= useContext(AuthContext);

    const [query, reexecuteTerritoryQuery] = useQuery({
        query: GET_TERRITORY_CACHE, //GET_ALL_TERRITORIES,
        variables: {
            congregationID: authContext.currentDomain
        },
        requestPolicy: "cache-first" //"cache-and-network"
    });

    const [buttonDisabled,setButtonDisabled] = useState(false);

    const [selTerritoryIDs, setSelectedTerritoryIDs] = useState<string[]>([]);
    let selectedTerritoryIDs = new Set(selTerritoryIDs);


    console.log("selected terrs");
    console.log(selectedTerritoryIDs);

    const [paginationData, paginationReducer] = useReducer(PaginationDataReducer, new PaginationDataClass({
        searchFields: ["territoryName"], 
        ascending:true,
        sortByOptions:
            [{ label: "Worked", value: "worked", field: "lastWorked" },

            {label: "Researched", value: "researched", sortFunction: researchedSearchFunction},

            {label: "Worked - Door to Door", value: "worked-door", field:"lastWorked", filterFunction: function (paginationData, inputItems) {            
                return inputItems.filter(function (a) {
                    return a.doorToDoor
                })}},

                {label: "Worked - Letter Writing", value: "worked-letter", field:"lastWorked", filterFunction: function (paginationData, inputItems) {            
                    return inputItems.filter(function (a) {
                        return !a.doorToDoor
                    })} },


            {label: "Researched - Letter Writing", value: "researched-letter", filterFunction: function (paginationData, inputItems) {            
                return inputItems.filter(function (a) {
                    return !a.doorToDoor
                })}, sortFunction: researchedSearchFunction
            },

            {label: "Researched - Door to Door", value: "researched-door", filterFunction: function (paginationData, inputItems) {            
                return inputItems.filter(function (a) {
                    return !a.doorToDoor
                })}, sortFunction: researchedSearchFunction
        },


            { label: "Name", value: "name", field: "territoryName" },
            { label: "Neighborhood", value: "neighborhood", field: "neighborhood" },
            ],
        itemsPerPage:3,
        itemsPerPageOptions: [{ label: "3", value: 3 }, { label: "5", value: 5 }, { label: "15", value: 15 }, { label: "50", value: 50 }, { label: "All", value: 99999 }]

    }));

    let unfilteredTerritories = query?.data?.getTerritoryCache as Territory[] || [];

    unfilteredTerritories = unfilteredTerritories.filter((terr) => {
        if (!terr.lastWorked) {
            return false
        }
        if (terr.pendingAddressChanges) {
            return false;
        }
        if ("numberAddresses" in terr && terr.numberAddresses == 0) {
            return false;
        }
        return true
    })

    let allTerritories = useMemo(() => paginationData.sortInputItems(unfilteredTerritories, paginationData, paginationReducer), [unfilteredTerritories, paginationData]);//Need to pass selectedTerritoryIDs to this and have it filter some out?

    let foundTerritoryIDs = new Set(selTerritoryIDs)//Remove any selections that are no longer currently paginated.//Figure out a more efficient way to do this later. this works for now
    if (foundTerritoryIDs.size >= 1) {
        for (let terr of allTerritories) {
            if (foundTerritoryIDs.has(terr.id)) {
                foundTerritoryIDs.delete(terr.id);
                if (foundTerritoryIDs.size == 0) {
                    break
                }
            }
        }
        if (foundTerritoryIDs.size >= 1) {
            for (let id of Array.from(foundTerritoryIDs)) {
                selectedTerritoryIDs.delete(id);
            }
            setSelectedTerritoryIDs(Array.from(selectedTerritoryIDs));
        }
    }

    const [phoneNumber, setPhoneNumber] = useState(params.selectedPublisher.phone || "");
    const [phoneNumberEnabled, setPhoneNumberEnabled] = useState((params.selectedPublisher.hasOwnProperty("phone") && params.selectedPublisher["phone"]));

    const [email, setEMail] = useState(params.selectedPublisher.email || "");
    const [emailEnabled, setEMailEnabled] = useState((params.selectedPublisher.hasOwnProperty("email") && params.selectedPublisher.email));

    const windowDimensions = useWindowDimensions();

    if (!query?.data?.getTerritoryCache){
        return (
            <p className='ellipsis-loading loading-ellipsis'>Loading</p>
        )
    }

    if (query.fetching) {
        return (
            <div className="card" style={{ marginTop: 15 }}>
                <div className="card-body">
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    if (query.error) {
        console.log(query);
        return (
            <div className="card" style={{ marginTop: 15 }}>
                <div className="card-body">
                    <p>{JSON.stringify(query.error)}</p>
                </div>
            </div>
        )
    }

    let now = new Date();

    return (
        <div className="card" style={{ marginTop: 15 }}>

            <div className="card-header">
            <h5 style={{marginBottom:20}}>Assign Territories To {params.selectedPublisher.firstName}</h5>
                <SearchHeader paginationData={paginationData} paginationReducer={paginationReducer} />
            </div>

            <div className="card-body" style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }}>

                <Table striped responsive style={{ marginBottom: 0 }}>
                    <thead className="bg-light mobile-table">
                        <tr>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Territory</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Worked</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>Researched</th>
                            <th className="mobile-table" scope="col" style={{ textAlign: "center" }}>History</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTerritories.map((territory: Territory) => {
                            // let outdatestr = "" + Math.floor((territory.checkedOutAt.getTime() - territory.checkedOutAt.getTime()) / (1000 * 3600 * 24)) + " Days Ago<br>" + (territory.checkedOutAt.getMonth() + 1) + "/" + territory.checkedOutAt.getDate() + "/" + territory.checkedOutAt.getFullYear().toString().slice("-2");
                            return (
                                <tr key={territory.id}>
                                    <td className="mobile-table" style={{ paddingLeft: 12 }}>
                                        <label style={{ marginBottom: 0 }}>
                                            <input type="checkbox" className="form-check-input" checked={selectedTerritoryIDs.has(territory["id"])} onChange={(event) => {
                                                console.log("onCheckboxChange");
                                                console.log(event.target);
                                                console.log(event.target.checked);

                                                if (event.target.checked) {
                                                    selectedTerritoryIDs.add(territory["id"]);
                                                    setSelectedTerritoryIDs(Array.from(selectedTerritoryIDs));
                                                } else {
                                                    selectedTerritoryIDs.delete(territory["id"])
                                                    setSelectedTerritoryIDs(Array.from(selectedTerritoryIDs))
                                                }
                                            }} /><FontAwesomeIcon style={{marginLeft:7}} icon={territory.doorToDoor ? faWalking : faEnvelope  } size={territory.doorToDoor ? null : null} /> {territory.territoryName}
{/*territory.doorToDoor && <svg style={{ width: 17, height: 17, marginBottom: 5, marginLeft: 5 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"><path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" /></svg>}{!territory.doorToDoor && <svg style={{ width: 19, height: 19, marginBottom: 5, marginLeft: 5 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-mailbox" viewBox="0 0 16 16"><path d="M4 4a3 3 0 0 0-3 3v6h6V7a3 3 0 0 0-3-3zm0-1h8a4 4 0 0 1 4 4v6a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V7a4 4 0 0 1 4-4zm2.646 1A3.99 3.99 0 0 1 8 7v6h7V7a3 3 0 0 0-3-3H6.646z" /><path d="M11.793 8.5H9v-1h5a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.354-.146l-.853-.854zM5 7c0 .552-.448 0-1 0s-1 .552-1 0a1 1 0 0 1 2 0z" /></svg>*/}
</label><br/><span style={{marginTop:5}}><OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <a onClick={function(){params.setTerritoryMapModalData({show:true,territoryID:territory.id}) }} style={{color:"var(--falcon-link-color)",marginLeft:0, marginRight:5}}>
                                                {/*Perhaps change assignTerritory so you can set selectedPublisher in the query header?*/}
                                                <FontAwesomeIcon icon={faMap} />
                                            </a>
                                        </OverlayTrigger>
                                      
                                      {/*  <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                            <Link to={"/viewTerritory/" + territory.id} style={{color:"var(--falcon-link-color)",marginLeft:8}}>
                                                <FontAwesomeIcon icon={faMap} />
                                            </Link>
                                        </OverlayTrigger>*/}

                                        <span><svg style={{ width: 17, height: 17, marginBottom: 5, marginLeft: 5 }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16"><path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" /></svg>{territory.numberAddresses}</span>  {territory.distanceBetweenHouses && <span> <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faRuler} /> {Math.floor(territory.distanceBetweenHouses)}'</span>}
</span>
                                        
                                    </td>


                                    {/*
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{(territory.checkedOutAt.getMonth() + 1) + "/" + territory.checkedOutAt.getDate() + "/" + territory.checkedOutAt.getFullYear().toString().slice("-2")}</Tooltip>} ><span>{"" + Math.floor((now.getTime() - territory.checkedOutAt.getTime()) / (1000 * 3600 * 24)) + " Days Ago"}</span></OverlayTrigger>
                                    
                                    <td className="mobile-table" data-th="Worked: " style={{ textAlign: "center", fontSize: 14 }}>{!territory.lastWorked && "Never worked"} {"" + Math.floor((now.getTime() - territory.lastWorked.getTime()) / (1000 * 3600 * 24)) + " Days Ago"} {windowDimensions.isMobile == false && <br/>}{(territory.lastWorked.getMonth() + 1) + "/" + territory.lastWorked.getDate() + "/" + territory.lastWorked.getFullYear().toString().slice(-2)}</td>
                                        */}
                                    <td className="mobile-table" data-th="Worked: " style={{ textAlign: "center", fontSize: 14 }}>{!territory.lastWorked && "Never worked"}{territory.lastWorked && <OverlayTrigger placement="top" overlay={<Tooltip>{(territory.lastWorked.getMonth() + 1) + "/" + territory.lastWorked.getDate() + "/" + territory.lastWorked.getFullYear().toString().slice("-2")}</Tooltip>} ><span>{"" + Math.floor((now.getTime() - territory.lastWorked.getTime()) / (1000 * 3600 * 24)) + " Days Ago"}</span></OverlayTrigger>}
                                    </td>

                                    <td className="mobile-table" data-th="Researched: " style={{ textAlign: "center", fontSize: 14 }}>{!territory.lastResearched && "Never Researched"}{territory.lastResearched && <OverlayTrigger placement="top" overlay={<Tooltip>{(territory.lastResearched.getMonth() + 1) + "/" + territory.lastResearched.getDate() + "/" + territory.lastResearched.getFullYear().toString().slice("-2")}</Tooltip>} ><span>{"" + Math.floor((now.getTime() - territory.lastResearched.getTime()) / (1000 * 3600 * 24)) + " Days Ago"}</span></OverlayTrigger>}</td>


                                    <td className="mobile-table" data-th="History: " style={{ textAlign: "center", fontSize: 14 }}>{windowDimensions.isMobile && <br />}{territory.workHistory.map((workHistory, index, arr) => {
                                        return (
                                            <span key={index}>
                                                {index != 0 && !windowDimensions.isMobile && <br />}
                                                <span style={(() => { if (workHistory.publisherID?.replace("usr", "pub") == params.selectedPublisher.id) { return { textDecoration: "underline" } } return {} })()}>{workHistory.publisherName} </span>
                                                {workHistory.checkedIn && <OverlayTrigger placement="top" overlay={<Tooltip>{(workHistory.checkedIn.getMonth() + 1) + "/" + workHistory.checkedIn.getDate() + "/" + workHistory.checkedIn.getFullYear().toString().slice("-2")}</Tooltip>} ><span>{"" + Math.floor((now.getTime() - workHistory.checkedIn.getTime()) / (1000 * 3600 * 24)) + " Days Ago"}</span></OverlayTrigger>}

                                                {!workHistory.checkedIn && (<span>(Never checked in)</span>)}

                                                {workHistory.researched && " R"}
                                                {workHistory.campaign && (<span> {workHistory.campaign}</span>)}
                                                {index != arr.length - 1 && <br />}
                                            </span>
                                        );
                                    })}</td>

                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>

            </div>

            <div className="card-body bg-light" style={{ paddingTop: 0 }}>

                <SearchFooter paginationData={paginationData} paginationReducer={paginationReducer} />

            </div>

            <div className="card-footer">


                <InputGroup className="mb-3">
                    <InputGroup.Checkbox checked={emailEnabled} onChange={() => { setEMailEnabled(!emailEnabled) }} />
                    <FormControl disabled={!emailEnabled} value={email} onChange={(event) => { setEMail(event.target.value) }} placeholder='Publisher Email' />
                </InputGroup>

                <InputGroup className="mb-3">
                    <InputGroup.Checkbox checked={phoneNumberEnabled} onChange={() => { setPhoneNumberEnabled(!phoneNumberEnabled) }} />
                    <FormControl disabled={!phoneNumberEnabled} value={phoneNumber} onChange={(event) => { setPhoneNumber(event.target.value) }} placeholder='Publisher Phone' />
                </InputGroup>

                <button disabled={selectedTerritoryIDs.size == 0 || buttonDisabled } style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} className="btn btn-primary" onClick={async () => {
                    console.log("yo")

                    if (window.navigator.onLine == false){
                        alert("You are not currently connected to the internet.")
                        return
                        }

                        if (buttonDisabled){
                            return
                        }

                        

                    let outputTerritories = []

                    for (let territory of allTerritories) {
                        if (selectedTerritoryIDs.has(territory["id"])) {
                            console.log(territory.territoryName + " was selected.");
                            outputTerritories.push(territory.id) //({id:territory.id,wasResearched:researched,workedCampaign:campaign});
                        }
                    }

                    if (outputTerritories.length >= 1) {

                        if (debounce){
                            return 
                        }
                        debounce = true;
                        setTimeout(function(){debounce = false;},700);


                        let output = { territoryIDs: outputTerritories, publisherID: params.selectedPublisher.id };

                        if (phoneNumberEnabled) {
                            output["phone"] = phoneNumber;
                        }

                        if (emailEnabled) {
                            output["email"] = email;
                        }

                        setButtonDisabled(true);
                        setTimeout(function(){ setButtonDisabled(false) },500);

                        let result = await graphQLClient.mutation(CHECK_OUT_TERRITORIES, output).toPromise();
                        console.log("result");
                        console.log(result)

                        if (result.error){
                            alert(result.error)
                        }


                        //  alert(JSON.stringify(outputTerritories));

                    }

                }}>Assign{(() => { if (selectedTerritoryIDs.size == 0) return ""; if (selectedTerritoryIDs.size == 1) return " 1 Territory"; if (selectedTerritoryIDs.size >= 2) return " " + selectedTerritoryIDs.size + " Territories"; })()}</button>

            </div>
        </div>
    )
}


/*
        sortByOptions:
            [{ label: "Worked", value: "worked", field: "lastWorked" },

            {
                label: "Researched", value: "researched", sortFunction: function (paginationData, inputItems) {
                    let ascModifier = -1
                    if (paginationData.ascending) {
                        ascModifier = 1;
                    }
                    let d = new Date().getTime()
                    return inputItems.sort(function (a, b) {
                        let adays = Math.ceil((a.lastWorked - d) / (2592000000 * 5))
                        let bdays = Math.ceil((b.lastWorked - d) / (2592000000 * 5))

                        if (adays > bdays) {
                            return 1 * ascModifier
                        } else if (adays < bdays) {
                            return -1 * ascModifier
                        }

                        if (!a.lastResearched && !b.lastResearched) {
                            return 0
                        } else if (!a.lastResearched) {
                            return -1 * ascModifier
                        } else if (!b.lastResearched) {
                            return 1 * ascModifier
                        }

                        if (a.lastResearched > b.lastResearched) {
                            return 1 * ascModifier
                        } else if (a.lastResearched < b.lastResearched) {
                            return -1 * ascModifier
                        }
                        return 0;



                    })
                }

            },

            {label: "Worked - Door to Door", value: "worked-door", sortFunction: function (paginationData, inputItems) {
                let ascModifier = -1
                if (paginationData.ascending) {
                    ascModifier = 1;
                }
                let d = new Date().getTime()
                return inputItems.sort(function (a, b) {
                    if (!a.doorToDoor && b.doorToDoor) {
                        return 1
                    } else if (a.doorToDoor && !b.doorToDoor) {
                        return -1
                    }

                    if (a.lastWorked > b.lastWorked){
                        return 1 * ascModifier
                    }else if (b.lastWorked > a.lastWorked){
                        return -1 * ascModifier
                    }
                    return 0

                })}},

                {label: "Worked - Letter Writing", value: "worked-letter", sortFunction: function (paginationData, inputItems) {
                    let ascModifier = 1
                    if (paginationData.ascending) {
                        ascModifier = -1;
                    }
                    let d = new Date().getTime()
                    return inputItems.sort(function (a, b) {
                        if (!a.doorToDoor && b.doorToDoor) {
                            return -1
                        } else if (a.doorToDoor && !b.doorToDoor) {
                            return 1
                        }
    
                        if (a.lastWorked > b.lastWorked){
                            return -1 * ascModifier
                        }else if (b.lastWorked > a.lastWorked){
                            return 1 * ascModifier
                        }
                        return 0
    
                    })}},



            {label: "Researched - Letter Writing", value: "researched-letter", sortFunction: function (paginationData, inputItems) {
                    let ascModifier = -1
                    if (paginationData.ascending) {
                        ascModifier = 1;
                    }
                    let d = new Date().getTime()
                    return inputItems.sort(function (a, b) {
                        if (!a.doorToDoor && b.doorToDoor) {
                            return -1
                        } else if (a.doorToDoor && !b.doorToDoor) {
                            return 1
                        }
                        let adays = Math.ceil((a.lastWorked - d) / (2592000000 * 5))
                        let bdays = Math.ceil((b.lastWorked - d) / (2592000000 * 5))
                        if (adays > bdays) {
                            return 1 * ascModifier
                        } else if (adays < bdays) {
                            return -1 * ascModifier
                        }
                        if (!a.lastResearched && !b.lastResearched) {
                            return 0
                        } else if (!a.lastResearched) {
                            return -1 * ascModifier
                        } else if (!b.lastResearched) {
                            return 1 * ascModifier
                        }
                        if (a.lastResearched > b.lastResearched) {
                            return 1 * ascModifier
                        } else if (a.lastResearched < b.lastResearched) {
                            return -1 * ascModifier
                        }
                        return 0;
                    })
                }
            },

            {label: "Researched - Door to Door", value: "researched-door", sortFunction: function (paginationData, inputItems) {
                let ascModifier = -1
                if (paginationData.ascending) {
                    ascModifier = 1;
                }
                let d = new Date().getTime()
                return inputItems.sort(function (a, b) {
                    if (!a.doorToDoor && b.doorToDoor) {
                        return 1
                    } else if (a.doorToDoor && !b.doorToDoor) {
                        return -1
                    }
                    let adays = Math.ceil((a.lastWorked - d) / (2592000000 * 5))
                    let bdays = Math.ceil((b.lastWorked - d) / (2592000000 * 5))
                    if (adays > bdays) {
                        return 1 * ascModifier
                    } else if (adays < bdays) {
                        return -1 * ascModifier
                    }
                    if (!a.lastResearched && !b.lastResearched) {
                        return 0
                    } else if (!a.lastResearched) {
                        return -1 * ascModifier
                    } else if (!b.lastResearched) {
                        return 1 * ascModifier
                    }
                    if (a.lastResearched > b.lastResearched) {
                        return 1 * ascModifier
                    } else if (a.lastResearched < b.lastResearched) {
                        return -1 * ascModifier
                    }
                    return 0;
                })
            }
        },


            { label: "Name", value: "name", field: "territoryName" },
            { label: "Neighborhood", value: "neighborhood", field: "neighborhood" },
            ],
*/