import { customReactSelectStyle } from "helpers/reactSelectStyle";
import { Address, AddressQueryFields, LiteraturePlacedRecord } from "models/Address"
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import CreatableSelect from "react-select/creatable";
import { gql, useClient } from "urql";

const reactSelectCustomSearch = (candidate: { label: string, value: string }, input: string) => {
    //candidate = { label: candidate.label.toLowerCase(), value: candidate.value.toLowerCase() };
    let l = candidate.label.toLowerCase();
    let inp = input.toLowerCase().split(" ");
    for (let i = 0; i < inp.length; i++) {
        if (!l.includes(inp[i])) {
            return false
        }
    }
    return true;
};

const PLACE_LITERATURE = gql`
    mutation placeLiterature($addressID:ID!,$literature:LiteraturePlacedRecordInput!){
    placeLiterature(
    addressID: $addressID
    literatureRecord: $literature
    ){
    ${AddressQueryFields}
  }
}`

const DEFAULT_LITERATURE_OPTIONS = [
    "Campaign",
    "JW.ORG Tract",
    "Dead Tract",
    "Future Tract",
    "Family Tract",
    "Kingdom Tract",
    "Who Controls World Tract",
    "Suffering Tract",
    "Bible Tract",
    "Meeting Invitation",
    "Contact Card",
    "Watchtower #1 2022",
    "Awake #1 2022",
    "Watchtower #3 2021",
    "Awake #3 2021",
    "Watchtower #2 2021",
    "Awake #2 2021",
    "Watchtower #1 2021",
    "Awake #1 2021",
    "Watchtower #3 2020",
    "Awake #3 2020",
    "Watchtower #2 2020",
    "Awake #2 2020",
    "Watchtower #1 2020",
    "Awake #1 2020",
    "Watchtower #3 2019",
    "Awake #3 2019",
    "Watchtower #2 2019",
    "Awake #2 2019",
    "Watchtower #1 2019",
    "Awake #1 2019",
    "Watchtower #3 2018",
    "Awake #3 2018",
    "Watchtower #2 2018",
    "Awake #2 2018",
    "Watchtower #1 2018",
    "Awake #1 2018",
]



export function PlaceLiteratureModal({ modalState, setModalState}){

    const address = modalState?.address as Address | null

    const graphQLClient = useClient();

    const [newLiteratureOptions, setNewLiteratureOptions] = useState(JSON.parse(window.localStorage.getItem("newLiteratureOptions")) || []);
    const [currentSelectedLiterature, setCurrentSelectedLiterature] = useState("");

return (
    <Modal show={modalState.type} onHide={function () { setModalState({ ...modalState, type: null }) }}>
    <Modal.Header closeButton>
        <Modal.Title>Place Literature - {address?.address}</Modal.Title>
    </Modal.Header>

    {/*address && address.literaturePlaced && address.literaturePlaced.map(function (literature: LiteraturePlacedRecord) {

        return (
            <p>{literature.literature}</p>
        )

    })*/} 

    <Modal.Body>
        <Form.Label>What brochure was sent and or placed?<br />Or, what scripture did you use?</Form.Label>
        <CreatableSelect
            onCreateOption={(option: string) => {
                if (option.length >= 3) {
                    window.localStorage.setItem("newLiteratureOptions", JSON.stringify([...newLiteratureOptions, option]))
                    setNewLiteratureOptions([...newLiteratureOptions, option])
                    setCurrentSelectedLiterature(option)
                }
            }}
            closeMenuOnSelect={true}
            options={[...DEFAULT_LITERATURE_OPTIONS, ...newLiteratureOptions].map(function (l) { return { value: l, label: l } })}
            styles={customReactSelectStyle}
            //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
            isSearchable={true}
            //onCreateOption={handleCreate}
            //menuIsOpen={true}
            placeholder='Select Literature...'
            classNamePrefix="react-select2"
            value={{ label: currentSelectedLiterature, value: currentSelectedLiterature }}
            onChange={function (e) { setCurrentSelectedLiterature(e.value) }}
            menuPlacement="bottom"
            //width="100%"
            filterOption={reactSelectCustomSearch}
        />

    </Modal.Body>

    <Modal.Footer>
        <Button variant="secondary" onClick={function () { setModalState({ ...modalState, type: null }) }}>Cancel</Button>
        <Button disabled={!currentSelectedLiterature} variant="primary" onClick={async function () { 
            console.log("submitted");
            console.log(currentSelectedLiterature);

            if (window.navigator.onLine == false){
                alert("You are not currently connected to the internet.")
                return
                }
            
            graphQLClient.mutation(PLACE_LITERATURE, 
                { addressID:address.id,
                    literature:{
                        literature:currentSelectedLiterature,
                    }
                }
            ).toPromise().then(function(result){
                console.log("RESULT");
                console.log(result)
            })

            
            
            
            setModalState({ ...modalState, type: null }) 
    }}>Submit</Button>
    </Modal.Footer>
</Modal>
)

        }

