import React, { useContext } from 'react';
import PropTypes from 'prop-types';
//import { Link } from 'react-router-dom';
//import { toast } from 'react-toastify';
//import { Button, Form, Row, Col } from 'react-bootstrap';
import { Form, Row, Col } from 'react-bootstrap';
import Divider from 'components/common/Divider';
import SocialAuthButtons from "./SocialAuthButtons" //'newComponents/SocialAuthButtons';
//import Flex from 'components/common/Flex';
import { Formik, ErrorMessage } from 'formik';
import { setPersistence, signInWithEmailAndPassword, browserSessionPersistence, browserLocalPersistence} from "firebase/auth";//browserSessionPersistence, inMemoryPersistence,browserLocalPersistence
import * as Yup from 'yup';
import AuthContext from "newComponents/AuthContext";

import { getAuth } from "firebase/auth";

        /* validate={values => {//Validation
      console.log("IS VALIDDING")
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
    
    if (!values.password){
        errors.password = 'Required';
    } else if (values.password.length < 3){
        errors.password = 'Password too short';
    }
    
         return errors;
       }}//End validation*/

 const LoginSchema = Yup.object().shape({
     email: Yup.string().email("Invalid email").required("Required"),
     password: Yup.string().min(2, "Too short").required("Required")
 });

const LoginForm = ({ currentTab,setCurrentTab, hasLabel//, layout
                   }) => {
    let authContext = useContext(AuthContext);
    
    return (
        <>
    <Row className="align-items-center mb-2">
      {/*<Col style={{"display":"none"}}>
        <h5 id="modalLabel">Register</h5>
      </Col>//The header inside the form itself. Not needed right now because modal has the same header right above it.*/}
      <Col xs="auto" style={{"marginLeft":"auto"}}>
        <p className="fs--1 text-600 mb-0">
          or <a onClick={()=>{setCurrentTab("Register"); //authContext.changeAuthState({modalTab:"Register"})
          }
        } href="#/">Create an account</a>
        </p>
      </Col>
    </Row>
<Formik
      initialStatus="Submit"//What the button will say by default.
        initialValues={{ email: '', password: '', rememberMe:true }}

validationSchema={LoginSchema}

       
onSubmit= {(values, { setErrors,setStatus,setSubmitting }) => {//onSubmit
            console.log("seterrors");
            console.log(setErrors);
            console.log(setStatus);
    let pers = browserLocalPersistence;
if (values.rememberMe == false){
    console.log("browsersessionpersistence");
    pers = browserSessionPersistence;
}
setPersistence(getAuth(), pers)
  .then(() => {
    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    // ...
    // New sign-in will be persisted with session persistence.
    return signInWithEmailAndPassword(getAuth(), values.email, values.password);
  })
  .catch((error) => {
    setSubmitting(false);
    //const errorCode = error.code;
    //const errorMessage = error.message;
             //  console.log("errorCode");
               console.log(error.code);
             //  alert(errorMessage);
               
let code = error.code.replace("auth/","").replaceAll("-"," ");
const words = code.split(" ");
for (let i = 0; i < words.length; i++) {
words[i] = words[i][0].toUpperCase() + words[i].substr(1);
}
code = words.join(" ");

if (code == "User Not Found"){
  code = "Email Not Registered"
}

setStatus(code);
});
                                                                      
       }}//End on submit
       
     >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
         values,
         errors,
         touched,
       //isValid,
       status,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         // and other goodies 
       }) => (
         <Form noValidate onSubmit={handleSubmit}>
           
                      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          name="email"
           onChange={handleChange}
             onBlur={handleBlur}
             value={values.email}
           isInvalid={touched.email && errors.email}
          type="email"
        />
           <span className="invalid-feedback"><ErrorMessage name="email" /></span>
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          name="password"
           onChange={handleChange}
             onBlur={handleBlur}
             value={values.password}
           isInvalid={touched.password && errors.password}
          type="password"
        />
           <span className="invalid-feedback"><ErrorMessage name="password" /></span>
      </Form.Group>
          
                 <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="Somerandomidisneededapparentlyforthelabeltowork">
            <Form.Check.Input
              type="checkbox"
              name="rememberMe"
              checked={values.rememberMe}
           onChange={handleChange}
             onBlur={handleBlur}
            />
            <Form.Check.Label className="mb-0">Remember Me</Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
<a className="fs--1 mb-0" onClick={()=>{setCurrentTab("ForgotPassword") //authContext.changeAuthState({modalTab:"ForgotPassword"})
}} href="#/" >Forget Password?</a>
        </Col>
      </Row>
           
           <button type="submit" disabled={(()=>{
           if (isSubmitting){return true} 
           if (Object.keys(errors).length >= 1){return true} 
           if (Object.keys(touched).length == 0){return true} 
           return false; })() }
          
          className={ (()=>{if (status=='Submit'){return 'mt-3 w-100 btn btn-primary'} return 'mt-3 w-100 btn btn-danger' })()}
           >{status}</button>
         </Form>

       )}
     </Formik>


      <Divider className="mt-4">or log in with</Divider>

      <SocialAuthButtons />
            </>
    );
};

LoginForm.propTypes = {
    layout: PropTypes.string,
    hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
    layout: 'simple',
    hasLabel: false
};

export default LoginForm;
