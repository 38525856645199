import React, {useContext} from 'react';
//import WeeklySales from './WeeklySales';
import { Row, Col, Form } from 'react-bootstrap';
import {
  marketShare,
 // totalOrder,
  //totalSales,
  //weeklySalesData,
  weather//,
  //product-,
  //storageStatus,
  //files,
  //users,
  //topProducts,
  //runningProjects
} from 'data/dashboard/default';



//import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
//import TotalSales from './TotalSales';
//import RunningProjects from './RunningProjects';
//import StorageStatus from './StorageStatus';
//import SpaceWarning from './SpaceWarning';
//import BestSellingProducts from './BestSellingProducts';
//import SharedFiles from './SharedFiles';
//import ActiveUsers from './ActiveUsers';
//import BandwidthSaved from './BandwidthSaved';
//import TopProducts from './TopProducts';
import Weather from './Weather';

import AuthContext from "newComponents/AuthContext";

import {useQuery, gql, useMutation} from '@apollo/client';
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import PageLoadSpinner from 'newComponents/PageLoadSpinner';

//import terrdata from "allterr.json" assert{type: 'json'};
//import { assert } from '@firebase/util';

//import AppContext from 'context/Context';

/*
var GET_LOCATIONS = gql`
  query GetLocations {
    locations {
      id
      name
      description
      photo
    }
  }
`;

GET_LOCATIONS = gql`
{me{
user{
id
name
email
}
refreshToken
}}
`

GET_LOCATIONS = gql`
query wut($userID:String){ 
hello(who:$userID)
}

`



GET_LOCATIONS = gql`
query what($userID:ID!){
getUser(id:$userID){
id
name
version
email
},
me{
refreshToken
user{
id
name
email
}
},
hello(who:"sup")
}
`

GET_LOCATIONS = gql`
query what{  
hello
}
`*/


const GET_LOCATIONS = gql`
query query_name{

getAddresses{
  id
  congregationid
  address
  territoryid
  territoryName
  addressNumber
  gatedCommunity
  streetName
  coordinates{
    coordinates
  }
  parcel{
    coordinates
  }
   houseHolders{
    firstName
    lastName
    age
    religion
    phone
   }
  postalCode
  city
  county
  country
  customid1
  updaterid
  updaterName
  createdAt
  updatedAt
  version
}
}
`

const createTerritory = gql`
mutation qt($ti :TerritoryInput){
  createTerritory(TerritoryInput:$ti){
    id
    territoryName
  }
}
`
    
const Dashboard = () => {



      const { loading, error, data } = useQuery(GET_LOCATIONS,{
    variables: {
   //   "userID": "usr_SFD234FDSFDDSGF"
    }
  });

    const authContext = useContext(AuthContext);
    
    console.log("CONSUMING AUTH CONTEXT");
    console.log(authContext);

    
    
  if (loading){ return (
    <div className="card">
      <BreadCrumbHeader data={{routes:["View All Addresses"]}}/> 

<div className="card-body" style={{minHeight:180}}>
      {<p>Loading...</p>}
      <PageLoadSpinner opacity={1}/>
      </div></div>
  )
              }

let out = data;

  if (error){
      console.log("errin");
      console.log(error);
      out = error;
     // return (<p>Error {JSON.stringify(error)}</p>
   // )
        }
    console.log("data");
      console.log(loading);
        console.log(data);
      console.log(error);
              
  return (
    <div className="card">
      <BreadCrumbHeader data={{routes:["View All Addresses"]}}/> 

<div className="card-body" style={{minHeight:180}}>
<PageLoadSpinner opacity={0}/>
<p style={{"overflowWrap":"anywhere"}}>{JSON.stringify(out)}</p>
      </div></div>
  );
};

export default Dashboard;
