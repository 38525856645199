import { authExchange } from "@urql/exchange-auth";
import { retryExchange } from "@urql/exchange-retry";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, onIdTokenChanged, signInWithRedirect, signInWithPopup } from "firebase/auth";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { createClient, debugExchange, dedupExchange, errorExchange, fetchExchange, Provider as URQLProvider } from "urql";
import { firebaseConfig, makeURQLGraphCache, scalarsExchange, urqlAuthedURL, urqlGuestURL, urqlRetryOptions } from "./Config";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
import { JsxElement } from "typescript";
import { GET_OWN_TERRITORIES } from "App";
import { HashRouter } from "react-router-dom";
import { LoadingSpinner } from "newComponents/LoadingSpinner";
import useWindowDimensions from "helpers/useWindowDimensions";

var currentToken: string = "uninitialized";
var currentTokenExpires = 0;

export var CURRENTAUTH:AuthDataClass | null = null;

var LOGGINGOUT = false;
var globalInitialLoad = false;

function checkHasPermission({ resolver }: { resolver: number | number[] }) {
    if (!this.currentDomainPermissions){
        return false;
    }
    if (typeof resolver == "number") {
        resolver = [resolver];
    }
    for (let r of resolver) {
        if (this.currentDomainPermissions?.has("" + r)) {
            return true;
        }
    }
    return false;
}

const makeNewURQLClient = function (invalidate?: boolean) {
    return createClient({
        url: urqlGuestURL,
        exchanges: [
            dedupExchange,
            scalarsExchange,
            //noopExchange,
            //testExchange,
            makeURQLGraphCache(invalidate),
            // noopExchange,
            debugExchange,
            errorExchange({
                onError(error) {
                    console.log("URQL GLOBAL ERROR");
                    console.error(error);
                },
            }),

            retryExchange(urqlRetryOptions),
            authExchange({
                getAuth: async function (oldtoken) {

                    console.log("GETAUTH RUNNING");

                    while (currentToken == "uninitialized") {
                        console.log("GETAUTH AWAITING UNINITIALIZED");
                        await new Promise(r => setTimeout(r, 30));
                    }

                    if (currentToken == "unauthed") {
                        console.log("GETUAUTH UNAUTHED");
                        while (auth.currentUser == null) {
                            await new Promise(r => setTimeout(r, 30));
                        }
                        console.log("GETUAUTH NOT UNAUTHED");


                        let t = await auth.currentUser.getIdTokenResult(true);
                        console.log("GETAUTH AFTER AWAIT GETIDTOKEN");
                        console.log(new Date());
                    
                        if (!LOGGINGOUT){
                        currentToken = t.token;
                        currentTokenExpires = t.claims.exp * 1000;
                        }
                        return { token: t.token, exp: (t.claims.exp*1000)  };
                    }

                    console.log("GETAUTH NOT UNAUTHED");
                    console.log(currentToken)

                    if (currentTokenExpires < new Date().getTime() - 5000 && auth.currentUser != null) {
                        console.log("GETAUTH 1");
                        console.log("CURRENTTOKEN EXPIRING, REFRESHING IT 1");
                        let t = await auth.currentUser.getIdTokenResult(true);
                        if (!LOGGINGOUT){
                            currentToken = t.token;
                            currentTokenExpires = t.claims.exp * 1000;
                            }
                        return { token: t.token, exp:t.claims.exp };
                    } else if (currentTokenExpires < new Date().getTime() - 600000 && auth.currentUser != null) {
                        console.log("GETAUTH 2");
                        console.log("CURRENTTOKEN EXPIRING, REFRESHING IT 2");
                        auth.currentUser.getIdToken(true);
                    }

                    console.log("GETAUTH 3");

                    return { token: currentToken, exp:currentTokenExpires };
                },
                willAuthError: function ({ authState }) {
                    console.log("WILLAUTHERROR URQL")
                    console.log(authState);
                    console.log(currentTokenExpires)
                    console.log(new Date().getTime() - 60000)
                    if (currentToken == "unauthed") {
                        return true;
                    }

                    if (currentToken == "uninitialized") {
                        return true;
                    }

                    //if (authState.token && authState.token != currentToken) return true;

                    if (currentTokenExpires < new Date().getTime() - 60000) return true;//Tells it to getAuth//Also set it to check if token is expired.
                    return false;//Tells it to resume
                },
                addAuthToOperation: function ({
                    authState,
                    operation,
                }) {

                    console.log("ADD AUTH TO OPERATION");
                    console.log(authState);
                    console.log(operation);

                    // the token isn't in the auth state, return the operation without changes
                    //if (!authState || !authState.token) {
                      //  return operation;
                    //}

                    if (!currentToken || currentToken == "unauthed" || currentToken == "uninitialized"){
                        return operation
                    }

                    // fetchOptions can be a function (See Client API) but you can simplify this based on usage
                    const fetchOptions =
                        typeof operation.context.fetchOptions === 'function'
                            ? operation.context.fetchOptions()
                            : operation.context.fetchOptions || {};

                    return {
                        ...operation,
                        context: {
                            ...operation.context,
                            url: urqlAuthedURL,
                            fetchOptions: {
                                ...fetchOptions,
                                headers: {
                                    ...fetchOptions.headers,
                                    "authToken": currentToken //authState.token,
                                },
                            },
                        },
                    };
                }
            }),
            fetchExchange
        ],
    })
}

var newURQLClient = makeNewURQLClient();

export async function waitInitCache(num = 0){

if (num >= 15){
    initialLoadSetter(true);
    globalInitialLoad = true;
}

if (globalInitialLoad){
    console.log("globalinitlod true");
    return;
}

setTimeout(()=>{waitInitCache(num + 1)},300);

    console.log("AT WAITINIT CACHE");
    let ack = await newURQLClient.query(GET_OWN_TERRITORIES, {
        congregationID:"1",
},{requestPolicy:"cache-only"} )
      .toPromise()

      if (ack.data){
      initialLoadSetter(true);
      globalInitialLoad = true;
      }

      //initialLoad = true;

      console.log("AT WAITINIT CACHE RESULT");
      console.log(ack);

}





export class AuthDataClass {//This data needs to be able to be jsonified...
    isAuthed: boolean = false;

    domain: string = "";
    currentDomain: string = "";
    currentDomainName: string = "";

    currentDomainPermissions?: Set<string> = new Set();

    domainUsers: Record<string, Object> = {};

    globalUser: Object | null = this.domainUsers["default"];
    currentDomainUser: Object | null = this.domainUsers[this.currentDomain];
    currentUser: Object | null = this.currentDomainUser || this.globalUser;

    picture: string = "";
    emailVerified = false;

    firebaseEmail: string = "";
    firebasePhone: string = "";
    firebaseUserID: string = "";
    firebasePicture: string = "";

    //firebase data...
    //firebaseEmail   and email_verified
    //firebasePhone
    //firebaseProviders/identities. wwb940@gmail.com on google.com
    //email and email_verified
    //firebase user_id
    //firebase picture

    //I don't want to provide a user until email is verified. In this instance.
    //Maybe work in sign in blocks and crap? In this instance?

    checkHasPermission=checkHasPermission

    dispatchAuthData:any

}

const saveAuthToStorage = function (authData: AuthDataClass) {
    console.log("saveAuthToStorage");
    let savedAuthData = { ...authData }
    savedAuthData.currentDomainUser = null;
    savedAuthData.currentUser = null;
    savedAuthData.globalUser = null;
    delete savedAuthData.currentDomainPermissions;

    window.localStorage.setItem("authData", JSON.stringify(savedAuthData));
}

function LOGOUT(){

    let dbname = "GraphCache" //window.localStorage.getItem("GraphCacheDBName")
            
    /*const DBDeleteRequest = window.indexedDB.deleteDatabase(dbname);

    console.log(DBDeleteRequest);
console.log("DELETE REQUEST");

    DBDeleteRequest.onerror = (event) => {
      console.error("DELETE REQUEST ERROR");
    };
    
    DBDeleteRequest.onsuccess = (event) => {
        console.error("DELETE REQUEST SUCCESS");
    */
      currentToken = "unauthed";
      currentTokenExpires = 0;
      window.localStorage.removeItem("authData");
      window.localStorage.removeItem("firebaseAuthCache");
      window.localStorage.removeItem("GraphCacheDBName");
      //newURQLClient = makeNewURQLClient(true);
      window.localStorage.setItem("logOutClearCache","true");
      auth.signOut().then(function () {

          //window.localStorage.setItem("authData", JSON.stringify(new AuthDataClass()));
          window.localStorage.removeItem("authData");
          //window.localStorage.removeItem("GraphCacheDBName");
          location.reload();
      })
    //}

}

function calculateDomainPermissions(resolvers) {
    let s = new Set()
    for (let r of resolvers) {
        s.add("" + r);
    }
    return s
    /*
    let r = new Set(roles);

    let output = new Set();

    if (r.has(RolesEnum.DEVELOPER)) {
        output.add("uploadTerritoryJSON")
    }

    if (r.has(RolesEnum.ADMIN)) {
        output.add("Admin");
    }

    if (r.has(RolesEnum.ADMIN) || r.has(RolesEnum.TERRITORY_SERVANT) || r.has(RolesEnum.SERVICE_OVERSEER)) {
        output.add("assignTerritory")
        output.add("drawTerritory")
        output.add("viewPublishers")
        output.add("createPublisher")
    }
*/
}

const AuthReducer = function (authData: AuthDataClass, action: { type: "refresh" | "updateToken" | "logOut" | "loadFromStorage" | "signInGoogle" | "updateUserList", value: any }): AuthDataClass {
    console.log("authReducer");
    console.log(authData);
    console.log(action);

    if (LOGGINGOUT) {
        return authData;
    }

    switch (action.type) {

        case 'refresh': {
            if (auth.currentUser) {
                auth.currentUser.getIdToken(true);
            }
            return authData;
        }
        case 'signInGoogle': {
            signInWithPopup(auth,googleProvider);
            //signInWithRedirect(auth, googleProvider);
            return authData;
        }

        case 'updateUserList': {
            console.log("UUL1");
            console.log("unmutted");
            console.log([...action.value])
            let newAuthData = { ...authData };
            let changed = false;

            for (let user of action.value) {
                console.log("UUL2");
                console.log(user);

                if (!user.congregationID) {
                    user.congregationID = "default";
                }
                user.domain = user.congregationID;

                newAuthData.domainUsers[user.congregationID] = user;
                changed = true;
            }

            console.log("UUL3");

            if ("default" in newAuthData.domainUsers) {
                console.log("UUL4");
                newAuthData.globalUser = newAuthData.domainUsers["default"]
                newAuthData.currentUser = newAuthData.domainUsers["default"];
            } else {
                console.log("UUL5");
                newAuthData.globalUser = null;
                newAuthData.currentUser = null;
            }

            newAuthData.currentDomainUser = null;
            newAuthData.currentDomain = "";

            for (const [key, value] of Object.entries(newAuthData.domainUsers)) {
                if (key != "default") {
                    console.log("UUL6");
                    newAuthData.currentDomainUser = value;
                    newAuthData.currentDomain = value.congregationID
                    newAuthData.currentDomainName = "West Spruce Creek";
                    newAuthData.currentUser = value;
                }
            }

            console.log("UUL7");

            if (!changed || JSON.stringify(authData) == JSON.stringify(newAuthData) ) {
                return authData
            }
            if (auth.currentUser) {
                auth.currentUser.getIdToken(true);
            }

            saveAuthToStorage(newAuthData);

            if (newAuthData.currentDomainUser && newAuthData.currentDomainUser.resolvers) {
                newAuthData.currentDomainPermissions = calculateDomainPermissions(newAuthData.currentDomainUser.resolvers);
            }

            return newAuthData;
        }

        case 'updateToken': {
            let newAuthData = { ...authData };
            let changed = false;

            console.log("at updateToken");
            console.log(action.value);
            console.log(authData);

            let idToken = action.value;

            if (authData.firebaseUserID != idToken.claims.sub) {
                changed = true;
                newAuthData.firebaseUserID = idToken.claims.sub;
            }

            if (authData.isAuthed == false) {
                changed = true;
                newAuthData.isAuthed = true;
            }

            if (authData.firebaseEmail || null != idToken.claims.email) {
                changed = true;
                newAuthData.firebaseEmail = idToken.claims.email;
            }

            if (authData.firebasePicture || null != idToken.claims.picture) {
                changed = true;
                newAuthData.firebasePicture = idToken.claims.picture;
                newAuthData.picture = idToken.claims.picture;
            }

            if (authData.emailVerified != idToken.claims.email_verified) {
                changed = true;
                newAuthData.emailVerified = idToken.claims.email_verified;
            }

            //Now... If globalUser does not exist... Call a request to create one. And cancel this u

            //   if (!idToken.claims.userID) {
            //       currentToken = "unauthed";
            //       return authData
            //   }

            currentToken = idToken.token
            currentTokenExpires = idToken.claims.exp * 1000

            console.log("currentTokenExpires");
            console.log(currentTokenExpires)

            //Now, check if globalUser exists, if not, send a request for one... Have a callback that calls a reducer to add that user data?

            window.localStorage.setItem("firebaseAuthCache",JSON.stringify(idToken) )

            if (changed == false) {
                return authData
            }

            if (newAuthData.currentDomainUser && newAuthData.currentDomainUser.resolvers) {
                newAuthData.currentDomainPermissions = calculateDomainPermissions(newAuthData.currentDomainUser.resolvers);
            }

            if (JSON.stringify(newAuthData) == JSON.stringify(authData)){
                return authData
            }

            saveAuthToStorage(newAuthData);

            return newAuthData;
        }
        case "logOut": {
            LOGGINGOUT = true;
            let newAuthData = new AuthDataClass();

            //if (JSON.stringify(newAuthData) != JSON.stringify(authData)) {
            //saveAuthToStorage(newAuthData);

            currentToken = "unauthed";
            currentTokenExpires = 0;
            window.localStorage.removeItem("authData");
            window.localStorage.removeItem("GraphCacheDBName");
            //newURQLClient = makeNewURQLClient(true);
            window.localStorage.setItem("logOutClearCache","true");
            let DBDeleteRequest = window.indexedDB.deleteDatabase("GraphCache");
            auth.signOut().then(function () {
                 DBDeleteRequest = window.indexedDB.deleteDatabase("GraphCache");
      
                //window.localStorage.setItem("authData", JSON.stringify(new AuthDataClass()));
                window.localStorage.removeItem("authData");
                //window.location.href = "/logout.html";
                window.localStorage.removeItem("GraphCacheDBName");
                
                setTimeout(function(){window.localStorage.removeItem("authData"); location.reload(); window.location.href="/"},200)
                
            })

            //let gcdbn = window.localStorage.getItem("GraphCacheDBName")
//           LOGOUT();

                  //console.log(event.result); // should be undefined
                
            
                


              /*  try{
                const request = indexedDB.open("GraphCache", 1);
                request.onsuccess = (event) => {
                    console.log("REQUEST ONSUCCESS");
                  const db = request.result;
                  console.log("PRE DELETE");
                    db.deleteObjectStore("entries");
                    db.deleteObjectStore("metadata");
                    console.log("POST DELETE");
                  }
                }catch(e){
                    console.log("TRANSACTION ERROR WHEN DELETING IDB")
                    console.log(e);
                }*/
                
                  // etc. for version < 3, 4…
                //};
            
            
                /*const DBOpenRequest = window.indexedDB.open(dbname, 4);
            
            DBOpenRequest.onsuccess = (event) => {
                let db = DBOpenRequest.result;
                let transaction = db.transaction(["toDoList"], "versionchange");
                // Add the data to the database
                //addData();*/
          //    };
        //}




            //         saveAuthToStorage(new AuthDataClass();  });
            //     currentToken = "unauthed";
            //     currentTokenExpires = 0;
            //     newURQLClient = makeNewURQLClient(true);
            return newAuthData;
            //}
            //   auth.signOut().then(function () {  });
            // return authData;
        }
        case "loadFromStorage": {
            let newAuthData = new AuthDataClass();

            Object.assign(newAuthData, action.value);

            newAuthData.currentDomainUser = newAuthData.domainUsers[newAuthData.currentDomain];
            newAuthData.globalUser = newAuthData.domainUsers["default"];
            newAuthData.currentUser = newAuthData.currentDomainUser || newAuthData.globalUser

            console.log("AT LOAD FROM STORAGE")
            if (JSON.stringify(newAuthData) != JSON.stringify(authData)) {
console.log("LOAD FROM STORAGE 1")
                if (newAuthData.currentDomainUser && newAuthData.currentDomainUser.resolvers) {
                    console.log("LOAD FROM STORAGE 2")
                    newAuthData.currentDomainPermissions = calculateDomainPermissions(newAuthData.currentDomainUser.resolvers);
                    console.log("LOAD FROM STORAGE 3")
                    console.log(newAuthData.currentDomainPermissions);
                }

                return newAuthData;
            }
            return authData;
        }

        default: {
            throw Error('Unknown action: ' + action.type);

            return authData
        }
    }

    //return new AuthDataClass();
}


export const AuthContext = createContext<AuthDataClass | null>(null); //window.localStorage.getItem("AUTH") || {});//Can set defaultValue apparently?

/*
CASES:

User comes on, has never been on before.
    In which case, localStorage will be null. Create a blank authData

User comes on, has been on before.
    In which case, localStorage won't be null. Create a authData from it.
*/



function authDataInit(blankAuthData: AuthDataClass): AuthDataClass {
    console.log("authDataInit");

    let authDataStorage = window.localStorage.getItem("authData");
    if (authDataStorage == null) {
        console.log("authDataStorage == null");
        currentToken = "unauthed";

        setTimeout(()=>{
            initialLoadSetter(true);
            globalInitialLoad = true;
        },50)


        return blankAuthData
    }

    waitInitCache();

    console.log("authDataStorage is not null, loading auth from storage");
    return AuthReducer(blankAuthData, { type: "loadFromStorage", value: JSON.parse(authDataStorage) });
}

var initialLoadSetter;

export const AuthenticationWrapper = (params) => {

    const [initialLoad,setInitialLoad] = useState(false);
    initialLoadSetter = setInitialLoad;

    const [authData, dispatchAuthData] = useReducer(AuthReducer, new AuthDataClass(), authDataInit);

    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        console.log("AuthWrapper useEffect");
        onIdTokenChanged(auth, async (user) => {

            if (LOGGINGOUT) { return };

            console.log("onIDTokenChanged")
            if (user) {

                let idToken = await user.getIdTokenResult(false);

                console.log("user exists");
                console.log(user)
                console.log(idToken);

                if (!LOGGINGOUT){
                    currentToken = idToken.token;
                    currentTokenExpires = idToken.claims.exp * 1000;
                    }


                dispatchAuthData({ type: "updateToken", value: idToken })

                //user.getIdTokenResult(refreshTheToken).then(function (idToken) {
            } else {
                console.log("User does not have a token");
                //User not logged in...
                //I don't necessarily want this to mean we're not going to keep our state. Since this can happen if offline. Only log out by default on startup. And if logout is called on reducer.
                currentToken = "unauthed";
                currentTokenExpires = 0;

                console.log({...authData});
                if (authData.currentUser && window.navigator.onLine || (authData.isAuthed && !authData.currentUser)) {
                    console.log("LOGGING OUT");
                dispatchAuthData({type:"logOut"});
                }

            }
        })

        let cachedToken = window.localStorage.getItem("firebaseAuthCache");
        if (cachedToken){
            console.log("CACHED TOKEN");
            dispatchAuthData({ type: "updateToken", value: JSON.parse(cachedToken) });
        }
    }, [])
    //<AuthContext.Provider value={{ ...authData, changeAuthState }}>

    console.log("Authenticatio before returning");
    console.log(authData);
    console.log(dispatchAuthData);

    if (LOGGINGOUT || initialLoad == false) {
        console.log("INITIALLOAD LOAD");
        return (
        <HashRouter basename={process.env.PUBLIC_URL}><div style={{position:"absolute",left:"50%",top:windowDimensions.height/2,transform:"translate(-50%, -50%)"}}><LoadingSpinner/></div></HashRouter> 
        )
    }

    CURRENTAUTH = authData;

    return (
        <AuthContext.Provider value={{ ...authData, dispatchAuthData: dispatchAuthData,     checkHasPermission }}
        >
            <URQLProvider value={newURQLClient}>
                {params.children}
            </URQLProvider>
        </AuthContext.Provider>
    )

}