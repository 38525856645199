//leaflet-control-layers-list paddingTop paddingBottom : 5

//CONSIDER: Hold down to search for a lot... Is that possible?

import { AddressQueryFields } from 'models/Address';
import BreadCrumbHeader from 'newComponents/BreadCrumbHeader';
import React from 'react';

import { useParams } from 'react-router-dom';
import EditTerritoryWorkHistory from './EditTerritoryWorkHistory';

  const EditTerritoryWorkHistoryPage = () => {

    const { territoryID } = useParams();

    return (
      <div className="card">
         <BreadCrumbHeader/>
        <div className = "card-body" style={{paddingTop:0}}>
        <EditTerritoryWorkHistory territoryID={territoryID}/>
        </div>
      </div>
    )

  }

  export default EditTerritoryWorkHistoryPage;