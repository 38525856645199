import graphqlschema from '../schema.json';
import customScalarsExchange from "urql-custom-scalars-exchange";
import Big from "big.js";
import { gql } from 'urql';
import { GET_PUBLISHER_CACHE, PublisherQueryFields } from 'models/Publisher';
import { AddressQueryFields, GET_ADDRESS_CACHE, LiteraturePlacedRecord } from 'models/Address';
//import { makeDefaultStorage } from './makeDefaultStorage'  //'@urql/exchange-graphcache/default-storage';
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';
import { offlineExchange } from '@urql/exchange-graphcache';
import { GET_TERRITORY_CACHE, TerritoryQueryFields } from 'models/Territory';
import { CURRENTAUTH } from './Authentication';
import { DoorToDoorRecordQueryFields, DTDAction } from 'models/DoorToDoorRecord';

export const firebaseConfig = {
  apiKey: "AIzaSyC7NWyo-jq_uBOdBf7dEO14WjqPXaIAQu0",
  authDomain: "login.westsprucecreekdatabase.org",
  //authDomain: "territorydb.westsprucecreekdatabase.org",
  //authDomain: "territorydb-e409e.firebaseapp.com",
  projectId: "territorydb-e409e",
  storageBucket: "territorydb-e409e.appspot.com",
  messagingSenderId: "456406558268",
  appId: "1:456406558268:web:d28a991dbdcd8fe6104539"
};

/*firebaseConfig = {
  apiKey: "AIzaSyC7NWyo-jq_uBOdBf7dEO14WjqPXaIAQu0",
  //authDomain: "territorydb-e409e.firebaseapp.com",
  projectId: "territorydb-e409e",
  storageBucket: "territorydb-e409e.appspot.com",
  messagingSenderId: "456406558268",
  appId: "1:456406558268:web:d28a991dbdcd8fe6104539"
};*/

export const urqlRetryOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: err => err && err.networkError && window.navigator.onLine,
}

export const enum ResolversEnum{

  //Territory Resolvers
      getOwnTerritories=1,
      getTerritoryByID=2,
      getPublishersTerritories=3,
      createTerritory=4,
      editTerritory=5,
      getAllTerritories=6,
      checkInTerritories=7,
      checkOutTerritories=8,
  
  //Address Resolvers
      getAddressByID=20,
      changeAddressTerritoryID=21,
      getAllAddresses=22,
      markDoNotCall=23,
      removeDoNotCall=24,
      researchAddress=25,
      placeLiterature=26,
      workDoor=27,
      workAnyDoor=28,
  
  //Publisher resolvers
      getAllPublishers=41,
      getPublisherByID=42,
      createPublisher=43,
      editPublisher=44,
      editPublishersRoles=45,
      createElevatedRole=46,
      getSelf=47,
      isAdmin=48,
  
      //Developer Resolvers
      getUserByID=200,
      getAllUsers=201,
      developmentOnly=202,
  
  }


export const enum RolesEnum {
  ATTENDEE = 1,//For congregation members who are not publishers.//For other record keeping purposes.//For notifications of meeting schedules.
  PUBLISHER = 2,//Base publisher should be able to make do not calls?
  RESEARCHER = 3,

  ADMIN = 10,//A responsible, tech savvy brother responsible for the setting up and configuration of the system within the congregation. May or may not have elder roles aswell.
  SERVICE_OVERSEER = 11,
  TERRITORY_SERVANT = 12,

  DEVELOPER = 137
}

var urlprefix = 'https://axtb75xfl8.execute-api.us-east-1.amazonaws.com/dev/';

console.log("hostname");
console.log(window.location.hostname);
if (window.location.hostname.includes("localhost")){
  urlprefix = 'https://lrtiwi0blh.execute-api.us-east-1.amazonaws.com/';
}

export const urqlAuthedURL = urlprefix + 'graphql'
export const urqlGuestURL = urlprefix + 'graphqlGuest'

export const scalarsExchange = customScalarsExchange({
  schema: graphqlschema,
  scalars: {
    GeoJSON(value) {
      return JSON.parse(value);
    },
    Date(value) {
      return new Date(Number("" + value));
    },
    Decimal(value) {
      return new Big(value);
    },
  },
});

export const makeURQLGraphCache = function (invalidate?: boolean) {

  let dbname = window.localStorage.getItem("GraphCacheDBName")
  if (dbname == null){
    dbname = "GraphCache-" + new Date().getTime();
    window.localStorage.setItem("GraphCacheDBName", dbname);
  }
  
  /*if (dbname == null) {
    dbname = "GraphCache-0"
    window.localStorage.setItem("GraphCacheDBName", dbname);
  }else if (invalidate){

if (dbname == "GraphCache-0"){
  dbname = "GraphCache-1";
  window.localStorage.setItem("GraphCacheDBName", dbname);
} else if (dbname == "GraphCache-1"){
  dbname = "GraphCache-0";
  window.localStorage.setItem("GraphCacheDBName", dbname);
}

  }*/

  return offlineExchange({
    schema: graphqlschema,
    storage: makeDefaultStorage({
      idbName: dbname, // The name of the IndexedDB database
      maxAge: 90, // The maximum age of the persisted data in days
    }),

    updates: {
      Mutation: {
        updateAddressCache: (result, args, cache, info) => {
          console.log("updateAddressCache updater");


          if (!result || result.length == 0) {
            return
          }

          console.log(result);
          console.log({ ...result })
          console.log(args);
          console.log(cache);
          console.log(info);

          let cacheUpd = cache.updateQuery({
            query: GET_ADDRESS_CACHE,
            variables: { congregationID: "1" }
          }, (oldData) => {
            //          return oldData;
            if (oldData == null) {
              // console.log("old data null");
              oldData = { getAddressCache: [] }
            }

            // console.log("oldData");
            //   console.log(oldData);

            let data = oldData.getAddressCache;

            if (result.updateAddressCache) {
              let oldTerrs = new Set()

              for (let i = 0; i < data.length; i++) {
                //console.log("ns0.2");
                oldTerrs.add(data[i].id);
              }
              //console.log("ns1");
              for (let i = 0; i < result.updateAddressCache.length; i++) {
                //console.log("ns2");
                let newTerr = result.updateAddressCache[i];
                if (oldTerrs.has(newTerr.id) == false) {
                  console.log("NEW ADDRESS");
                  //inserts.push(newTerr);
                  data.push(newTerr);
                }//
              }

              return oldData; //{ getTerritoryCache: [...data] } //oldData; //{getTerritoryCache:data} ///oldData; //{updateTerritoryCache:data}; //data
            }
          });

          //return results; //[];
        },

        updatePublisherCache: (result, args, cache, info) => {

          if (!result || result.length == 0) {
            return
          }


          let cacheUpd = cache.updateQuery({
            query: GET_PUBLISHER_CACHE,
            variables: { congregationID: "1" }
          }, (oldData) => {
            //          return oldData;
            if (oldData == null) {
              console.log("old data null");
              oldData = { getPublisherCache: [] }
            }

            //console.log("oldData");
            // console.log(oldData);

            let data = oldData.getPublisherCache;

            if (result.updatePublisherCache) {
              let oldTerrs = new Set()

              for (let i = 0; i < data.length; i++) {
                //console.log("ns0.2");
                oldTerrs.add(data[i].id);
              }
              //console.log("ns1");
              for (let i = 0; i < result.updatePublisherCache.length; i++) {
                //console.log("ns2");
                let newTerr = result.updatePublisherCache[i];
                if (oldTerrs.has(newTerr.id) == false) {
                  console.log("NEW Publisher");
                  //inserts.push(newTerr);
                  data.push(newTerr);
                }//
              }

              console.log("ns4");

              //  data = [...data,...inserts];
              console.log("dun ittin");
            }
            console.log("CUR QUERY DATA");
            //console.log(data);

            //console.log(oldData);

            return oldData; //{ getTerritoryCache: [...data] } //oldData; //{getTerritoryCache:data} ///oldData; //{updateTerritoryCache:data}; //data
          }
          );

          //return results; //[];
        },

        updateTerritoryCache: (result, args, cache, info) => {
          console.log("updateTerritoryCache updater");

          if (!result || result.length == 0) {
            return
          }

          //   console.log(result);
          // console.log({ ...result })
          console.log(args);
          console.log(cache);
          console.log(info);

          let cacheUpd = cache.updateQuery({
            query: GET_TERRITORY_CACHE,
            variables: { congregationID: "1" }
          }, (oldData) => {
            //          return oldData;
            if (oldData == null) {
              console.log("old data null");
              oldData = { getTerritoryCache: [] }
            }

            //console.log("oldData");
            // console.log(oldData);

            let data = oldData.getTerritoryCache;

            if (result.updateTerritoryCache) {
              let oldTerrs = new Set()

              for (let i = 0; i < data.length; i++) {
                //console.log("ns0.2");
                oldTerrs.add(data[i].id);
              }
              //console.log("ns1");
              for (let i = 0; i < result.updateTerritoryCache.length; i++) {
                //console.log("ns2");
                let newTerr = result.updateTerritoryCache[i];
                if (oldTerrs.has(newTerr.id) == false) {
                  console.log("NEW TERRITORY");
                  //inserts.push(newTerr);
                  data.push(newTerr);
                }//
              }

              console.log("ns4");

              //  data = [...data,...inserts];
              console.log("dun ittin");
            }
            return oldData; //{ getTerritoryCache: [...data] } //oldData; //{getTerritoryCache:data} ///oldData; //{updateTerritoryCache:data}; //data
          }
          );

        }
      }
    },

    resolvers: {
      Query: {

        getPublisherByID: (parent, args, cache) => {

          return cache.keyOfEntity({ __typename: 'Publisher', id: args.id })
        },

        getAddressByID: (parent, args, cache) => {
          return cache.keyOfEntity({ __typename: 'Address', id: args.id })

          let rf =  cache.readFragment(
            gql`
            fragment _ on Address {
              ${AddressQueryFields}
            }
          `,
            {
              id: args.id
            } // this identifies the fragment (User) entity // any additional field variables
          );

            console.log("gaid");
            console.log(rf);
            return rf;

        },

        getTerritoryByID: (parent, args, cache) => {

          //let terr = cache.resolve({ __typename: "Territory", id: args.id })

          return cache.keyOfEntity({ __typename: 'Territory', id: args.id })

          return cache.readFragment(
            gql`
            fragment _ on Territory {
              ${TerritoryQueryFields}
            }
          `,
            {
              id: args.id
            } // this identifies the fragment (User) entity // any additional field variables
          );



          //console.log("GetTerritoryByID Cache Resolve2");
          //console.log(terr);

          //return { __typename: "Territory", id: args.id }



        }

        /*getTerritoryCache:(parent, args, cache, info) => {
          console.log("resolver getTerritoryCache");
          console.log(parent)
          console.log(args);
          console.log(cache);
          console.log(info);
  
          let ack = cache.resolve("Query","getTerritoryCache",{congregationID:"1"})
          console.log("cacheresolve3");
          console.log(ack)
  
          return ack;
  
        }*/
      }

    },


    optimistic: {


      workDoor: (args, cache, info) => {
        console.log("workDoor optimistic mutation");
        console.log(args);
        console.log(cache);
        console.log(info);
          let doorToDoorRecord = cache.readFragment(
            gql`
            fragment _ on DoorToDoorRecord {
              __typename
              ${DoorToDoorRecordQueryFields}
            }
          `,
            { id: args.doorToDoorRecordID }
          ); // Data or null

          console.log("the record from cache");
          console.log({ ...doorToDoorRecord })
          console.log(doorToDoorRecord.actions.length);

          let theDate = args.date.getTime();

          console.log(theDate);
  
          for (let i=0;i<doorToDoorRecord.actions.length;i++){//No 2 actions can have the exact same date, so date can be used as a unique identifier.
              let action = doorToDoorRecord.actions[i];
              if (parseInt(action.date) == theDate){
                  theDate = theDate + 1;
                  i = -1;
                  console.log("FOUND SAME TIME");
                  console.log(action);
              }
          }
  
  
          let action:DTDAction = {
              userID: "usr_7", //(await context.auth.getDomainRoles({ domain: "default" })).id,
              date:""+theDate,
              addressID:args.addressID,
              action:args.type,
              __typename:"DTDAction"
          }

          if (args.undoDate){
            action.undoDate = ""+args.undoDate.getTime();
          }

          doorToDoorRecord.actions.push(action);

          console.log("doorToDoorRecord at end");
          console.log({...doorToDoorRecord});
          console.log(doorToDoorRecord.actions.length)
          console.log(new Date().getTime());

        return doorToDoorRecord;
      },


      checkInTerritories: (args, cache, info) => {
        console.log("checkInTerritories optimistic mutation");
        console.log(args);
        console.log(cache);
        console.log(info);

        try{

        let outputTerritories = []
        let d = new Date()
        for (let territoryID of args.territoryIDs) {
          let territory = cache.readFragment(
            gql`
            fragment _ on Territory {
              __typename
              id
              territoryName
              assignedToPublisherID
              assignedToPublisherName
              checkedOutAt
              lastWorked
              lastResearched
              congregationID
              doorToDoor
              gatedCommunity
              updatedAt
              createdAt
              workHistory{
                __typename
                campaign
                checkedIn
                checkedOut
                researched
                publisherID
                publisherName
              } 
            }
          `,
            { id: territoryID }
          ); // Data or null

          console.log("the terr from cache");
          console.log(territory);
          console.log({ ...territory })

          delete territory.assignedToPublisherID
          delete territory.assignedToPublisherName
          delete territory.checkedOutAt

          territory.lastWorked = "" + d.getTime();
          territory.lastResearched = 1

          territory.workHistory[0]["checkedIn"] = "" + d.getTime();

          if (args.didResearch) {
            territory.workHistory[0]["researched"] = true;
            territory.lastResearched = d.getTime();
          } else {
            for (let wh of territory.workHistory) {

              let checkIn = !wh?.checkedIn ? 1 : typeof(wh?.checkedIn) == "string" ? parseInt(wh?.checkedIn) :  wh?.checkedIn?.getTime();

              if (wh.researched && checkIn > territory.lastResearched ) //(typeof wh?.checkedIn == "string" && parseInt(wh?.checkedIn) > territory.lastResearched) ) {
                territory.lastResearched = checkIn; //wh?.checkedIn?.getTime()
              }
            }
          //}
          territory.lastResearched = "" + territory.lastResearched;




          if (args?.didCampaign && args?.didCampaign?.trim()) {
            territory.workHistory[0]["campaign"] = args?.didCampaign
          }

          console.log("outputTerr")
          console.log(territory)

          outputTerritories.push(territory);

        }

        return outputTerritories;

      } catch(e: Error | any){

        let errmess = {};
        try{
          console.log(JSON.stringify(e, Object.getOwnPropertyNames(e)));
          errmess = JSON.parse(JSON.stringify(e, Object.getOwnPropertyNames(e)))
        }catch(err){     
          console.log("caught error");
          console.log(err);  
        }
        
        let outp = {"Message":e?.message,"Source":e?.source,"LineNumber":e?.lineno,"ColumnNumber":e?.colno,"Error": errmess, "From optimistic mutation":true}
        
        // Make the call to log the client error.
        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://53sgfpjtke45rq5h24d4zvcpd40ubxfn.lambda-url.us-east-1.on.aws/LogClientError', true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify(outp));

        return [];
      }

      },

      placeLiterature: (args, cache, info) => {
        console.log("placeLiterature optimistic mutation");
        console.log(args);
        console.log(cache);
        console.log(info);


          let address = cache.readFragment(
            gql`
            fragment _ on Address {
              __typename
              ${AddressQueryFields}
            }
          `,
            { id: args.addressID }
          ); // Data or null

          if (!address.literaturePlaced){
            address.literaturePlaced=[];
          }
      
          let publisher = CURRENTAUTH?.currentDomainUser;

            address.literaturePlaced.push({
            literature: args.literatureRecord.literature,
            date: new Date(),
            publisherID: publisher.id,
            publisherName: publisher.displayName 
          } as LiteraturePlacedRecord);

        return address;
      },

      checkOutTerritories: (args, cache, info) => {
        console.log("checkOutTerritories optimistic mutation");
        console.log(args);
        console.log(cache);
        console.log(info);
        let outputTerritories = []

        let d = new Date()

        for (let territoryID of args.territoryIDs) {
          let territory = cache.readFragment(
            gql`
            fragment _ on Territory {
              __typename
              id
      territoryName
      assignedToPublisherID
      assignedToPublisherName
      checkedOutAt
      lastWorked
      lastResearched
      congregationID
      doorToDoor
      gatedCommunity
      updatedAt
      createdAt
      workHistory{
        __typename
        campaign
        checkedIn
        checkedOut
        researched
        publisherID
        publisherName
          } 
            }
          `,
            { id: territoryID }
          ); // Data or null

          console.log("the terr from cache");
          console.log(territory);
          console.log({ ...territory })

          delete territory.lastWorked
          delete territory.lastResearched

          territory.checkedOutAt = "" + d.getTime();

          territory.assignedToPublisherID = args.publisherID;

          let publisherName = cache.resolve({ __typename: "Publisher", id: args.publisherID }, "displayName"); //"Wyatto Bunkman";//cache.resolve({__typename:"Publisher",id:args.publisherID},"firstName");

          territory.assignedToPublisherName = publisherName;

          territory.workHistory.unshift({ publisherID: args.publisherID, publisherName, checkedOut: "" + d.getTime(), __typename: "TerritoryWorkRecord" });

          outputTerritories.push(territory);

        }

        console.log("output");
        console.log(outputTerritories);

        return outputTerritories;
      },

    },

    keys: {//
      HouseHolder: () => null,
      TerritoryWorkRecord: () => null,
      DoNotCallRecord: () => null,
      LiteraturePlacedRecord: () => null,
      DTDAction: (action:DTDAction)=>{ return action.addressID + ":" + action.date  },
      //GeoJSONPoint: ()=>null,
      // GeoJSONMultiPolygon: ()=>null,
    }


  });
} 