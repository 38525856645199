import React, { useContext, useState } from "react";

import { useQuery, gql, useMutation, useClient } from 'urql';

import { AddressQueryFields } from 'models/Address';
import { Col, FloatingLabel, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ErrorMessage, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';
import { init } from 'echarts';
import ActionButton from "components/common/ActionButton";
import { faTrashAlt, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "newComponents/LoadingButton";
import { time } from "console";
import { Publisher, PublisherQueryFields } from "models/Publisher";
import { useSearchParams } from "react-router-dom";

import Select from "react-select";
import { customReactSelectStyle } from "helpers/reactSelectStyle";
import { AuthContext } from "utilities/Authentication";
import toPascalCase from "utilities/toPascalCase";
import { ResolversEnum, RolesEnum } from "utilities/Config";

const CREATE_NEW_PUBLISHER = gql`
mutation($publisher:PublisherInput!){
    createPublisher(data:$publisher){
${PublisherQueryFields}
    }
  }`
//
function formatPhoneNumber(value: string) {
    if (!value) return value;

    // Disallow any alpha characters.
    var phoneNumber = value.replace(/[^\d]/g, "");

    var phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;

    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    if (phoneNumberLength < 11) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    }

    if (phoneNumberLength > 13) {
        phoneNumber = phoneNumber.substr(0, 13);
        phoneNumberLength = phoneNumber.length;
    }

    var ccs = phoneNumberLength - 10

    return `+${phoneNumber.slice(0, ccs)} (${phoneNumber.slice(ccs, ccs + 3)}) ${phoneNumber.slice(ccs + 3, ccs + 6)}-${phoneNumber.slice(ccs + 6, ccs + 10)}`;
}
/*
 {validationSchema={YupSchema}}
const YupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Too short").required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(2, "Too short").required("Required"),
    confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
    acceptTerms: Yup.boolean().oneOf([true], "You must accept the terms and conditions to use the site")
});
*/

const CreatePublisher = () => {

    const authContext = useContext(AuthContext);

    const [nickNameTouched, setNickNameTouched] = useState(false);

    const graphqlClient = useClient();

    const [searchParams, setSearchParams] = useSearchParams();
    const publisherName = searchParams.get("publisherName")

    let initialFirstName = "";
    let initialLastName = "";

    if (publisherName) {
        let publisherNameSplit = publisherName.split(" ");
        if (publisherNameSplit.length >= 1) {
            initialFirstName = publisherNameSplit[0]
        }
        if (publisherNameSplit.length >= 2) {
            initialLastName = publisherNameSplit[1]
        }
    }

    let elevRole = !authContext?.checkHasPermission({ resolver: ResolversEnum.createElevatedRole });

    const rolesOptions = [{ value: 2, label: "Publisher" }, { value: 3, label: "Researcher" }, { value: 12, label: "Territory Servant", isDisabled: elevRole }, { value: 11, label: "Service Overseer", isDisabled: elevRole }, { value: 10, label: "Admin", isDisabled: elevRole }]

    //const blankPublisher = new Publisher({});

    return (
        <div>
            <h3 style={{ textAlign: "center" }}>Create New Publisher</h3>

            <Formik
                initialStatus="Submit"//What the button will say by default.
                initialValues={new Publisher({ firstName: initialFirstName, middleName: "", lastName: initialLastName, displayName: "", email: "", phone: "", roles: [2] })}

                onSubmit={async (values, { setErrors, setStatus, setSubmitting, setValues }) => {

                    if (window.navigator.onLine == false){
                        alert("You are not currently connected to the internet.")
                        return
                        }

                    console.log("onSubmit");
                    console.log(values);

                    setSubmitting(true);

                    let result = await graphqlClient.mutation(CREATE_NEW_PUBLISHER, { publisher: { ...values, id: "" } }).toPromise();

                    if (result.error) {
                        console.log("WAS AN ERROR");
                        setStatus("ERROR: " + result.error.message);
                        await new Promise(r => setTimeout(r, 3000));
                    } else {
                        console.log("WAS SUCCESSFUL");
                        setStatus("success");
                    }
                    await new Promise(r => setTimeout(r, 3000));

                    setValues(new Publisher({ firstName: "", middleName: "", lastName: "", displayName: "", email: "", phone: "", roles: [2] }));

                    setSubmitting(false);
                    setStatus("Submit");

                    console.log("new publisher result");
                    console.log(result);

                }}//End onSubmit

            >{({//Dictionary being passed to an arrow function, to initiate the formik form and it's state. Keeps state separate from other formiks?
                values,
                errors,
                touched,
                //  isValid,
                status,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                // and other goodies 
            }) => (
                <Form noValidate onSubmit={handleSubmit}>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                placeholder={'First Name'}
                                value={values.firstName}
                                name={`firstName`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="firstName" /></span>
                        </Form.Group>
                    </Col></Row>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control
                                placeholder={'Middle Name'}
                                value={values.middleName}
                                name={`middleName`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="middleName" /></span>
                        </Form.Group>
                    </Col></Row>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                placeholder={'Last Name'}
                                value={values.lastName}
                                name={`lastName`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="lastName" /></span>
                        </Form.Group>
                    </Col></Row>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Display Name</Form.Label>
                            <Form.Control

                                onSelect={() => { console.log("ON SELECT"); if (!nickNameTouched) { values.displayName = [values.firstName, values.lastName].join(" ") } setNickNameTouched(true); }}

                                placeholder={'Display Name'}
                                value={(() => { if (nickNameTouched) return values.displayName; return [values.firstName, values.lastName].join(" ") })()}
                                name={`displayName`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="displayName" /></span>
                        </Form.Group>
                    </Col></Row>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                placeholder={'Email'}
                                value={values.email}
                                name={`email`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="email"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="email" /></span>
                        </Form.Group>
                    </Col></Row>

                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control
                                placeholder={'Phone'}
                                value={formatPhoneNumber(values.phone || "")}
                                name={`phone`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="tel"
                            />
                            <span className="invalid-feedback"><ErrorMessage name="phone" /></span>
                        </Form.Group>
                    </Col></Row>


                    <Row><Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Roles</Form.Label>
                            <Select
                                closeMenuOnSelect={true}
                                options={rolesOptions}
                                styles={customReactSelectStyle}
                                //isValidNewOption={(value) => { console.log(value); if (value.length >= 2) { return true }; return false; }}
                                isSearchable={true}
                                isMulti
                                //onCreateOption={handleCreate}
                                //menuIsOpen={true}


                                value={values.roles.map((roleNumber) => { return { value: roleNumber, label: toPascalCase(RolesEnum[roleNumber].replaceAll("_", " ")) || "Role Number: " + roleNumber }; })}
                                //value={values.roles}
                                name={"roles"}
                                onBlur={handleBlur}

                                type="text"

                                placeholder='Roles'
                                classNamePrefix="react-select2"
                                //value={value}

                                onChange={(e) => {
                                    console.log("onchange");
                                    console.log(e);
                                    return setFieldValue('roles', e.map((v) => v.value));
                                }}
                                //onChange={handleChange}

                                menuPlacement="top"
                            //width="100%"
                            //filterOption={reactSelectCustomSearch}
                            />
                            <span className="invalid-feedback"><ErrorMessage name="roles" /></span>
                        </Form.Group>
                    </Col></Row>

                    <LoadingButton type="submit" loading={isSubmitting} status={status} className="w-100" >Submit</LoadingButton>

                </Form>

            )}
            </Formik>

            {/*<div className="form-floating">
        <textarea defaultValue={address.notes} className="form-control" id="floatingTextarea2" placeholder="Notes" style={{height: 100}}></textarea>
        <label for="floatingTextarea2">Notes</label>
        </div>*/}

        </div>
    );

};

export default CreatePublisher;

