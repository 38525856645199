import { lineOffset, lineString } from "@turf/turf";
import { Address, HouseHolder } from "models/Address";
import { Territory } from "models/Territory";
import React, { useMemo } from "react";
import { Circle, FeatureGroup, LayerGroup, LayersControl, Marker, Pane, Popup, Rectangle, TileLayer, useMap } from "react-leaflet"
import { GeoJSON as ReactLeafletGeoJSON } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
//import "react-leaflet-fullscreen/dist/styles.css";
import "leaflet.fullscreen/Control.FullScreen.css";
import * as turf from '@turf/turf'
import MapTileLayers from "./MapTileLayers";
import { DoorToDoorRecordQueryFields, DTDAction } from "models/DoorToDoorRecord";
import { useClient, gql } from "urql";
import { Form, Row } from "react-bootstrap";


const WORK_DOOR = gql`
mutation work($addressID:ID!,$undoDate:Date,$date:Date!,$doorToDoorRecordID:ID!,$type:String!  ){
    workDoor(undoDate:$undoDate,addressID:$addressID,date:$date,doorToDoorRecordID:$doorToDoorRecordID, type:$type){
        ${DoorToDoorRecordQueryFields}
  }
}`

//
const leafletBaseStyle = {
    stroke: true, //enable border
    color: "#0000ff", //border color, hexadecimal
    weight: 1, //border width
    opacity: 1, //border opacity
    lineCap: "butt", //how to style end of a line
    lineJoin: "round", //Makes line joints rounded
    fill: true, //To fill polygon with color or not
    fillColor: "#0000ff",
    fillOpacity: .03,
    fillRule: "nonzero"
}

const Work_PolygonStyle = {
    ...leafletBaseStyle,
    color: "#0000ff"
}

const Work_PolyLineStyle = {
    ...leafletBaseStyle,
    fill: false,
    weight: 3,
    color: "#0000ff",
    opacity:0.3
    
}

const NH_PolygonStyle = {
    ...leafletBaseStyle,
    color: "#f5f242", //"#00ff00", //"#f5da42" //"#aaaaaa"
    fillColor: "#f5f242"//"#00ff00",
}

const NH_PolyLineStyle = {
    ...leafletBaseStyle,
    fill: false,
    weight: 3,
    color: "#f5f242", //"#00ff00", //"#f5da42", //"#aaaaaa",
    opacity:0.3
}

const H_PolygonStyle = {
    ...leafletBaseStyle,
    color: "#f5aa42", //"#000000"
    fillColor:"#f5aa42"
}

const H_PolyLineStyle = {
    ...leafletBaseStyle,
    fill: false,
    weight: 3,
    color: "#f5aa42", //"#000000",
    opacity:0.3
}

const DNC_PolygonStyle = {
    ...leafletBaseStyle,
    fill: true,
    fillOpacity: 0.125,
    weight: 2,
    fillColor: "#ff0000",
    color: "#ff0000"
}

const DNC_PolyLineStyle = {
    ...leafletBaseStyle,
    fill: false,
    weight: 3,
    color: "#ff0000",
    opacity:0.3
}


function AddressGeometry({ address, workRecord, showHideOptions = { houseHolders: true, age: true, religion: true, phone: true, notes: true } }: { showHideOptions?: { houseHolders: true, age: true, religion: true, phone: true, notes: true }, address: Address, workRecord: { doorToDoorWorkID: string, actions: DTDAction[], currentState: "NH" | "H" | "", numberNHs: number, addressID: string } }) {

    let workedState = workRecord?.currentState || "";

    const graphQLClient = useClient();

    const OnCheckbox = async function (e) {
        console.log(e);
        console.log(e.target.name);
        console.log(e.target);
        console.log(e.target.checked);
        console.log("workrecord");
        console.log(workRecord);

        let result = null

        let curDate = new Date();

        if (window.navigator.onLine == false){
            alert("You are not currently connected to the internet.")
            return
            }

        if (e.target.checked == false) {
            let undoDate = null; //new Date()//TODO calculate undoDate

            if (workRecord?.actions.length == 0) {
                return
            }

            let lastRecord = workRecord.actions[workRecord?.actions.length - 1]
            if (lastRecord.action == "UNDO" || lastRecord.undone) {
                return
            }

            console.log("lastRecord")
            console.log(lastRecord);
            console.log({
                addressID: address.id,
                undoDate: lastRecord.date,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "UNDO"
            })

            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                undoDate: lastRecord.date,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "UNDO"
            }).toPromise();

        } else if (e.target.name == "NH") {
            //Make NH
            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                date: curDate,
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "NH"
            }).toPromise();

        } else if (e.target.name == "H") {
            //Make H
            result = await graphQLClient.mutation(WORK_DOOR, {
                addressID: address.id,
                date: new Date(),
                doorToDoorRecordID: workRecord?.doorToDoorWorkID,
                type: "H"
            }).toPromise();

        }

        if (result.error) {
            console.log("UNDO error");
            alert(result.error.message);
        } else {
            console.log("WORK success");
            console.log(result);
        }

    }

    const addressRoadLine = useMemo(() => {
        if (!address.roadLine) {
            return null;
        }
        return {
            type: "LineString",
            coordinates: lineOffset(lineString(address.roadLine.coordinates), address.roadLine.properties.side == "left" ? -0.00003 : 0.00003, { units: 'degrees' }).geometry.coordinates//address["roadLine"]["coordinates"]
        }

    }, [address.roadLine]);

    let polyLineStyle = Work_PolyLineStyle
    let polygonStyle = Work_PolygonStyle

    if (address.doNotCall) {
        polyLineStyle = DNC_PolyLineStyle
        polygonStyle = DNC_PolygonStyle
    } else if (workRecord && workRecord.currentState == "NH") {
        polyLineStyle = NH_PolyLineStyle
        polygonStyle = NH_PolygonStyle
    } else if (workRecord && workRecord.currentState == "H") {
        polyLineStyle = H_PolyLineStyle
        polygonStyle = H_PolygonStyle
    }


    return (
        <>
            {addressRoadLine && <ReactLeafletGeoJSON data={addressRoadLine} style={polyLineStyle} ></ReactLeafletGeoJSON>}
            <ReactLeafletGeoJSON data={address.parcel || address.coordinates} style={polygonStyle} >
                <Popup>
                    <h6>{address.address}{address.doNotCall && " (Do Not Call)"}</h6>
                    {address.houseHolders?.map(function (houseHolder: HouseHolder, index: number) {
                        let str = [showHideOptions.houseHolders && houseHolder.fullName, showHideOptions.age && houseHolder.age, showHideOptions.religion && houseHolder.religion, showHideOptions.phone && houseHolder.phone].filter((a) => a).join(", ").trim();
                        if (!str) return null;
                        return (<span key={index} style={{ marginBottom: 10, fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol" }}>{str}<br /></span>)
                    })}

{showHideOptions.notes && address.notes && <span style={{fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol"}}>{address.notes}<br/></span>}

                    {/*<Form.Group className="" style={{marginBottom:"0px !important;", marginTop:"10px !important;" }}>*/}
                   {workRecord && <div style={{ marginTop: 10 }}>
                        <Row>
                            <Form.Label style={{ fontSize: 12 }}>
                                <input style={{ marginTop: 1 }} name="NH" disabled={workedState == "H" || address.doNotCall} checked={workedState == "NH"} onChange={OnCheckbox} type="checkbox" className="form-check-input NHCheckbox" /> Not At Home <span style={{ fontFamily: "Poppins,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol", fontWeight:600, visibility: workRecord?.numberNHs ? "visible" : "hidden", marginRight: 10 }}>({workRecord?.numberNHs || "0"})</span>
                            </Form.Label>
                        </Row><Row>
                            <Form.Label style={{ fontSize: 12 }}>
                                <input style={{ marginTop: 1 }} name="H" disabled={workedState == "NH" || address.doNotCall} checked={workedState == "H"} onChange={OnCheckbox} type="checkbox" className="form-check-input HCheckbox" /> At Home
                            </Form.Label>
                        </Row>
                    </div> }
                </Popup>
            </ReactLeafletGeoJSON>
        </>
    )
}

function TerritoryMap({ territory, addressWorkRecords, inputAddresses, showHideOptions = { houseHolders: true, age: true, religion: true, phone: true, notes: true } }: { showHideOptions?: { houseHolders: true, age: true, religion: true, phone: true, notes: true }, inputAddresses?: Address[], territory: Territory, addressWorkRecords: Record<string, { doorToDoorWorkID: string, actions: DTDAction[], currentState: "NH" | "H" | "", numberNHs: number, addressID: string }> }) {

    const map = useMap();

    const addresses = inputAddresses || territory.addresses || [];

    useMemo(function () {
        console.log("Territory map usememo");

        let points = [];

        for (let adr of (territory?.addresses || [])) {
            points.push(adr.coordinates?.coordinates);
        }

        console.log(points);

        if (points.length != 0) {

            let bbox = turf.bbox(turf.points(points));

            console.log(bbox);

            map.fitBounds([[bbox[1], bbox[0]], [bbox[3], bbox[2]]], { padding: [40, 40] })

            //  let expandbbox = 0.0001

            //map.fitBounds([[bbox[1] - expandbbox,bbox[0] - expandbbox],[bbox[3] + expandbbox,bbox[2] + expandbbox]])
        }

        //map.fitBounds([[],[]])

    }, [(!addresses || addresses.length == 0)])

    const center = [51.505, -0.09]
    const rectangle = [
        [51.49, -0.08],
        [51.5, -0.06],
    ]




    return (
        <>
            <FullscreenControl />



            <MapTileLayers />

            {addresses.map(function (address, index) {
                return (
                    <AddressGeometry key={address.id} address={address} showHideOptions={showHideOptions} workRecord={addressWorkRecords && addressWorkRecords[address.id] || null} />
                )
            })}
        </>

    )

}

export default TerritoryMap